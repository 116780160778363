import { parsePhoneNumber } from 'awesome-phonenumber'
import md5 from 'md5'
import { get as extract, isNil, isPlainObject, omitBy, upperFirst } from 'lodash'
import { ulid } from 'ulid'

export function isEmpty(value) {
  return value === null || value === undefined
}

export function mapArray(array, cb) {
  const map = {}
  const size = array.length

  for (let i = 0; i < size; i++) {
    const value = array[i]
    const key = cb(value)

    map[key] = i
  }

  return map
}

export function isEqualArray(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false
  }

  if (a.length !== b.length) {
    return false
  }

  return a.every((v, i) => v === b[i])
}

export function arrayUnpackByKey(array, key) {
  let index = []

  if (Array.isArray(array)) {
    for (let i = 0; i < array.length; i++) {
      const item = array[i]

      index.push(item)

      if (Array.isArray(item[key])) {
        index = [...index, ...arrayUnpackByKey(item[key], key)]
      }
    }
  }

  return index
}

export function formatPhone(number, format = 'international') {
  if (!number) {
    return null
  }

  const phoneNumber = parsePhoneNumber(number, { regionCode: Munio.config.i18n.country })

  return phoneNumber.valid ? (phoneNumber.number[format] ?? phoneNumber.number.international) : number
}

export function gravatar(email, size = 32) {
  email = md5(((email || '!') + '').toLowerCase().trim())

  return `https://secure.gravatar.com/avatar/${email}.jpg?s=${size}&d=mp`
}

export const FOCUSABLE_ELEMENTS = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'button:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])',
]

export function focusableNodes(element) {
  const nodes = element.querySelectorAll(FOCUSABLE_ELEMENTS)
  return Object.keys(nodes).map((key) => nodes[key])
}

export function focusTrap(element, event) {
  const nodes = focusableNodes(element)

  if (!element.contains(document.activeElement)) {
    nodes[0].focus()
  } else {
    const focusedItemIndex = nodes.indexOf(document.activeElement)

    if (event.shiftKey && focusedItemIndex === 0) {
      nodes[nodes.length - 1].focus()
      event.preventDefault()
    }

    if (!event.shiftKey && focusedItemIndex === nodes.length - 1) {
      nodes[0].focus()
      event.preventDefault()
    }
  }
}

export function colorCssName(className, text = false) {
  const prefix = text ? 'mdl-color-text--' : 'mdl-color--'
  const regex = new RegExp('^' + prefix)

  return regex.test(className) ? className : prefix + className
}

export function get(value, defaultValue) {
  if ([null, undefined].includes(value)) {
    value = defaultValue
  }
  return value
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function sortArray(arr, path, ascending = true) {
  const direction = ascending ? 1 : -1

  return [...arr].sort((a, b) => {
    const av = extract(a, path)
    const bv = extract(b, path)
    const nullcmp = (av === null || av === undefined) - (bv === null || bv === undefined)
    const strcmp = typeof av === 'string' || typeof bv === 'string'

    if (nullcmp !== 0) {
      return nullcmp
    }

    const result = strcmp ? String(av).localeCompare(String(bv), Munio.config.i18n.language) : av > bv ? 1 : -1

    return result * direction
  })
}

export class Timer {
  timerId
  callback
  delay
  start
  remaining

  constructor(callback, delay) {
    this.callback = callback
    this.delay = delay
    this.begin()
  }

  get percent() {
    return (Date.now() - this.start) / this.delay
  }

  cancel() {
    clearTimeout(this.timerId)
  }

  pause() {
    this.cancel()
    this.remaining -= Date.now() - this.start
  }

  resume() {
    this.start = Date.now()
    this.cancel()
    this.timerId = setTimeout(this.callback, this.remaining)
  }

  begin() {
    this.remaining = this.delay
    this.resume()
  }

  end() {
    this.remaining = 0
    this.cancel()
  }
}

export function cssTimeToMilliseconds(str) {
  const num = parseFloat(str, 10)
  let unit = str.match(/m?s/)
  let milliseconds

  if (unit) {
    unit = unit[0]
  }

  switch (unit) {
    case 's':
      milliseconds = num * 1000
      break
    case 'ms':
      milliseconds = num
      break
    default:
      milliseconds = 0
      break
  }

  return milliseconds
}

export function userFullname(user) {
  if (!user) {
    return undefined
  }

  const name = user.fullname ?? user.name ?? [user.firstname, user.lastname].filter((v) => String(v).trim()).join(' ')

  return name || user.email || user.mobile
}

export function addressString(address, country = null) {
  if (!address) {
    return ''
  }

  const town = [address.postcode, address.city]
    .map((p) => p && p.trim())
    .filter(Boolean)
    .join(' ')
  const parts = [address.line1, address.line2, town].map((p) => p && p.trim()).filter(Boolean)

  if (country && country !== address.country) {
    parts.push(Munio.country(address.country).i18n)
  }

  return parts.join(', ') || ''
}

export function titleCase(str) {
  return String(str).split(' ').map(upperFirst).join(' ')
}

function uuid(a = undefined) {
  if (a) {
    return (
      // a random number from 0 to 15
      (
        a ^ // unless b is 8,
        ((Math.random() * // in which case
          16) >> // a random number from
          (a / 4))
      ) // 8 to 11
        .toString(16)
    ) // in hexadecimal
  }

  return (
    // or otherwise a concatenated string:
    (
      [1e7] + // 10000000 +
      -1e3 + // -1000 +
      -4e3 + // -4000 +
      -8e3 + // -80000000 +
      -1e11
    ) // -100000000000,
      .replace(
        // replacing
        /[018]/g, // zeroes, ones, and eights with
        uuid, // random hex digits
      )
  )
}

export function bytesForHumans(bytes) {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i

  for (i = 0; bytes >= 1024 && i < 4; i++) {
    bytes /= 1024
  }

  return bytes?.toFixed(2) + ' ' + units[i]
}

export { uuid, ulid }

export function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email)
}

export function pendoLabel(label) {
  return `${window.Munio.config.route.name}.${label}`
}

export function objectFilter(obj) {
  const newObj = {}

  Object.keys(obj).forEach((key) => {
    if (isPlainObject(obj[key])) {
      const subObj = objectFilter(obj[key])
      if (Object.keys(subObj).length) {
        newObj[key] = subObj
      }
    } else {
      newObj[key] = obj[key]
    }
  })

  return omitBy(newObj, isNil)
}
