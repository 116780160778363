<script setup lang="ts">
import { computed, inject, onBeforeUnmount, onMounted } from 'vue'
import MdlCheckbox from '@component/mdl/Checkbox.vue'
import type { Row } from './DataTable.vue'

defineOptions({
  name: 'MdlTableRow',
})

const props = withDefaults(defineProps<Row>(), {
  selectable: true,
  modified: false,
})

const addRow = inject('addRow') as Function
const removeRow = inject('removeRow') as Function
const toggleRow = inject('toggleRow') as Function
const hasSelectionEnabled = inject('hasSelectionEnabled') as boolean
const isRowSelected = inject('isRowSelected') as Function
const selected = computed(() => isRowSelected(props.value))

onMounted(() => {
  addRow(props)
})

onBeforeUnmount(() => {
  removeRow(props)
})

function onToggle(checked: boolean) {
  toggleRow(checked, props.value)
}
</script>

<template>
  <tr
    :class="{
      'is-selected': selected,
      'is-modified': props.modified,
    }"
    :data-valign="valign"
  >
    <td v-if="hasSelectionEnabled">
      <MdlCheckbox
        :key="value"
        :name="name"
        :value="value"
        :checked="selected"
        :disabled="!selectable"
        @update:checked="onToggle"
      />
    </td>
    <slot></slot>
  </tr>
</template>
