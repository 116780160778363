<template>
  <MdlList>
    <Action v-for="action of actionsShown" :key="action.ulid" :action="action" :hide-model="hideModel" />
  </MdlList>
</template>

<script>
import { onMounted, onBeforeUnmount, watch, computed } from 'vue'
import Action from './Action.vue'
import useStore from './store.js'
import MdlList from '@component/mdl/List.vue'

export default {
  name: 'IntegrationActions',

  components: {
    Action,
    MdlList,
  },

  props: {
    actions: { type: Array, required: true },
    added: { type: Array, required: true },
    channel: { type: String, required: true },
    hideModel: Boolean,
    live: Boolean,
  },

  setup(props) {
    const store = useStore()

    onMounted(() => {
      store.onMount(props)
    })

    onBeforeUnmount(() => {
      store.onUnmount()
    })

    watch(
      () => props.actions,
      (value) => {
        store.set_actions(value)
      },
    )

    watch(
      () => props.live,
      (value) => {
        store.set_live(value)
      },
    )

    return {
      numUnloaded: computed(() => store.actions.pending.length),
      actionsShown: computed(() => store.actionsShown),
    }
  },
}
</script>
