import Wrapper from '@app/Wrapper.js'
import App from './App.vue'

Munio.AccessZoneCompany = class extends Wrapper {
  constructor(selector, id) {
    super(selector, { id })
  }

  component() {
    return App
  }
}
