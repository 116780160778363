import { URLSearchParams } from 'url'
import { RequestData, URLParams } from '@/munio/http'
import { AxiosRequestHeaders } from 'axios'

export class Api {
  constructor(public readonly params: object = {}) {}

  protected get $http() {
    return window.Munio.$http
  }

  protected $urlParams(params: URLParams = window.location.search): URLSearchParams {
    return window.Munio.urlParams(params)
  }

  protected $route(name: string, params: object = {}): string {
    return window.Munio.route(name, Object.assign(params, this.params))
  }

  protected $get<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.get<T>(url, data, headers, config)
  }

  protected $post<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.post<T>(url, data, headers, config)
  }

  protected $put<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.put<T>(url, data, headers, config)
  }

  protected $patch<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.patch<T>(url, data, headers, config)
  }

  protected $delete<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.delete<T>(url, data, headers, config)
  }

  protected $beacon(url: string, data?: RequestData) {
    const payload = data ? new Blob([JSON.stringify(data)], { type: 'application/json' }) : null

    return navigator.sendBeacon(url, payload)
  }
}
