<template>
  <component class="card mdl-shadow--2dp" :href="href" :is="tag" :class="cardClasses">
    <div :class="thumbnailClasses" v-if="showThumbnail" :style="thumbnailStyles">
      <slot name="thumbnail">
        <div class="card__thumbnail--initials" v-if="!thumbnail && initials">{{ initials }}</div>
      </slot>
    </div>

    <div class="card__content">
      <div class="card__content-label" v-if="label">
        {{ label }}
      </div>
      <div class="card__content-upper">
        <div class="card__content-subtitle">{{ subtitle }}</div>
        <div class="card__content-title">
          <slot name="title">{{ title }}</slot>
          <slot name="title-suffix">
            <small class="ml-4" v-if="titleSuffix">{{ titleSuffix }}</small>
          </slot>
        </div>

        <div class="card__content-box" v-if="$slots['default']">
          <slot />
        </div>
      </div>
      <div class="card__content-lower">
        <div class="card__footer" v-if="$slots['footer']">
          <slot name="footer" />
        </div>
        <div class="card__actions" v-if="$slots['actions']">
          <slot name="actions" />
        </div>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

/*
 *  TODO:
 *  - Add slot for notifications (fabs)
 *  - Style slot for actions (space-between)
 */
const props = defineProps({
  alignment: {
    type: String,
    default: 'left',
    validator: (value: string) => ['center', 'left'].includes(value),
  },
  href: String,
  subtitle: String,
  title: String,
  titleSuffix: String,
  label: String,
  thumbnail: String,
  thumbnailShow: Boolean,
  thumbnailCover: {
    type: Boolean,
    default: true,
  },
  thumbnailSize: Number,
  initials: String,
  layout: {
    type: String,
    default: 'vertical',
    validator: (value: string) => ['vertical', 'horizontal'].includes(value),
  },
})

const tag = computed(() => (props.href ? 'a' : 'div'))

const thumbnailStyles = computed(() => ({
  backgroundImage: props.thumbnail ? `url(${props.thumbnail})` : undefined,
  width: props.thumbnailSize && props.layout === 'horizontal' ? `${props.thumbnailSize}px` : undefined,
  height: props.thumbnailSize && props.layout === 'vertical' ? `${props.thumbnailSize}px` : undefined,
}))

const cardClasses = computed<string[]>(() => [`card--layout-${props.layout}`, `card--alignment-${props.alignment}`])

const thumbnailClasses = computed<string[]>(() => [
  'card__thumbnail',
  'flex-shrink-0',
  `card__thumbnail--size-${props.thumbnailCover ? 'cover' : 'contain'}`,
])

const showThumbnail = computed(() => props.thumbnail || props.initials || props.thumbnailShow)
</script>
