<template>
  <CourseCheckout @close="emit('close')" />
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted, provide } from 'vue'
import { AuthenticatedCart } from '@/munio/api/ShopCheckout'

const CourseCheckout = defineAsyncComponent(() => import('@app/CourseLauncher/Content/CourseCheckout.vue'))

const props = defineProps<{
  cart: AuthenticatedCart
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

window.Munio.state.cart = props.cart
</script>
