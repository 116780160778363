<script setup lang="ts">
import { computed } from 'vue'
import { trans } from '@/munio/i18n'
import MdlSwitch from '@component/mdl/Switch.vue'

defineOptions({
  name: 'SwitchFilter',
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    inputName: string
    label?: string
    disabled?: boolean
    value?: boolean | string
  }>(),
  {
    inputName: 'value',
  },
)

defineEmits(['activate'])

const checked = computed(() => {
  if (typeof props.value === 'boolean') {
    return props.value
  }

  return ['true', '1', 'on'].includes(props.value)
})
</script>

<template>
  <div class="mdl-filter__form" :class="$attrs.class" @click="$emit('activate', $event)">
    {{ trans('No') }}
    <MdlSwitch :name="inputName" :checked="checked" right />
    {{ trans('Yes') }}
  </div>
</template>
