<template>
  <div class="user-search card mdl-shadow--2dp">
    <div class="row flex flex-wrap">
      <div class="user-search__left flex flex-col justify-between col-xs-12 col-sm-5 p-0">
        <div class="user-search__left-top mt-8 px-12" v-if="$slots['left-top']">
          <slot name="left-top" />
        </div>
        <SearchField class="pt-8 px-12" :disabled="disabled" v-model="query" @invite="addInvite" />
        <SearchResults class="flex-auto" :loading="searching" :results="results" @select="addUser" />

        <div v-if="invitable && results && !validEmail(query)" class="user-search__invite px-12 pt-0 pb-8">
          <h4 class="py-4">{{ inviteTitle }}</h4>
          <div class="flex items-center">
            <input
              class="flex-auto"
              type="email"
              v-model="invite"
              :placeholder="invitePlaceholder"
              @keyup.enter="addInvite(invite)"
            />
            <MdlButton :disabled="!validEmail(invite)" @click="addInvite(invite)">
              <i class="material-icons">keyboard_arrow_right</i>
            </MdlButton>
          </div>
        </div>
      </div>

      <div class="user-search__right flex flex-col justify-between col-xs-12 col-sm-7 p-0">
        <div class="user-search__right-top px-12 py-4" v-if="$slots['right-top']">
          <slot name="right-top" />
        </div>

        <template v-if="!hasUsers && !hasPlaceholders">
          <slot name="empty-state">
            <div class="flex-auto p-12">
              <h4 v-html="title" class="mt-0"></h4>
              <p v-html="description"></p>
            </div>
          </slot>
        </template>

        <!-- List of users to add -->
        <div v-else class="user-search__users flex-auto px-8 pt-8">
          <transition-group v-if="added.length" name="user-search">
            <div class="user-search__user-wrapper" v-for="user in users" :key="user.id">
              <component
                :is="getUserType(user)"
                :user="user"
                @change:email="changeUserEmail(user, $event)"
                @change:name="changeUserName(user, $event)"
                @remove="removeUser(user)"
              />
            </div>
          </transition-group>
          <div v-if="usersSoftLimited.length">
            <div class="user-search__user-limit flex items-center" v-html="softLimitWarning"></div>
            <transition-group v-if="usersSoftLimited.length" name="user-search">
              <div class="user-search__user-wrapper" v-for="user in usersSoftLimited" :key="user.id">
                <component
                  :is="getUserType(user)"
                  :user="user"
                  @change:email="changeUserEmail(user, $event)"
                  @change:name="changeUserName(user, $event)"
                  @remove="removeUser(user)"
                />
              </div>
            </transition-group>
          </div>
          <div v-for="user in placeholders" :key="user.id" class="placeholder__wrapper">
            <User :user="user" />
          </div>
          <div
            v-if="isHardLimitReached"
            class="user-search__user-limit user-search__user-limit--hard flex items-center"
            v-html="hardLimitWarning"
          ></div>
        </div>

        <div>
          <div
            v-if="invitable && inviteMessagePlaceholder && hasUsers"
            class="user-search__message flex-auto mt-8 px-8"
          >
            <div class="form-group stacked">
              <div class="form-value">
                <textarea v-model="inviteMessage" :placeholder="inviteMessagePlaceholder"></textarea>
              </div>
            </div>
          </div>

          <!-- Actions -->
          <div class="user-search__actions flex justify-self-end justify-end items-center p-8">
            <slot name="actions" />
            <MdlButton :disabled="!hasUsers || submitting" primary raised @click="submit">
              {{ submitLabel }}
            </MdlButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { trans } from '@/munio/i18n/index.js'
import User from './User.vue'
import UserInvite from './UserInvite.vue'
import SearchField from './SearchField.vue'
import SearchResults from './SearchResults.vue'
import MdlButton from '@component/mdl/Button.vue'

export default {
  components: {
    User,
    UserInvite,
    SearchField,
    SearchResults,
    MdlButton,
  },

  props: {
    modelValue: { type: Array, default: () => [] },
    searchHandler: { type: Function, required: true },
    submitHandler: { type: Function, required: true },
    invitable: { type: Boolean, default: false },
    hardLimit: { type: Number, default: undefined },
    softLimit: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
    // strings and labels
    title: { type: String, default: trans('Add users') },
    description: { type: String, default: trans('No users added yet') },
    searchPlaceholder: { type: String, default: trans('Search for user') },
    placeholderAvatar: { type: String, default: 'control-point' },
    placeholderLabel: { type: String, default: trans('Unassigned') },
    softLimitWarning: { type: String, default: trans('Soft limit') },
    hardLimitWarning: { type: String, default: trans('Hard limit reached') },
    inviteTitle: { type: String, default: trans("Couldn't find the user you are looking for?") },
    invitePlaceholder: { type: String, default: trans('Type an email to invite a user') },
    inviteMessagePlaceholder: { type: String, default: undefined },
    emptyMessage: { type: String, default: trans('No results found') },
    submitLabel: { type: String, default: trans('Add users') },
  },

  data() {
    return {
      query: '',
      invite: '',
      inviteMessage: '',
      added: [],
      results: false,
      noResults: false,
      searching: false,
      submitting: false,
    }
  },

  mounted() {
    this.clear()
  },

  computed: {
    users() {
      if (this.softLimit) {
        return this.added.slice(0, this.softLimit)
      }
      return this.added
    },
    usersSoftLimited() {
      if (this.softLimit) {
        return this.added.slice(this.softLimit)
      }
      return []
    },
    placeholderCount() {
      if (!this.softLimit) {
        return 0
      }

      return this.softLimit - this.added.length
    },
    placeholders() {
      const array = []
      const n = this.added.length + this.placeholderCount

      for (let i = this.added.length; i < n; i++) {
        array.push({
          id: i,
          placeholder: true,
          icon: this.placeholderAvatar,
          fullname: this.placeholderLabel,
        })
      }

      return array
    },
    limit() {
      return this.softLimit || this.hardLimit
    },
    hasUsers() {
      return this.added.length > 0
    },
    hasPlaceholders() {
      return this.placeholders.length > 0
    },
    hasSearched() {
      return this.results || this.noResults
    },
    hasLimit() {
      return !!(this.hardLimit || this.softLimit)
    },
    isHardLimitReached() {
      if (this.hardLimit) {
        return this.added.length >= this.hardLimit
      }

      return false
    },
  },

  watch: {
    query(query) {
      clearTimeout(this._timeout)

      if (query.length < 3) {
        return (this.results = false)
      }

      this._timeout = setTimeout(async () => {
        this.results = await this.callHandler(query)
      }, Munio.config.debounceTimeout)
    },

    modelValue(added) {
      this.added = added
    },
  },

  provide() {
    return {
      root: this,
      users: this.added,
    }
  },

  methods: {
    clear() {
      this.query = ''
      this.invite = ''
      this.added = []
      this.results = false
      this.searching = false
      this.submitting = false
    },

    setAdded(users) {
      this.added = users
      this.$emit('upade:modelValue', users)
    },

    getUserType(user) {
      return user.id === user.email ? 'UserInvite' : 'User'
    },

    addUser(user) {
      const users = this.added.slice()

      if (this.hardLimit && users.length >= this.hardLimit) {
        return // stop if hitting hard limit
      }

      if (!users.find(({ id }) => id === user.id)) {
        if (!this.validEmail(user.email)) {
          user.noEmail = true
        }

        users.push(user)
      }

      this.setAdded(users)
    },

    changeUserEmail(user, email) {
      const index = this.added.indexOf(user)
      this.added[index].email = email
    },
    changeUserName(user, name) {
      const index = this.added.indexOf(user)
      this.added[index].fullname = name
    },
    removeUser(user) {
      const index = this.added.indexOf(user)
      const users = this.added.slice()
      users.splice(index, 1)

      this.setAdded(users)
    },

    clearExceedingUsers() {
      if (this.hasLimit) {
        const users = this.added.slice()
        users.splice(this.softLimit)

        this.setAdded(users)
      }
    },

    async addInvite(email) {
      if (!this.validEmail(this.invite)) {
        return
      }

      const users = this.added.slice()
      const results = await this.callHandler(email)

      if (results.length === 1) {
        this.addUser(results[0])
      } else if (!users.find((user) => email === user.email)) {
        const user = { fullname: '', email }
        user.id = email // use email as a temporary unique id

        // use search query as fullname if it doesn't match the email
        if (this.query !== email) {
          user.fullname = this.query
        }

        this.addUser(user)
      }

      this.invite = ''
    },

    async callHandler(value) {
      this.searching = true
      this.noResults = false

      const results = await this.searchHandler(value)

      this.noResults = results.length === 0
      this.searching = false

      return results
    },

    async submit() {
      this.submitting = true

      await this.submitHandler(
        this.added.map(({ type, id, email, fullname }) => {
          if (id === email) id = null
          return { id, email, fullname }
        }),
        this.inviteMessage,
      )

      this.clear()
    },

    isEmailQuery() {
      return this.validEmail(this.query)
    },

    validEmail(value) {
      return value && String(value).match(/[^@]@[^@]/)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@style/variables';

.user-search {
  background-color: $default-dropdown-bg-color;
  border: 1px solid $default-item-divider-color;

  .modal & {
    border-width: 1px 0 0 0;
    box-shadow: none;
  }

  .row {
    margin: 0;
    min-height: 500px;
  }

  &__left {
    min-height: 350px;
    background-color: $layout-drawer-bg-color;
    border-right: 1px solid $default-item-divider-color;
  }

  &__invite {
    border-top: 1px solid $default-item-divider-color;

    .mdl-button {
      margin-left: 1rem;
    }
  }

  &__users {
    max-height: 60vh;
    overflow: auto;
  }

  &__user-limit {
    color: $data-table-header-color;
    border-top: 1px solid $data-table-divider-color;
    padding-top: 0.5rem;
    margin-top: 1rem;
    text-transform: uppercase;
    font-size: 0.8em;
    justify-content: center;

    &--hard {
      border-top-color: mdl-color($color-danger);
      color: mdl-color($color-danger);
    }
  }

  &__actions {
    border-top: 1px solid $default-item-divider-color;
  }
}

/**
   * Transition
   */
.user-search__user-wrapper {
  transition: all $trans-slow-out;
}

.user-search-move {
  transition: all $trans-slow-inout;
}

.user-search-enter,
.user-search-leave-to {
  opacity: 0;
  transform: translateX(-10%);
}

.user-search-leave-active {
  transition: all $trans-in;
  height: 0px;
}

.placeholder__wrapper {
  transition: all $trans-slow-out;
}

.placeholder-move {
  transition: all $trans-slow-inout;
}

.placeholder-enter {
  opacity: 1;
}

.placeholder-leave-to {
  opacity: 0;
}

.placeholder-leave-active {
  transition: all $trans-in;
}
</style>
