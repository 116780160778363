import Wrapper from '@app/Wrapper.js'

Munio.ShopMembers = class extends Wrapper {
  constructor(selector) {
    super(selector)
  }

  component() {
    return import('./Creator.vue')
  }
}
