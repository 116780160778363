<script setup lang="ts">
import { useSlots } from 'vue'

import MdlProgressbar from '@component/mdl/ProgressBar.vue'
import useSlotCheck from '@composable/useSlotCheck.ts'

defineProps<{
  loading?: boolean
  emptyMessage?: string
  listClass?: string | object | Array<string>
  card?: boolean
}>()

const hasSlotActions = useSlotCheck(useSlots().actions)
const hasSlotFooter = useSlotCheck(useSlots().footer)
</script>

<template>
  <div>
    <div class="mdl-list__actions" v-if="hasSlotActions">
      <div class="spacer"></div>

      <slot name="actions"></slot>
    </div>

    <p v-if="emptyMessage">{{ emptyMessage }}</p>

    <div v-if="loading" class="mdl-list__progress">
      <MdlProgressbar indeterminate />
    </div>

    <div class="mdl-list" :class="listClass">
      <slot></slot>
    </div>

    <div v-if="hasSlotFooter" class="mdl-list__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
