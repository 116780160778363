<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
// import { useStore } from '@app/VuexPlugin.ts'
import { trans } from '@/munio/i18n'
import Modal from '@component/Modal.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlProgressBar from '@component/mdl/ProgressBar.vue'
import Icon from '@component/Icon.vue'
import BuyCourse from './BuyCourse.vue'
import ContentCourse from './Content/Course.vue'
import ContentIlt from './Content/ILT.vue'
import ContentMisc from './Content/Misc.vue'
import Info from './Content/Info.vue'
import { useRequirementsStore } from '@app/AccessZoneCompany/store'

defineOptions({
  name: 'CourseModal',
})

const $store = useRequirementsStore()
const props = defineProps<{
  id?: string | number
  requirementId?: string | number
  image?: string
  title: string
  subtitle?: string
  context?: string | number
  selfEnroll?: boolean
}>()
const emit = defineEmits(['close'])

const show = ref(false)
const purchase = ref(false)
const fetching = ref(false)
const processing = ref(false)
const userCourse = ref(null)
const modified = ref(false)
const cart = ref(null)
const course = computed(() => userCourse.value?.course)
const courseImageStyle = computed(() => {
  if (!props.image) return null

  return {
    backgroundImage: `url(${props.image})`,
  }
})
const product = computed(() => userCourse.value?.product)

function close() {
  emit('close')

  if (modified.value && !$store) {
    window.location.reload(true)
  }
}

async function createCart() {
  try {
    fetching.value = true
    const { data } = await window.Munio.api.currentUser.access.checkout(props.requirementId)
    cart.value = data.data
    purchase.value = true
  } catch (e) {
    console.error(e)
  } finally {
    fetching.value = false
  }
}

function showErrorPopup() {
  window.Munio.Flash.error(trans('An unknown error has occured') + '. ' + trans('Please try again') + '.')
}

async function start({ restart = false }) {
  try {
    processing.value = true
    await window.Munio.api.currentUser.courses.launch(userCourse.value.courseId, props.context, restart)
  } catch (err) {
    showErrorPopup()
  } finally {
    processing.value = false
  }
}

async function restart() {
  if (!(await window.Munio.confirm())) {
    return
  }

  try {
    processing.value = true
    const { data } = await window.Munio.api.currentUser.courses.restart(userCourse.value.courseId)
    userCourse.value = data
  } catch (err) {
    showErrorPopup()
  } finally {
    processing.value = false
  }
}

async function onMiscUpload(file) {
  if (!file) return

  try {
    processing.value = true
    const { data } = await window.Munio.api.currentUser.courses.document(userCourse.value.courseId, file)
    userCourse.value = data
  } catch (err) {
    showErrorPopup()
  } finally {
    processing.value = false
  }
}

async function onMiscSetDate(dateFrom, dateTo) {
  if (!dateFrom && !dateTo) return

  try {
    processing.value = true
    const { data } = await window.Munio.api.currentUser.courses.setdate(userCourse.value.courseId, dateFrom, dateTo)
    userCourse.value = data
  } catch (err) {
    showErrorPopup()
  } finally {
    processing.value = false
  }
}

async function onMiscApprove() {
  try {
    processing.value = true
    const { data } = await window.Munio.api.currentUser.courses.approve(userCourse.value.courseId)
    userCourse.value = data
  } catch (err) {
    showErrorPopup()
  } finally {
    processing.value = false
  }
}

async function unenroll() {
  if (!(await window.Munio.confirm())) {
    return
  }

  try {
    processing.value = true
    await window.Munio.api.currentUser.courses.unenroll(userCourse.value.courseId)
    modified.value = true
    close()
  } catch (err) {
    console.error(err)
  } finally {
    processing.value = false
  }
}

async function enroll() {
  try {
    processing.value = true
    await window.Munio.api.currentUser.courses.enroll(userCourse.value.courseId)
    modified.value = true
    await fetchCourse(true, false)
  } catch (err) {
    console.error(err)
  } finally {
    processing.value = false
  }
}

async function fetchCourse(force = false, selfEnroll = null) {
  if (!show.value || !props.id) {
    return
  }

  if (course.value) {
    if (!force && course.value.id === props.id) {
      return
    }
  }

  fetching.value = true

  try {
    const { data } = await window.Munio.api.currentUser.courses.get(props.id, props.context)
    data.canSelfEnroll = selfEnroll ?? props.selfEnroll
    userCourse.value = data
    purchase.value = false
  } catch (err) {
    console.error(err)
  } finally {
    fetching.value = false
  }
}

async function updateSession(session) {
  if ($store) {
    $store.setRequirementSession({ id: props.requirementId, session })
  }

  modified.value = true

  if (!session) {
    close()
  }

  await fetchCourse(true)
}

onMounted(async () => {
  show.value = true
  await fetchCourse()
})
</script>

<template>
  <Modal :show="show" @hidden="close" :loading="processing">
    <div
      class="coursemodal__header px-8 py-12 flex flex-col justify-end"
      :style="courseImageStyle"
      @dblclick="fetchCourse(true)"
    >
      <div v-if="subtitle" class="opaque mb-[5px]">{{ subtitle }}</div>
      <h3>{{ title }}</h3>
    </div>

    <button class="coursemodal__cancel" @click="show = false">
      <Icon name="close" />
    </button>

    <MdlProgressBar v-if="fetching" indeterminate />

    <div v-if="!fetching && course && !purchase">
      <Info v-if="course.info?.length" :header="trans('Participant information')">{{ course.info }}</Info>

      <ContentIlt v-if="course.isIlt" :user-course="userCourse" @update-session="updateSession" />
      <ContentMisc
        v-else-if="course.isMisc"
        :user-course="userCourse"
        :loading="processing"
        :on-upload="onMiscUpload"
        :on-setdate="onMiscSetDate"
        :on-approve="onMiscApprove"
        @unenroll="unenroll"
        @enroll="enroll"
        @restart="restart"
      />
      <ContentCourse
        v-else
        :user-course="userCourse"
        :product="product"
        :processing="processing"
        @start="start"
        @unenroll="unenroll"
        @enroll="enroll"
        @purchase="createCart"
      />
    </div>

    <BuyCourse v-if="!fetching && purchase && cart" :cart="cart" @close="fetchCourse(true)" />

    <template #footer>
      <MdlButton v-if="course?.isMisc && !fetching" :disabled="processing" @click="show = false">
        {{ trans('Close') }}
      </MdlButton>
    </template>
  </Modal>
</template>
