import Wrapper from '@app/Wrapper.js'
import App from './App.vue'
import store from './store.js'

Munio.AccessLinkManager = class extends Wrapper {
  constructor(selector, gateId) {
    super(selector, { gateId })
  }

  component() {
    return App
  }

  store() {
    return store
  }
}
