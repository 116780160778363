<template>
  <input type="hidden" name="_token" :value="token" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

// Access the CSRF token from the global window context
const token = computed(() => window.csrf_token())
</script>
