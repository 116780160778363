<template>
  <div>
    <template v-if="!session">
      <div v-if="incompletePrerequisites.length" class="modal-body modal-body--tinted">
        <h4 class="mdl-subheader">{{ trans('Prerequisites') }}</h4>
        <div class="course-nodes">
          <Prerequisite
            v-for="prerequisite in incompletePrerequisites"
            :key="prerequisite.courseId"
            :prerequisite="prerequisite"
          />
        </div>
      </div>

      <SessionList :sessions="sessions" :can-enroll="!incompletePrerequisites.length" @enrolled="enrolled">
        <div v-if="userCourse.course.attachments.length" class="modal-body pt-0">
          <h4 class="mdl-subheader">{{ trans('Attachments') }}</h4>
          <Attachments :attachments="userCourse.course.attachments" />
        </div>
      </SessionList>
    </template>

    <template v-else>
      <SessionInfo :session="session" @unenrolled="unenrolled">
        <div v-if="userCourse.course.attachments.length" class="modal-body pb-0">
          <h4 class="mdl-subheader">{{ trans('Attachments') }}</h4>
          <Attachments :attachments="userCourse.course.attachments" />
        </div>
      </SessionInfo>
    </template>
  </div>
</template>

<script>
import { trans } from '@/munio/i18n'
import Attachments from '@component/UserCourse/Attachments.vue'
import SessionInfo from './SessionInfo.vue'
import SessionList from './SessionList.vue'
import Prerequisite from './Prerequisite.vue'

export default {
  components: {
    Attachments,
    SessionInfo,
    SessionList,
    Prerequisite,
  },

  props: {
    userCourse: Object,
  },

  data() {
    return {
      modifying: false,
    }
  },

  computed: {
    course() {
      return this.userCourse.course
    },

    sessions() {
      if (!this.userCourse) return []
      return this.userCourse.sessions.filter((s) => !s.isClosed)
    },

    session() {
      if (!this.userCourse) return null
      return this.userCourse.session
    },

    incompletePrerequisites() {
      return this.userCourse.prerequisites.filter((prerequisite) => !prerequisite.isCompleted)
    },
  },

  methods: {
    trans,
    enrolled(session) {
      this.$emit('updateSession', session)
    },
    unenrolled() {
      this.$emit('updateSession')
    },
  },
}
</script>
