const owl = '🦉'

// @ts-ignore
class DevConsole implements Console {
  private static enabled = import.meta.env.DEV
  private static emoji?: string

  static symbol(emoji: string, callback: Function): void {
    this.emoji = emoji
    callback()
    this.emoji = undefined
  }

  static assert(value?: string, message?: string, ...optionalParams: unknown[]): void {
    if (this.enabled) {
      console.assert(value, message, ...optionalParams)
    }
  }

  static clear(): void {
    if (this.enabled) {
      console.clear()
    }
  }

  static count(label?: string): void {
    if (this.enabled) {
      console.count(label)
    }
  }

  static countReset(label?: string): void {
    if (this.enabled) {
      console.countReset(label)
    }
  }

  static debug(message?: string, ...optionalParams: unknown[]): void {
    if (this.enabled) {
      console.debug(this.emoji ?? owl, message, ...optionalParams)
    }
  }

  static dir(obj?: unknown, options?: unknown): void {
    if (this.enabled) {
      console.dir(obj, options)
    }
  }

  static dirxml(...data: unknown[]): void {
    if (this.enabled) {
      console.dirxml(...data)
    }
  }

  static error(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.enabled) {
      console.error(this.emoji ?? owl, message, ...optionalParams)
    }
  }

  static group(...label: unknown[]): void {
    if (this.enabled) {
      console.group(...label)
    }
  }

  static groupCollapsed(...label: unknown[]): void {
    if (this.enabled) {
      console.groupCollapsed(...label)
    }
  }

  static groupEnd(): void {
    if (this.enabled) {
      console.groupEnd()
    }
  }

  static info(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.enabled) {
      console.info(this.emoji ?? owl, message, ...optionalParams)
    }
  }

  static log(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.enabled) {
      console.log(this.emoji ?? owl, message, ...optionalParams)
    }
  }

  static table(tabularData?: unknown, properties?: string[]): void {
    if (this.enabled) {
      console.table(tabularData, properties)
    }
  }

  static time(label?: string): void {
    if (this.enabled) {
      console.time(label)
    }
  }

  static timeEnd(label?: string): void {
    if (this.enabled) {
      console.timeEnd(label)
    }
  }

  static timeLog(label?: string, ...data: unknown[]): void {
    if (this.enabled) {
      console.timeLog(label, ...data)
    }
  }

  static timeStamp(label?: string): void {
    if (this.enabled) {
      console.timeStamp(label)
    }
  }

  static trace(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.enabled) {
      console.trace(this.emoji ?? owl, message, ...optionalParams)
    }
  }

  static warn(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.enabled) {
      console.warn(this.emoji ?? owl, message, ...optionalParams)
    }
  }

  static profile(label?: string): void {
    if (this.enabled) {
      console.profile(label)
    }
  }

  static profileEnd(label?: string): void {
    if (this.enabled) {
      console.profileEnd(label)
    }
  }
}

// Attach to the global object
declare global {
  interface Window {
    dev: typeof DevConsole
  }
}

window.dev = DevConsole

export default DevConsole
