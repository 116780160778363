<template>
  <div class="course-nodes__node course-nodes__node-end" :data-status="isCompleted ? 'completed' : 'not-started'">
    <div class="course-nodes__node-subway">
      <div class="subway-track--in" :class="{ 'is-active': isConnected }"></div>
      <div class="subway-icon">
        <CircularProgress :size="32" :strokeWidth="3" :progress="progress" />
        <Icon :name="isCompleted ? 'star' : 'star_border'" />
      </div>
    </div>

    <div class="course-nodes__node-container">
      <div class="course-nodes__node-title" v-if="!isCompleted">
        <em>{{ trans('Not completed') }}</em>
      </div>
      <div class="course-nodes__node-link" v-else>
        <a :href="url" target="_blank">
          {{ trans('Download diploma') }}
        </a>
      </div>
      <div class="course-nodes__node-text" v-if="isCompleted">
        {{ expirationText }}
        <span v-if="diploma && !diploma.expiration.isUnlimited" class="opaque">
          &mdash; {{ diploma.expiration.date }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { trans } from '@/munio/i18n'
import CircularProgress from '@component/CircularProgress.vue'
import Icon from '@component/Icon.vue'

export default {
  name: 'NodeCompletion',

  components: {
    CircularProgress,
    Icon,
  },

  props: {
    connected: { type: Boolean, default: false },
    attempt: { type: Object },
    diploma: { type: Object },
  },

  computed: {
    progress() {
      if (this.isCompleted) {
        return 100
      }

      if (!this.connected || !this.attempt || (this.attempt.nodes || []).length > 0) {
        return 0
      }

      return this.attempt.progress || 0
    },
    isExpired() {
      if (!this.diploma) {
        return false
      }
      return this.diploma.expiration.isExpired
    },
    isCompleted() {
      if (!this.diploma) {
        return false
      }
      return this.diploma.id > 0 && !this.isExpired
    },
    isConnected() {
      return this.connected || this.isCompleted
    },
    expirationText() {
      if (!this.diploma) {
        return null
      }
      return this.diploma.expiration.text
    },
    url() {
      if (this.isCompleted) {
        return this.diploma.url || `/pdf/diploma/${this.diploma.id}.pdf`
      }

      return null
    },
  },

  methods: {
    trans,
  },
}
</script>
