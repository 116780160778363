<script setup lang="ts">
import { nextTick, ref } from 'vue'
import { trans } from '@/munio/i18n'
// This is a wrapper for vue-treeselect
// https://vue-treeselect.js.org/#api
import TreeSelect from '@/libs/vue-treeselect'

defineOptions({
  name: 'TreeSelect',
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    name?: string
    clearAllText?: string
    clearValueText?: string
    limitText?: (count: number) => string
    loadingText?: string
    noChildrenText?: string
    noOptionsText?: string
    noResultsText?: string
    placeholder?: string
    retryText?: string
    retryTitle?: string
    searchPromptText?: string
    searchable?: boolean
    clearable?: boolean
    multiple?: boolean
    modelValue?: unknown
    disabled?: boolean
    required?: boolean
    tabindex?: string | number
    autofocus?: boolean
    options?: unknown[]
    alwaysOpen?: boolean
    displayOnly?: boolean
    appendToBody?: boolean
    defaultExpandLevel?: number
    disableFuzzyMatching?: boolean
  }>(),
  {
    clearAllText: trans('Clear all'),
    clearValueText: trans('Clear'),
    limitText: (count: number) => 'and '.concat(String(count), ' more'),
    loadingText: trans('Loading'),
    noChildrenText: trans('No options'),
    noOptionsText: trans('No options'),
    noResultsText: trans('No entries found'),
    placeholder: trans('Select'),
    retryText: `${trans('Retry')}?`,
    retryTitle: trans('Retry'),
    searchPromptText: trans('Search'),
    defaultExpandLevel: 0,
    disableFuzzyMatching: true,
  },
)

const emit = defineEmits(['update:modelValue', 'search-change', 'close', 'open', 'select', 'deselect'])

const $select = ref<typeof TreeSelect & { getMenu(): HTMLDivElement }>()

function onOpen(...args: unknown[]) {
  emit('open', ...args)

  if (props.displayOnly) {
    return
  }

  nextTick(() => {
    const menu = $select.value!.getMenu()

    if (!menu) {
      return
    }

    const selected = menu.querySelector<HTMLDivElement>('.vue-treeselect__option--selected')

    if (selected) {
      const position = selected.offsetTop - menu.offsetHeight / 2 + selected.offsetHeight / 2
      menu.scrollTo({ top: position, behavior: 'instant' })
    }
  })
}
</script>

<template>
  <TreeSelect
    ref="$select"
    v-bind="$attrs"
    :name="name"
    :model-value="modelValue"
    :tab-index="tabindex !== undefined ? Number(tabindex) : undefined"
    :clear-all-text="clearAllText"
    :clear-value-text="clearValueText"
    :limit-text="limitText"
    :loading-text="loadingText"
    :no-children-text="noChildrenText"
    :no-options-text="noOptionsText"
    :no-results-text="noResultsText"
    :placeholder="placeholder"
    :retry-text="retryText"
    :retry-title="retryTitle"
    :search-prompt-text="searchPromptText"
    :searchable="searchable"
    :clearable="clearable"
    :multiple="multiple"
    :disabled="disabled"
    :required="required"
    :options="options"
    :always-open="alwaysOpen || displayOnly"
    :default-expand-level="defaultExpandLevel"
    :class="{ 'display-only': displayOnly }"
    :append-to-body="appendToBody"
    :disable-fuzzy-matching="disableFuzzyMatching"
    :auto-focus="autofocus"
    @open="onOpen"
    @close="(...args) => $emit('close', ...args)"
    @update:model-value="(...args) => $emit('update:modelValue', ...args)"
    @search-change="(...args) => $emit('search-change', ...args)"
    @select="(...args) => $emit('select', ...args)"
    @deselect="(...args) => $emit('deselect', ...args)"
  >
    <template v-if="$slots['option-label']" #option-label="optionProps">
      <slot name="option-label" v-bind="optionProps" />
    </template>
    <template v-if="$slots['value-label']" #value-label="valueProps">
      <slot name="value-label" v-bind="valueProps" />
    </template>
    <template v-if="$slots['before-list']" #before-list>
      <slot name="before-list" />
    </template>
    <template v-if="$slots['after-list']" #after-list>
      <slot name="after-list" />
    </template>
  </TreeSelect>
</template>
