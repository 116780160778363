<script setup lang="ts">
import CourseLauncherModal from './CourseModal.vue'

defineOptions({
  name: 'Wrapper',
})

defineProps<{
  course?: {
    id: string | number
    title: string
    subtitle: string
    image: string
    context?: string | number
  }
  requirementId?: string | number
  selfEnroll?: boolean
}>()
const emit = defineEmits(['close'])
</script>

<template>
  <CourseLauncherModal
    v-if="course"
    :id="course.id"
    :requirement-id="requirementId"
    :title="course.title"
    :subtitle="course.subtitle"
    :image="course.image"
    :context="course.context"
    :self-enroll="selfEnroll"
    @close="emit('close')"
  />
</template>
