<script setup lang="ts">
import { computed } from 'vue'
import Icon from '@component/Icon.vue'

type Step = {
  label: string
  description?: string
  icon?: string
  selectable?: boolean
  done?: boolean
  failed?: boolean
}

const props = withDefaults(
  defineProps<{
    steps: Step[]
    current: number
    inline?: boolean
  }>(),
  {
    inline: false,
  },
)

const emit = defineEmits<{
  (e: 'update:current', value: number): void
}>()

const classNames = computed(() => ({
  'mdl-stepper': true,
  'mdl-stepper--horizontal': props.inline,
  'mdl-stepper--horizontal-alt': !props.inline,
}))

function isPassed(index) {
  return index < props.current || props.current + 1 === props.steps.length
}

function isActive(index) {
  return index === props.current
}

function isClickable(index) {
  if (props.steps[index].selectable !== undefined) {
    return props.steps[index].selectable
  }

  return isPassed(index)
}

function isDone(index) {
  if (props.steps[index].done !== undefined) {
    return props.steps[index].done
  }

  return isPassed(index)
}

function isFailed(index) {
  if (props.steps[index].failed !== undefined) {
    return props.steps[index].failed
  }
  return false
}

function onClick(index) {
  if (isClickable(index)) {
    emit('update:current', index)
  }
}
</script>
<template>
  <ul :class="classNames">
    <template v-for="(step, index) in steps" :key="`step-${step.label}`">
      <li
        class="mdl-stepper__step"
        :class="{
          'is-done': isPassed(index),
          'is-failed': isFailed(index),
          'is-active': isActive(index),
          'is-clickable': isClickable(index),
        }"
        @click.stop="onClick(index)"
      >
        <span class="mdl-stepper__step-circle">
          <Icon v-if="step.icon" :name="step.icon" />
          <Icon v-else-if="isFailed(index) && isActive(index)" name="clear" />
          <Icon v-else-if="isDone(index)" name="check" />
          <span v-else>{{ index + 1 }}</span>
        </span>
        <span class="mdl-stepper__step-content">
          <span class="mdl-stepper__step-label" v-html="step.label"></span>
          <span class="mdl-stepper__step-description" v-if="step.description" v-html="step.description"></span>
        </span>
      </li>
      <li v-if="inline" class="mdl-stepper__separator" :key="`separator-${step.label}`"></li>
    </template>
  </ul>
</template>
