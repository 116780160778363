<template>
  <div role="dialog" :class="classes" @keydown.esc="hideModal">
    <template v-if="active">
      <div
        role="document"
        class="modal-dialog"
        :class="{
          'modal-lg': size === 'large',
          'modal-xl': size === 'xlarge',
          'modal-fullscreen': size === 'fullscreen',
        }"
        :style="{ width: optionalWidth }"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            v-if="title || image || $slots.header || $slots.title"
            :class="{ 'modal-header--image': image }"
            :style="headerStyle"
          >
            <button v-if="inline ? false : dismissable" type="button" class="close" @click="hideModal">
              <span class="material-icons">close</span>
            </button>
            <slot name="header">
              <h4 class="modal-title">
                <slot name="title">
                  {{ title }}
                </slot>
              </h4>
            </slot>
          </div>

          <div class="modal-stepper" v-if="hasStepperSlot">
            <slot name="stepper"></slot>
            <hr class="my-0" />
          </div>

          <div class="modal-body" v-if="hasBodySlot">
            <slot name="body"></slot>
          </div>

          <slot></slot>

          <div class="modal-footer" v-if="hasFooterSlot">
            <slot name="footer"></slot>
          </div>

          <div class="modal-actions" v-if="hasActionsSlot">
            <slot name="actions"></slot>
          </div>
        </div>

        <div class="modal-progress" v-if="loading">
          <MdlProgressBar v-if="loading === true" indeterminate />
          <MdlProgressBar v-else :progress="loading" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import $ from 'jquery'
import MdlProgressBar from '@component/mdl/ProgressBar.vue'
import useSlotCheck from '@composable/useSlotCheck'

export default {
  name: 'Modal',

  components: {
    MdlProgressBar,
  },

  props: {
    show: { type: Boolean, required: true },
    title: { type: String, default: '' },
    image: { type: String },
    backdrop: { type: [Boolean, String], default: 'static' },
    keyboard: { type: Boolean, default: true },
    size: { type: String, validate: (value) => ['fullscreen', 'xlarge', 'large'].includes(value) },
    xlarge: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    width: { type: String, default: null },
    loading: { type: [Boolean, Number], default: false },
    dismissable: { type: Boolean, default: true },
    inline: Boolean,
  },

  emits: ['show', 'shown', 'hide', 'hidden', 'open', 'close'],

  setup(props, { slots }) {
    const hasStepperSlot = useSlotCheck(slots.stepper)
    const hasBodySlot = useSlotCheck(slots.body)
    const hasActionsSlot = useSlotCheck(slots.actions)
    const hasFooterSlot = useSlotCheck(slots.footer)

    return {
      hasStepperSlot,
      hasBodySlot,
      hasActionsSlot,
      hasFooterSlot,
    }
  },

  data() {
    return {
      active: false,
      container: this.inline ? undefined : document.getElementById('modal-container'),
    }
  },

  computed: {
    classes() {
      return {
        modal: true,
        'modal-inline': this.inline,
        fade: !this.inline,
      }
    },

    headerStyle() {
      if (!this.image) {
        return undefined
      }

      return {
        backgroundImage: `url(${this.image})`,
      }
    },

    optionalWidth() {
      if (this.width === null) {
        return null
      } else if (Number.isInteger(this.width)) {
        return this.width + 'px'
      }
      return this.width
    },
  },

  mounted() {
    const modal = this.$el

    if (!this.inline) {
      const container = document.getElementById('modal-container')

      if (container && !container.contains(modal)) {
        container.appendChild(modal)
      }
    }

    $(modal).on('show.bs.modal', this.onShow)
    $(modal).on('shown.bs.modal', this.onShown)
    $(modal).on('hide.bs.modal', this.onHide)
    $(modal).on('hidden.bs.modal', this.onHidden)

    if (this.show) {
      this.showModal()
    }
  },

  beforeUnmount() {
    const modal = this.$el
    const backdrop = document.querySelector('.modal-backdrop.fade.in')

    $(modal).off('show.bs.modal', this.onShow)
    $(modal).off('shown.bs.modal', this.onShown)
    $(modal).off('hide.bs.modal', this.onHide)
    $(modal).off('hidden.bs.modal', this.onHidden)

    if (backdrop) {
      backdrop.remove()
    }

    if (this.show) {
      this.hideModal()
    }
  },

  watch: {
    show(val) {
      if (val) {
        this.showModal()
      } else {
        this.hideModal()
      }
    },
  },

  methods: {
    showModal() {
      let backdrop = this.inline ? false : this.backdrop

      if (backdrop === true && !this.dismissable) {
        backdrop = 'static'
      }

      $(this.$el).modal({
        show: true,
        keyboard: this.keyboard,
        backdrop,
      })

      document.addEventListener('keyup', this.hideModalOnEscape)
    },

    hide() {
      this.hideModal()
    },

    hideModal() {
      $(this.$el).modal('hide')
      document.removeEventListener('keyup', this.hideModalOnEscape)
    },

    hideModalOnEscape(e) {
      if (e.keyCode === 27 && this.dismissable) {
        this.hideModal()
      }
    },

    onShow() {
      this.active = true
      this.$emit('show', true)
      this.$emit('open', true)
    },
    onShown() {
      this.$emit('shown', true)
    },
    onHide() {
      this.$emit('hide', false)
    },
    onHidden() {
      this.active = false
      this.$emit('hidden', false)
      this.$emit('close', false)
    },
  },
}
</script>
