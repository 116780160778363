class Salesorder {
  constructor(id) {
    this.id = id
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.salesorder', { order: this.id }))
  }

  update(data) {
    return Munio.$http.post(
      Munio.route('api.web.salesorder.update', { order: this.id }),
      JSON.parse(JSON.stringify(data)),
    )
  }

  approve() {
    return Munio.$http.post(Munio.route('api.web.salesorder.approve', { order: this.id }))
  }

  bill() {
    return Munio.$http.post(Munio.route('api.web.salesorder.bill', { order: this.id }))
  }

  ledger() {
    return Munio.$http.post(Munio.route('api.web.salesorder.ledger', { order: this.id }))
  }

  duplicate(replace = false) {
    return Munio.$http.post(Munio.route('api.web.salesorder.duplicate', { order: this.id }), { replace })
  }

  remove() {
    return Munio.$http.delete(Munio.route('api.web.salesorder.delete', { order: this.id }))
  }
}

export default Salesorder
