import { reactive, createApp, h } from 'vue'
import App from './App.vue'
import Snackbar from '@component/mdl/Snackbar.vue'

class Notifications {
  app = null
  instance = null

  constructor(selector) {
    Munio.Notifications = this

    this.selector = selector
    this.mount()
  }

  mount() {
    if (this.app) {
      return this
    }

    this.app = createApp({
      name: 'Notifications',
      render: () => h(App),
    })
    this.app.mount(this.selector)

    return this
  }

  show(toast) {
    this.instance.refs.snackbar.show(toast)
  }

  error(message, options) {
    this.show({
      ...options,
      message,
      type: 'danger',
    })
  }

  hide() {
    this.instance.refs.snackbar.hide()
  }
}

new Notifications('#notifications')
