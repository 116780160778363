import $ from 'jquery'

Munio.behaviors.toggleElements = {
  selector: '[data-toggle-element]',
  attach: function (context) {
    $(this.selector, context).each(function (i, el) {
      const $el = $(el)

      $el.on('toggle-elements', function (e, data, context) {
        const element = $(data)
        const target = element.data('toggle-element')
        const targets = $(target, context)
        const state = element.is(':checked')

        $.each(targets, function (key, item) {
          const element = $(item)

          if (element.is('[data-toggle-elements="on"]')) {
            if (!state) {
              element.hide()
            } else {
              element.show()
            }
          }

          if (element.is('[data-toggle-elements="off"]')) {
            if (state) {
              element.hide()
            } else {
              element.show()
            }
          }
        })
      })

      $el.on('change', function () {
        $el.trigger('toggle-elements', $el, context)
      })

      $el.trigger('toggle-elements', $el, context)
    })
  },
}
