import Wrapper from '@app/Wrapper.js'
import store from './store.js'

Munio.UserCourses = class extends Wrapper {
  constructor(selector, id) {
    super(selector, { id })
  }

  component() {
    return import('./App.vue')
  }

  store() {
    return store
  }
}
