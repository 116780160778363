<script setup lang="ts">
import { h } from 'vue'

withDefaults(
  defineProps<{
    active?: boolean
    multicolor?: boolean
    small?: boolean
  }>(),
  {
    active: true,
    multicolor: false,
    small: false,
  },
)

function createLayer(index: number) {
  return h('div', { class: `mdl-spinner__layer mdl-spinner__layer-${index}` }, [
    h('div', { class: 'mdl-spinner__circle-clipper mdl-spinner__left' }, [h('div', { class: 'mdl-spinner__circle' })]),
    h('div', { class: 'mdl-spinner__gap-patch' }, [h('div', { class: 'mdl-spinner__circle' })]),
    h('div', { class: 'mdl-spinner__circle-clipper mdl-spinner__right' }, [h('div', { class: 'mdl-spinner__circle' })]),
  ])
}

const Layer1 = createLayer(1)
const Layer2 = createLayer(2)
const Layer3 = createLayer(3)
const Layer4 = createLayer(4)
</script>

<template>
  <div
    class="mdl-spinner is-upgraded"
    :class="{
      'mdl-spinner--single-color': !multicolor,
      'mdl-spinner--small': small,
      'is-active': active,
    }"
  >
    <Layer1 />
    <Layer2 />
    <Layer3 />
    <Layer4 />
  </div>
</template>
