import { ref, Fragment, Text, Comment, Ref, Slot, VNode, watch } from 'vue'

export function useSlotCheck(slot?: Slot, ...args: unknown[]): Ref<boolean> {
  const hasSlot = ref(false)

  watch(
    () => slot?.(...args),
    (vnodes) => {
      hasSlot.value = !vNodesIsEmpty(vnodes)
    },
    { immediate: true },
  )

  return hasSlot
}

export function vNodesIsEmpty(vnodes: VNode[] | null | undefined): boolean {
  if (vnodes === null || vnodes === undefined) return true

  return vnodes.every((node) => {
    // Comment nodes are always considered empty
    if (node.type === Comment) {
      return true
    }

    // Text nodes are empty if the text content is empty or only whitespace
    if (node.type === Text && !(node.children as string | null)?.trim()) {
      return true
    }

    if (node.type === Fragment && vNodesIsEmpty(node.children as VNode[])) {
      return true
    }

    return false
  })
}

export default useSlotCheck
