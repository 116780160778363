<template>
  <MdlListItem class="user-search__user" :title="user.email" :thumbnail="thumbnail">
    <template #actions>
      <!-- Show an input to let the admin give a name to the invited person -->
      <input
        type="text"
        :id="`user-search-name-${user.id}`"
        :placeholder="trans('Full name')"
        :value="user.fullname"
        @input="$emit('change:name', $event.target.value)"
      />
      <!-- Remove action -->
      <MdlButton icon="close" @click="$emit('remove')" />
    </template>
  </MdlListItem>
</template>

<script>
import MdlListItem from '@component/mdl/ListItem.vue'
import MdlButton from '@component/mdl/Button.vue'
import { gravatar } from '@/munio/utils/index.js'
import { trans } from '@/munio/i18n/index.js'

export default {
  props: {
    user: { type: Object, required: true },
  },

  components: {
    MdlListItem,
    MdlButton,
  },

  computed: {
    thumbnail() {
      return gravatar(this.user.email)
    },
  },

  methods: {
    trans,
  },
}
</script>

<style scoped lang="scss">
input {
  height: auto;
  font-size: 0.9em;

  &::placeholder {
    font-size: 0.9em;
  }
}
</style>
