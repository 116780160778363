import { form, lineForm, getCompanyAddress } from './helpers.js'

function initLine(state, uid) {
  let lines = state.form.lines

  if (lines[uid] === undefined) {
    const linesAll = (state.order.data?.lines || []).concat(state.form.linesAdded)

    lines = state.form.lines = Object.assign({}, lines, {
      [uid]: lineForm(
        state.form,
        linesAll.find((line) => line.uid === uid),
      ),
    })
  }

  return lines[uid]
}

const init = (state, order) => {
  state.order = order

  reset(state)
}

const reset = (state) => {
  state.form = form()
}

const processing = (state, value) => {
  state.processing = value
}

const setSalesperson = (state, salesperson) => {
  state.salespeople.show = false
  state.form.salesperson = salesperson
}

const setComment = (state, comment) => {
  state.form.payment.invoice.comment = comment
}

const replaceCustomer = (state, { customer, payment = false }) => {
  const target = payment ? state.order.data.payment.customer : state.order.data.customer

  Object.assign(target, customer)
}

const setCustomer = (state, { customer, payment, replace }) => {
  if (payment) {
    setPaymentCustomer(state, { customer, replace })
  } else {
    if (replace) {
      replaceCustomer(state, { customer })
    } else {
      state.form.customer = customer
    }

    if (customer.invoice?.customer) {
      setPaymentCustomer(state, { customer: customer.invoice.customer, replace })
    }
  }
}

const setPaymentCustomer = (state, { customer, replace }) => {
  if (replace) {
    replaceCustomer(state, { customer, payment: true })
  } else {
    state.form.payment.customer = customer
    state.form.payment.address = getCompanyAddress(customer.company) || false
  }
}

const setPaymentAddress = (state, address) => {
  state.form.payment.address = address
}

const setInvoiceEmail = (state, email) => {
  state.form.payment.invoice.email = email
}

const setInvoiceDays = (state, days) => {
  state.form.payment.invoice.days = days
}

const setInvoiceReference = (state, reference) => {
  state.form.payment.invoice.reference = reference
}

const setContact = (state, contact) => {
  state.form.contact = contact
}

const setLineInput = (state, { uid, input }) => {
  Object.assign(initLine(state, uid), input)
}

const addProduct = (state, { product, price, unlimited, quantity = 1, session = null }) => {
  const uid = [product.sku, session?.id].filter((v) => v).join(':')

  if (session) {
    state.form.sessions = Object.assign({}, state.form.sessions, { [session.id]: session })
  }

  if (!state.order.data.lines.find((line) => line.uid === uid)) {
    let currentIndex = state.form.linesAdded.findIndex((line) => line.uid === uid)
    let line = {
      uid,
      shopProductId: product.instanceId,
      productId: product.id,
      sku: product.sku,
      identifier: product.course.identifier,
      title: product.course.title,
      avatar: product.course.image.avatar,
      vatRate: product.vatRate,
      isUnlimited: unlimited,
      quantity,
      session,
      discount: 0,
      price: {
        full: price,
        final: price,
      },
      amount: {},
    }

    if (currentIndex >= 0) {
      state.form.linesAdded = [
        ...state.form.linesAdded.slice(0, currentIndex),
        line,
        ...state.form.linesAdded.slice(currentIndex + 1),
      ]
    } else {
      state.form.linesAdded.push(line)
    }
  }

  // setModalShow(state, ['products', false])
  setLineInput(state, {
    uid,
    input: {
      product: product.id,
      session: session?.id,
      price,
      quantity,
      unlimited,
      removed: false,
    },
  })
}

const setModalData = (state, [type, data]) => {
  state[type].data = data
}

const setModalShow = (state, [type, value]) => {
  state[type].show = !!value
}

const setModalLoading = (state, [type, value]) => {
  state[type].loading = !!value
}

const setAccounting = (state, value) => {
  state.form.enableAccounting = value
}

const setLicensing = (state, value) => {
  state.form.enableLicensing = value
}

export default {
  init,
  reset,
  processing,
  setSalesperson,
  setComment,
  setContact,
  setCustomer,
  setPaymentCustomer,
  setPaymentAddress,
  setInvoiceEmail,
  setInvoiceDays,
  setInvoiceReference,
  setLineInput,
  replaceCustomer,
  addProduct,
  setModalData,
  setModalShow,
  setModalLoading,
  setAccounting,
  setLicensing,
}
