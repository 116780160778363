import Wrapper from '@app/Wrapper.js'
import store from './store/index.js'

Munio.UserRelationshipManager = class extends Wrapper {
  constructor(selector, userId) {
    super(selector, {
      userId,
    })
  }

  component() {
    return import('./App.vue')
  }

  store() {
    return store
  }
}
