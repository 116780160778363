<template>
  <div class="mdl-searchbar__result flex-col is-selectable" :class="{ 'is-selected': selected }" @click="onSelect">
    <div class="flex items-center w-full">
      <div class="flex-grow">
        <div>
          {{ title }}
          <small v-if="selected" @click.stop class="opaque select-none">
            #
            <span class="select-all">{{ candidate.user.id }}</span>
          </small>
        </div>
        <div class="opaque flex flex-col">
          <small>{{ subtitle }}</small>
          <small>{{ description }}</small>
        </div>
      </div>

      <span class="flex text-sm flex-col ml-4 text-right">
        <span class="font-medium text-gray-900">{{ matchPercent }}</span>
        <span class="text-gray-500">{{ trans('Match') }}</span>
      </span>
    </div>

    <div v-if="selected" class="flex justify-between w-full mt-4">
      <MdlChip
        :icon="chipIcon(candidate.match.firstname)"
        v-tooltip="candidate.match.firstname * 100"
        contact-class="mr-0"
        :color-bg="chipBg(candidate.match.firstname)"
        :color-text="chipText(candidate.match.firstname)"
      >
        {{ trans('Firstname') }}
      </MdlChip>

      <MdlChip
        :icon="chipIcon(candidate.match.lastname)"
        v-tooltip="candidate.match.lastname * 100"
        contact-class="mr-0"
        :color-bg="chipBg(candidate.match.lastname)"
        :color-text="chipText(candidate.match.lastname)"
      >
        {{ trans('Lastname') }}
      </MdlChip>

      <MdlChip
        :icon="chipIcon(candidate.match.employer)"
        v-tooltip="candidate.match.employer * 100"
        contact-class="mr-0"
        :color-bg="chipBg(candidate.match.employer)"
        :color-text="chipText(candidate.match.employer)"
      >
        {{ trans('Employer') }}
      </MdlChip>

      <MdlChip
        :icon="chipIcon(candidate.match.email)"
        v-tooltip="candidate.match.email * 100"
        contact-class="mr-0"
        :color-bg="chipBg(candidate.match.email)"
        :color-text="chipText(candidate.match.email)"
      >
        {{ trans('E-mail') }}
      </MdlChip>

      <MdlChip
        :icon="chipIcon(candidate.match.mobile)"
        v-tooltip="candidate.match.mobile * 100"
        contact-class="mr-0"
        :color-bg="chipBg(candidate.match.mobile)"
        :color-text="chipText(candidate.match.mobile)"
      >
        {{ trans('Mobile') }}
      </MdlChip>
    </div>
  </div>

  <!--
  <mdl-list-item type="card"
    :title="title"
    :subtitle="subtitle"
    :thumbnail="avatar"
    :background="avatarColor"
    expandable
    :expanded="selected"
    @toggle="onSelect"
  >
    <template v-slot:description>
      {{ description }}
    </template>

    <template v-slot:actions>
      <icon :name="selected ? 'radio_button_checked' : 'radio_button_unchecked'" class="mdl-color-text--primary"/>
      <span :class="['label', {'label-default': ! selected, 'label-primary': selected}]">{{ trans(':percent match', {percent: matchPercent}) }}</span>
    </template>
  </mdl-list-item>
  -->
</template>

<script>
import { formatPhone } from '@/munio/utils/index.js'
import MdlChip from '@component/mdl/Chip.vue'
import { trans } from '@/munio/i18n/index.js'
import tooltip from '@directive/tooltip.js'

export default {
  name: 'User',

  components: {
    MdlChip,
  },

  directives: {
    tooltip,
  },

  props: {
    candidate: { type: Object, required: true },
    selected: { type: Boolean },
  },

  computed: {
    title() {
      return this.candidate.user.fullname
    },

    subtitle() {
      return this.candidate.user.company ? this.candidate.user.company.name : null
    },

    description() {
      const arr = []

      if (this.candidate.user.email) {
        arr.push(this.candidate.user.email)
      }

      if (this.candidate.user.mobile) {
        arr.push(formatPhone(this.candidate.user.mobile))
      }

      return arr.join(' / ')
    },

    avatar() {
      return this.candidate.user.avatar.url
    },

    avatarColor() {
      if (this.isMatched) {
        return 'mdl-color--success'
      }

      return 'mdl-color--default'
    },

    matchPercent() {
      return `${Math.round(this.candidate.match.percent * 100)}%`
    },
  },

  methods: {
    trans,
    formatPhone,

    onSelect() {
      this.$emit('select', this.candidate.user.id)
    },

    chipIcon(match) {
      if (match >= 0.95) {
        return 'star'
      }
      return match < 0.5 ? 'not_interested' : 'star_half'
    },

    chipBg(match) {
      if (match >= 0.95) {
        return 'success'
      }
      return match < 0.5 ? 'default' : 'warning'
    },

    chipText(match) {
      if (match >= 0.95) {
        return 'success-contrast'
      }
      return match < 0.5 ? 'grey-700' : 'warning-contrast'
    },
  },
}
</script>
