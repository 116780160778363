import Wrapper from '@app/Wrapper.js'

Munio.ComponentViewer = class extends Wrapper {
  constructor(selector) {
    super(selector)
  }

  get name() {
    return 'ComponentViewer'
  }

  component() {
    return import('./App.vue')
  }
}
