import Wrapper from '@app/Wrapper.js'
import store from './store/index.js'

Munio.ZoneIndex = class extends Wrapper {
  constructor(selector, gate) {
    super(selector, {
      gate,
    })
  }

  get name() {
    return 'ZoneIndex'
  }

  component() {
    return import('./index.vue')
  }

  store() {
    return store
  }
}
