import Wrapper from '@app/Wrapper.js'

Munio.Approvals = class extends Wrapper {
  get name() {
    return 'Approvals'
  }

  component() {
    return import('./App.vue')
  }
}
