<template>
  <div id="login">
    <div class="login-left">
      <a href="/" class="logo-wrapper">
        <img class="logo" :src="logo" alt="Munio LMS" />
      </a>
    </div>

    <div v-if="isConfirmed" class="login-right">
      <div class="login-header">
        <h1 class="header-title">{{ trans('Thank you') }}</h1>
        <p class="my-8">{{ trans("Let's get out of your hair. You will now be redirected back.") }}</p>
      </div>
    </div>

    <div v-else class="login-right">
      <div class="login-header">
        <h1 class="header-title">
          {{ trans('Confirm your profile') }}
        </h1>
        <p class="mt-8">
          {{
            trans(
              "Since it's been a while since we last saw you, we just want you to confirm that your information is still up to date.",
            )
          }}
        </p>
      </div>

      <div class="login-content flex flex-col">
        <MdlStepper :steps="steps" :current="step" @step="step = $event" class="p-0" />

        <div class="flex-grow">
          <Transition :name="transition">
            <fieldset v-if="step === 0" key="profile">
              <MdlTextfield
                v-model="form.firstname"
                required
                :readonly="isConfirmed"
                :floating-label="label('firstname')"
                :class="classes('firstname')"
                :error="error('firstname')"
                @update:modelValue="clearError('firstname')"
                @keypress.enter="next('firstname')"
              />
              <MdlTextfield
                v-model="form.lastname"
                required
                :readonly="isConfirmed"
                :floating-label="label('lastname')"
                :class="classes('lastname')"
                :error="error('lastname')"
                @update:modelValue="clearError('lastname')"
                @keypress.enter="next('lastname')"
              />
              <MdlTextfield
                type="email"
                v-model="form.email"
                required
                :readonly="isConfirmed"
                :floating-label="label('email')"
                :class="classes('email')"
                :error="error('email')"
                @update:modelValue="clearError('email')"
                @keypress.enter="next('email')"
              />
              <MdlPhonenumber
                mobile
                ref="mobile"
                v-model="form.mobile"
                :readonly="isConfirmed"
                :label="label('mobile')"
                :class="classes('mobile')"
                :error="error('mobile')"
                @update:modelValue="clearError('mobile')"
                @keypress.enter="next('mobile')"
                @update="isMobileNumberValid = $event.isValid"
              />
            </fieldset>

            <fieldset v-else-if="step === 1" key="employer">
              <CompanySearch
                v-if="changeEmployer"
                class="mt-4"
                :name-label="trans('Employer')"
                v-model="form.employer"
                @search="form.employer = null"
              />

              <div v-else>
                <Company :company="form.employer" :country="form.employer.country" />

                <a @click="changeEmployer = true" class="block mt-8">
                  {{ trans('Click here to change employer') }}
                </a>
              </div>
            </fieldset>
          </Transition>
        </div>

        <Captcha v-if="step === 1" class="mt-8" v-model="form.captcha" />

        <div>
          <Transition name="fade" mode="out-in">
            <div v-if="step === 0" key="profile" class="action-bar">
              <MdlButton primary @click="next" :disabled="errors">
                {{ trans('Continue') }}
              </MdlButton>
              <MdlButton @click="cancel">{{ trans('Cancel') }}</MdlButton>
            </div>

            <div v-else-if="step === 1" key="employer" class="action-bar">
              <MdlButton
                raised
                primary
                @click="confirm"
                :disabled="!canConfirm || isConfirming || !form.employer || !captchaValid"
              >
                {{ trans('Confirm') }}
              </MdlButton>
              <MdlButton @click="back" :disabled="isConfirming">{{ trans('Back') }}</MdlButton>
            </div>
          </Transition>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="login-progress">
      <MdlProgressbar indeterminate></MdlProgressbar>
    </div>

    <MobileConfirmModal
      :show="mobileVerification.show"
      :codeExpirationDate="mobileVerification.expiration"
      :success="mobileVerification.success"
      :attempts="mobileVerification.failedAttempts"
      :hasExceededMaxCodeAttempts="mobileVerification.hasExceededMaxCodeAttempts"
      :retryCodeTimeout="mobileVerification.retryCodeTimeout"
      @close="close"
      @submitCode="submitMobileVerificationCode"
      @resendCode="getMobileVerificationCode"
    />
  </div>
</template>

<style scoped>
.login-content {
  min-height: 380px;
}

fieldset + fieldset {
  margin: 0;
}
</style>

<script>
import get from 'lodash/get'
import pick from 'lodash/pick'
import { secondsToMinutes } from 'date-fns/secondsToMinutes'
import { trans, transChoice } from '@/munio/i18n/index.js'
import Captcha from '@component/Captcha.vue'
import Company from '@component/CompanySearch/Company.vue'
import MobileConfirmModal from '@component/MobileVerification/ConfirmModal.vue'
import MdlPhonenumber from '@component/mdl/Phonenumber.vue'
import CompanySearch from '@component/CompanySearch/index.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlTextfield from '@component/mdl/Textfield.vue'
import MdlStepper from '@component/mdl/Stepper.vue'
import MdlProgressbar from '@component/mdl/ProgressBar.vue'

export default {
  name: 'UserProfileConfirm',

  components: {
    CompanySearch,
    MdlPhonenumber,
    Captcha,
    Company,
    MobileConfirmModal,
    MdlButton,
    MdlTextfield,
    MdlStepper,
    MdlProgressbar,
  },

  props: {
    profile: { type: Object, required: true },
  },

  data() {
    return {
      fields: ['firstname', 'lastname', 'email', 'mobile'],
      edit: 'profile',
      form: {
        firstname: this.profile.firstname,
        lastname: this.profile.lastname,
        email: this.formatEmail(this.profile.email),
        mobile: this.profile.mobile,
        employer: this.profile.company,
        confirm: true,
        captcha: null,
      },
      validated: {},
      logo: Munio.config.account.logo.header,
      isLoading: true,
      isConfirming: false,
      errors: false,
      isConfirmed: false,
      canConfirm: false,
      changeEmployer: !this.profile.company,
      step: 0,
      isMobileNumberValid: true,
      mobileVerification: {
        show: false,
        expiration: '',
        number: this.profile.mobileVerified ? this.profile.mobile : undefined,
        success: undefined,
        hasExceededMaxCodeAttempts: false,
        failedAttempts: 0,
        retryCodeTimeout: 0,
      },
    }
  },

  computed: {
    body() {
      return document.getElementsByTagName('body')[0]
    },

    background() {
      return document.getElementById('background')
    },

    transition() {
      return this.step ? 'slide-next' : 'slide-back'
    },

    country() {
      return Munio.config.account?.company.country || Munio.config.i18n.country
    },

    hasSelectedNewEmployer() {
      if (this.changeEmployer) {
        return this.changeEmployer.id !== get(this.employer, 'id')
      }

      return false
    },

    steps() {
      return [
        {
          id: 'profile',
          label: trans('User profile'),
          done: this.step > 0,
        },
        {
          id: 'employer',
          label: trans('Employer'),
          done: !!this.form.employer,
          icon: this.form.employer ? undefined : 'priority_high',
        },
      ]
    },

    captchaValid() {
      return !!this.form.captcha || Munio.config.captcha.valid
    },
  },

  methods: {
    get,
    trans,

    formatEmail(value) {
      return (value || '').toLocaleLowerCase()
    },

    label(field) {
      return field === 'email' ? trans('E-mail') : trans(field.charAt(0).toLocaleUpperCase() + field.substr(1))
    },

    classes(field) {
      return {
        'is-modified': this.form[field] !== this.profile[field],
        'flex-grow': ['firstname', 'lastname'].includes(field),
      }
    },

    error(field) {
      if (this.errors && this.errors[field]) {
        const error = this.errors[field]
        return Array.isArray(error) ? error[0] : error
      }
    },

    clearError(field) {
      if (!this.errors) {
        return
      }

      const { [field]: value, ...errors } = this.errors
      this.errors = Object.keys(errors).length > 0 ? errors : false
    },

    async validate(field) {
      if (this.isConfirmed) return

      if (field) {
        this.clearError(field)
        const oldValue = this.validated[field] ?? this.profile[field]
        let newValue = this.form[field]

        if (field === 'email') {
          newValue = this.formatEmail(newValue)
        }

        this.form[field] = newValue

        // nothing changed
        if (newValue === oldValue) {
          return
        }
      }

      try {
        const profile = pick(this.form, ['firstname', 'lastname', 'email', 'mobile'])
        this.errors = false
        this.isLoading = true
        await Munio.api.currentUser.validate(profile)
        this.canConfirm = true

        if (this.form.mobile && this.form.mobile !== this.mobileVerification.number) {
          this.mobileVerification.number = undefined
          this.canConfirm = false
        }
      } catch ({ response }) {
        this.isConfirmed = this.canConfirm = false
        this.errors = get(response, 'data.error.message', false)
      } finally {
        this.isLoading = false
      }
    },

    back() {
      this.step--
      this.$nextTick(() => {
        if (!this.form.employer) {
          this.form.employer = this.profile.employer
        }

        this.changeEmployer = !this.form.employer
      })
    },

    async next(field) {
      await this.validate(field instanceof Event ? undefined : field)

      if (this.errors) {
        return
      }

      if (!this.mobileVerification.number && this.form.mobile) {
        await this.getMobileVerificationCode()
        return
      }

      this.step++
    },

    async cancel() {
      if (await Munio.confirm(undefined, undefined, { confirmButtonText: trans('Log out') })) {
        window.location.href = Munio.route('lms.logout')
      }
    },

    async confirm() {
      try {
        this.isConfirming = this.isLoading = true
        const { data: response } = await Munio.api.currentUser.update(this.form)
        this.isConfirmed = true
        this.$nextTick(() => {
          window.location.href = response.data.url
        })
      } catch ({ response }) {
        this.step = 0
        this.errors = get(response, 'data.error.message', false)
        this.isLoading = false
      } finally {
        this.isConfirming = false
      }
    },

    async close() {
      this.mobileVerification.show = false

      if (this.mobileVerification.success) {
        this.next()
      }
    },

    async getMobileVerificationCode() {
      try {
        let response = await window.Munio.api.mobile(this.form.mobile).submit(true)

        this.mobileVerification.show = true
        this.mobileVerification.expiration = new Date(response.data.data.verification.expiresAt).toString()
        this.mobileVerification.success = undefined
        this.mobileVerification.hasExceededMaxCodeAttempts = false
      } catch (err) {
        if (err.response.status === 429) {
          const minutes = secondsToMinutes(err.response.headers['retry-after'])
          this.mobileVerification.hasExceededMaxCodeAttempts = true

          Munio.Flash.error(
            trans('You have exceeded the maximum number of attempts!') +
              transChoice('Please try again in :num minutes', minutes),
          )
        }

        console.error(err)
      }
    },

    async submitMobileVerificationCode(code) {
      try {
        this.mobileVerification.success = undefined
        await window.Munio.api.mobile(this.form.mobile).verify(code)
        this.mobileVerification.number = this.form.mobile
        this.mobileVerification.success = true
        this.mobileVerification.hasExceededMaxCodeAttempts = false
      } catch (err) {
        if (err.response.status === 429) {
          this.mobileVerification.hasExceededMaxCodeAttempts = true
          this.mobileVerification.retryCodeTimeout = secondsToMinutes(err.response.headers['retry-after'])
        }

        this.mobileVerification.failedAttempts++
        this.mobileVerification.success = false
        this.mobileVerification.number = undefined

        console.error(err)
      }
    },
  },

  watch: {
    isLoading(value) {
      if (value) {
        this.body.classList.add('is-loading')
      } else {
        this.body.classList.remove('is-loading')
      }
    },
  },

  async mounted() {
    if (Munio.config.account.background) {
      this.background.style.backgroundImage = `url(${Munio.config.account.background})`
    }

    this.validate()
  },
}
</script>
