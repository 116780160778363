<template>
  <Node :node="node" />
</template>

<script>
import Node from '@component/UserCourse/Node.vue'

export default {
  components: {
    Node,
  },

  props: {
    prerequisite: { type: Object, required: true },
  },

  computed: {
    status() {
      return 'incomplete'
    },

    progress() {
      return this.prerequisite.progress
    },

    title() {
      let str = this.prerequisite.course.title.default

      if (this.prerequisite.course.identifier) {
        str += ` <small class="opaque">${this.prerequisite.course.identifier}</small>`
      }

      return str
    },

    node() {
      return {
        icon: 'play_arrow',
        object: { title: `<div>${this.title}</div>` },
        progress: this.progress,
        status: this.status,
      }
    },
  },
}
</script>
