import $ from 'jquery'

Munio.behaviors.tooltips = {
  selector: '[data-toggle="tooltip"], [rel="tooltip"]',
  attach: function (context) {
    $(this.selector, context).tooltip({
      container: 'body',
    })
  },
}

$.fn.tooltip.Constructor.DEFAULTS.delay = { show: 500, hide: 75 }
