export class MunioStorage {
  constructor(engine = localStorage) {
    this.engine = engine
    this.boot()
  }

  get session() {
    return new MunioStorage(sessionStorage)
  }

  boot() {
    if (this.get('version') !== Munio.version) {
      this.set('version', Munio.version)
    }
  }

  isEmpty() {
    return this.engine.length === 0
  }

  clear() {
    this.engine.clear()
  }

  remove(key) {
    this.engine.removeItem(key)
  }

  set(key, value) {
    this.engine.setItem(key, encode(value))
  }

  get(key, fallback = null) {
    const value = this.engine.getItem(key)

    return (value ? decode(value) : null) ?? fallback
  }

  getWithDefault(key, defaults = {}) {
    return Object.assign({}, defaults, this.get(key))
  }

  async remember(key, callback) {
    let value = this.get(key)

    if (value === null) {
      this.set(key, (value = await callback()))
    }

    return value
  }
}

export function encode(value) {
  if (Object.prototype.toString.call(value) === '[object Date]') {
    return '__m_date|' + value.toUTCString()
  }
  if (Object.prototype.toString.call(value) === '[object RegExp]') {
    return '__m_expr|' + value.source
  }
  if (typeof value === 'number') {
    return '__m_numb|' + value
  }
  if (typeof value === 'boolean') {
    return '__m_bool|' + (value ? '1' : '0')
  }
  if (typeof value === 'string') {
    return '__m_strn|' + value
  }
  if (typeof value === 'function') {
    return '__m_strn|' + value.toString()
  }
  if (value === Object(value)) {
    return '__m_objt|' + JSON.stringify(value)
  }

  return value
}

export function decode(value) {
  const length = value.length
  if (length < 9) {
    // it wasn't encoded by us
    return value
  }

  const type = value.substr(0, 8)
  const source = value.substring(9)

  switch (type) {
    case '__m_date':
      return new Date(source)

    case '__m_expr':
      return new RegExp(source)

    case '__m_numb':
      return Number(source)

    case '__m_bool':
      return Boolean(source === '1')

    case '__m_strn':
      return '' + source

    case '__m_objt':
      return JSON.parse(source)

    default:
      if (['{', '['].includes(value.substr(0, 1))) {
        return JSON.parse(value)
      }

      // hmm, we reached here, we don't know the type,
      // then it means it wasn't encoded by us, so just
      // return whatever value it is
      return value
  }
}

Munio.Storage = new MunioStorage()
