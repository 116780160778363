<template>
  <div>
    {{ trans('You have been invited by :sender', { sender: invite.sender.fullname }) }}

    <slot />
  </div>
</template>

<script>
import { trans } from '@/munio/i18n'

export default {
  props: {
    invite: { type: Object, required: true },
  },

  methods: {
    trans,
  },
}
</script>
