<template>
  <div class="flex flex-col">
    <template v-if="!fetching && companies.length">
      <h3>
        {{ trans('Your locations') }}
        <span class="opaque">({{ companies.length }})</span>
      </h3>
    </template>

    <div class="mt-8">
      <MdlProgressbar v-if="fetching" indeterminate />
      <div class="flex flex-wrap gap-8">
        <template v-for="owner of companies" :key="owner.id">
          <CompanyCard link :company="owner.company" :state="owner.state" style="max-width: 250px" />
        </template>
      </div>
    </div>

    <div class="my-12" v-if="companies.length"></div>

    <template v-if="!fetching">
      <div class="mb-8">
        <div class="flex justify-between items-center">
          <div>
            <h3 v-if="companies.length">{{ trans('Need to go someplace else?') }}</h3>
            <h3 v-else>
              {{ trans('Available locations') }}
              <span class="opaque">({{ availableCompanies.length }})</span>
            </h3>
          </div>
          <div class="form-inline">
            <input
              :placeholder="trans('Search') + '…'"
              autofocus
              class="1"
              name="search"
              type="text"
              v-model="query"
              @keydown="search"
            />
            <button type="submit" class="btn btn-icon btn-primary"><i class="fa fa-search"></i></button>
          </div>
        </div>
        <div class="mt-8" v-if="!query && !browsing && !availableCompanies.length">
          <MdlButton raised primary @click="showAllLocations">
            {{ trans('View all locations') }}
          </MdlButton>
        </div>
      </div>

      <div>
        <MdlProgressbar v-if="browsing" indeterminate />
        <div v-if="!browsing && availableCompanies.length" class="flex flex-wrap gap-8">
          <Card
            v-for="item in availableCompanies"
            :key="item.company.id"
            alignment="center"
            :thumbnail="item.company.logoUrl"
            :thumbnail-cover="false"
            thumbnail-show
            :initials="item.company.initials"
            :title="item.company.name"
            :label="item.company.isDemo && can('munio') ? trans('Demo') : undefined"
            @click="selectedCompany = item.company"
            style="max-width: 250px"
          >
            <MdlProgressbar v-if="item.status" :progress="item.state.progress" :status="true" />
          </Card>
        </div>
        <div
          v-if="!availableCompanies.length && !browsing && query"
          class="empty-state flex-col"
          style="background-image: url(/img/empty-state/zones.svg)"
        >
          <h3>{{ trans('No matches found') }}</h3>
          <MdlButton raised primary @click="showAllLocations">
            {{ trans('View all locations') }}
          </MdlButton>
        </div>
      </div>
    </template>

    <AccessZoneModal :company="selectedCompany" @hidden="selectedCompany = null" />
  </div>
</template>

<script>
import AccessZoneModal from './AccessZoneModal.vue'
import CompanyCard from '../AccessZoneCompany/CompanyCard.vue'
import Card from '@component/Card.vue'
import MdlProgressbar from '@component/mdl/ProgressBar.vue'
import MdlButton from '@component/mdl/Button.vue'

import { trans } from '@/munio/i18n/index.js'

export default {
  components: {
    Card,
    CompanyCard,
    AccessZoneModal,
    MdlProgressbar,
    MdlButton,
  },

  data() {
    return {
      companies: [],
      availableCompanies: [],
      selectedCompany: null,
      query: null,
      fetching: false,
      browsing: false,
    }
  },

  methods: {
    trans,
    can: window.can,

    async fetch() {
      this.fetching = true
      const {
        data: { data },
      } = await Munio.api.currentUser.access.get()
      this.companies = data

      if (!this.companies.length) {
        await this.browse()
      }

      this.fetching = false
    },

    search: window.debounce(function () {
      this.browse()
    }, 300),

    isEmployee(company) {
      return Munio.config.user.company?.id === company.id
    },

    async browse() {
      this.browsing = true

      let {
        data: { data },
      } = await Munio.api.currentUser.access.browseCompanies(this.query)

      let companyIds = this.companies.map((c) => c.company.id)

      this.availableCompanies = data
        .filter((c) => !companyIds.includes(c.id))
        .filter((c) => {
          if (c.isDemo) {
            if (this.isEmployee(c) || window.can('munio')) {
              return true
            }
            return false
          }
          return true
        })
        .map((c) => ({ company: c, state: null }))

      this.browsing = false
    },

    showAllLocations() {
      this.query = null
      this.browse()
    },
  },

  created() {
    this.fetch()
  },
}
</script>
