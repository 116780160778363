<template>
  <div :class="colorClass">
    <Icon small name="schedule" />
    {{ minutesLeft + ':' + secondsLeft }}
  </div>
</template>

<script setup lang="ts">
import { onUnmounted, ref, watch } from 'vue'

import Icon from '@component/Icon.vue'

const props = withDefaults(
  defineProps<{
    codeExpirationDate: string
    colorClass?: string
  }>(),
  {
    colorClass: 'mdl-color-text--grey-600',
  },
)

watch(
  () => props.codeExpirationDate,
  () => {
    setToZero()
    startTimer()
  },
)

const emit = defineEmits<{
  (e: 'started'): void
  (e: 'expired'): void
  (e: 'stopped'): void
}>()

const minutesLeft = ref('')
const secondsLeft = ref('')
let timer: any = null

function startTimer() {
  emit('started')

  timer = setInterval(() => {
    let timeLeft = new Date(props.codeExpirationDate).getTime() - new Date().getTime()

    if (timeLeft > 0) {
      minutesLeft.value = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString()
      secondsLeft.value = Math.floor((timeLeft % (1000 * 60)) / 1000).toString()

      if (secondsLeft.value.length === 1) {
        secondsLeft.value = '0' + secondsLeft.value
      }
    } else {
      clearInterval(timer)
      emit('expired')
    }
  })
}

function stopTimer() {
  clearInterval(timer)
  emit('stopped')
}

function setToZero() {
  minutesLeft.value = '0'
  secondsLeft.value = '00'
  clearInterval(timer)
  emit('stopped')
}

onUnmounted(() => {
  clearInterval(timer)
})

defineExpose({
  startTimer,
  stopTimer,
  setToZero,
})
</script>
