import { createApp, h, reactive } from 'vue'
import Wrapper from './Wrapper.vue'
import { createPinia } from 'pinia'

class CourseLauncher {
  instance = null
  state = reactive({
    course: null,
    requirementId: null,
    selfEnroll: null,
  })

  mount() {
    if (this.instance) {
      return
    }

    this.instance = createApp({
      name: 'CourseLauncher',
      data: () => this.state,
      render: (context) =>
        h(Wrapper, {
          course: context.course,
          selfEnroll: context.selfEnroll,
          requirementId: context.requirementId,
          onClose: () => {
            this.state.course = null
            this.state.requirementId = null
            this.state.selfEnroll = null
          },
        }),
    })

    this.instance.use(createPinia())
    this.instance.mount('#modal-container')
  }

  open(course, selfEnroll = false, requirementId = null) {
    this.mount()

    // preload image
    let img = new Image()
    img.src = course.image

    this.state.course = course
    this.state.requirementId = requirementId
    this.state.selfEnroll = selfEnroll
  }
}

Munio.CourseLauncher = new CourseLauncher()
