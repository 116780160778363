import Wrapper from '@app/Wrapper.js'

Munio.SessionRequests = class extends Wrapper {
  constructor(selector, companyCourseId, requests) {
    super(selector, { initialRequests: requests, companyCourseId })
  }

  component() {
    return import('./App.vue')
  }
}
