import Wrapper from '@app/Wrapper.js'

Munio.ThemeColorCustomizer = class extends Wrapper {
  constructor(selector, data = {}) {
    const $el = document.querySelector(selector)

    super(selector, {
      id: $el.id,
      name: $el.name,
      modelValue: $el.value,
      ...data,
    })
  }

  get replace() {
    return true
  }

  get name() {
    return 'ThemeColorCustomizer(' + this.data.propName + ')'
  }

  component() {
    return import('@component/ColorPicker.vue')
  }
}
