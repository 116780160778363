import { App, inject, provide } from 'vue'

export default {
  install: (app: App) => {
    app.mixin({
      beforeCreate: function () {
        const options = this.$options
        // store injection
        if (options.store) {
          this.$store = typeof options.store === 'function' ? options.store() : options.store
          provide('$store', this.$store)
        } else {
          const store = inject('$store', false)

          if (store) {
            this.$store = store
          }
        }
      },
    })
  },
}

export function useStore<Store>(): Store | false {
  return inject<Store | false>('$store', false)
}
