import { trans } from '@/munio/i18n/index.js'
import { omit } from 'lodash'

export default {
  async save({ state: { form, order }, commit }) {
    try {
      commit('processing', true)
      const input = omit(form, 'linesAdded')
      const { data } = await Munio.api.salesorder(order.data.id).update(input)
      commit('init', data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('processing', false)
    }
  },

  async remove({ state: { order, parentUrl }, commit }) {
    if (!(await Munio.confirm())) {
      return
    }

    try {
      commit('processing', 'delete')
      await Munio.api.salesorder(order.data.id).remove()

      const redirectUrl = order.data.predecessorId
        ? Munio.route(Munio.route().current(), { order: order.data.predecessorId })
        : parentUrl

      window.location.href = redirectUrl
    } catch (err) {
      console.error(err)
    }
  },

  async duplicate({ state: { order }, commit }, replace = false) {
    try {
      commit('processing', 'duplicate')
      const { data } = await Munio.api.salesorder(order.data.id).duplicate(replace)
      window.location.href = Munio.route(Munio.route().current(), { order: data.data.id })
    } catch (err) {
      console.error(err)
    }
  },

  async bill({ state: { order }, commit }) {
    if (!(await Munio.confirm(undefined, trans('The order will be sent to accounting for billing.')))) {
      return
    }

    try {
      commit('processing', 'bill')
      const { data } = await Munio.api.salesorder(order.data.id).bill()
      commit('init', data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('processing', false)
    }
  },

  async updateLedger({ state: { order }, commit }) {
    try {
      commit('processing', 'ledger')
      const { data } = await Munio.api.salesorder(order.data.id).ledger()
      commit('init', data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('processing', false)
    }
  },

  async approve({ state: { order }, commit }) {
    if (!(await Munio.confirm(undefined, trans('The order will be delivered and cannot be modified afterwards')))) {
      return
    }

    try {
      commit('processing', true)
      const { data } = await Munio.api.salesorder(order.data.id).approve()
      commit('init', data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('processing', false)
    }
  },

  async updateCompany({ state, commit }, { company }) {
    const {
      data: { data: customer },
    } = await Munio.api.shop(state.order.data.shop.slug).customer(company.id).get()

    if (company.id === state.order.data.customer.company.id) {
      commit('replaceCustomer', { customer })
    }

    if (company.id === state.order.data.payment.customer.company.id) {
      commit('replaceCustomer', { customer, payment: true })
    }
  },

  async setCompany({ state, commit }, { company, payment = false, replace = false }) {
    const customer = {
      company,
      membership: {
        active: false,
      },
    }

    if (company.id) {
      try {
        const {
          data: { data },
        } = await Munio.api.shop(state.order.data.shop.slug).customer(company.id).get()
        Object.assign(customer, data)
      } catch (err) {
        if (err.response?.status !== 404) {
          throw err
        }
      }
    }

    commit('setCustomer', { customer, payment, replace })
  },

  setDiscount({ getters: { lines }, dispatch }, discount) {
    for (const line of lines) {
      dispatch('setLineDiscount', { uid: line.uid, discount })
    }
  },

  setLineDiscount({ commit, getters: { getLine, amount } }, { uid, discount }) {
    const line = getLine(uid)

    if (!discount) {
      discount = 0
    }

    const price = amount(line.price.full)
      .percentage(100 - discount)
      .toUnit()

    commit('setLineInput', { uid: line.uid, input: { discount, price } })
  },

  async showSalespeopleModal({ state, commit }) {
    commit('setModalShow', ['salespeople', true])
    if (!state.salespeople.data.length) {
      commit('setModalLoading', ['salespeople', true])

      try {
        const {
          data: { data: salespeople },
        } = await Munio.api.shop(state.order.data.shop.slug).salespeople()
        commit('setModalData', ['salespeople', salespeople])
      } catch (err) {
        console.error(err)
      } finally {
        commit('setModalLoading', ['salespeople', false])
      }
    }
  },

  async showProductModal({ state, commit }) {
    commit('setModalShow', ['products', true])
    if (!state.products.data.length) {
      commit('setModalLoading', ['products', true])

      try {
        const {
          data: { data: products },
        } = await Munio.api.shop(state.order.data.shop.slug).products()
        commit('setModalData', ['products', products])
      } catch (err) {
        console.error(err)
      } finally {
        commit('setModalLoading', ['products', false])
      }
    }
  },
}
