import Wrapper from '@app/Wrapper.js'

Munio.AccessSettings = class extends Wrapper {
  constructor(selector, gateId) {
    super(selector, { gateId })
  }

  component() {
    return import('./App.vue')
  }
}
