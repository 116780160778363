<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { trans } from '@/munio/i18n'
import { parseDate, format } from '@/munio/utils/date'
import MSelect from '@component/Select.vue'

withDefaults(
  defineProps<{
    name?: string
    readonly?: boolean
    defaultValue?: string | Date
  }>(),
  {
    readonly: false,
  },
)

const model = defineModel<string | Date>({ default: (props) => props.defaultValue })

const input = reactive({
  year: null as number | null,
  month: null as number | null,
  day: null as number | null,
})
const inputDate = computed(() => {
  if (input.year === null || input.month === null || input.day === null) {
    return null
  }

  return new Date(input.year, input.month - 1, input.day)
})
const inputIso = computed({
  get: () => {
    return inputDate.value ? format(inputDate.value, 'yyyy-MM-dd') : null
  },
  set: (value: string | Date) => {
    const date = parseDate(value)

    if (date) {
      input.year = date.getFullYear()
      input.month = date.getMonth() + 1
      input.day = date.getDate()
    }
  },
})

const forManyManyYears = new Date().getFullYear() - 1900 + 1
const years = Array.from({ length: forManyManyYears }, (_, i) => new Date().getFullYear() - i).map((year) => ({
  id: year,
  label: year,
}))

const months = Array.from({ length: 12 }, (_, i) => i).map((month) => ({
  id: month + 1,
  label: format(new Date(0, month), 'MMM'),
}))

const days = Array.from({ length: 31 }, (_, i) => i + 1).map((day) => ({
  id: day,
  label: day,
}))

watch(inputIso, (value) => {
  model.value = value || undefined
})

watch(
  model,
  (value, oldValue) => {
    if (value && value !== oldValue) {
      inputIso.value = value
    }
  },
  { immediate: true },
)
</script>

<template>
  <div class="grid grid-cols-[80px_90px_90px_auto] gap-x-2 items-center">
    <input v-if="name" type="hidden" :name="name" :value="inputIso" />
    <MSelect v-model="input.day" :options="days" :disabled="readonly" :placeholder="trans('Day')" />
    <MSelect v-model="input.month" :options="months" :disabled="readonly" :placeholder="trans('Month')" />
    <MSelect v-model="input.year" :options="years" :disabled="readonly" :placeholder="trans('Year')" />
  </div>
</template>
