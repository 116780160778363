<template>
  <div v-if="document" class="flex flex-col w-full rounded-lg gap-y-2">
    <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center gap-x-4">
        <Icon v-if="document.icon" :name="document.icon" />
        <span class="t_label_attachmentTitle break-words">
          {{ document.file.name }}
        </span>

        <span class="opaque">{{ bytesForHumans(document.file.size) }}</span>
      </div>
      <div class="flex items-center">
        <MdlButton icon="download" @click="openInNewTab(document.url.download)" v-tooltip="trans('Download')" />
        <MdlButton icon="open_in_new" @click="openInNewTab(document.url.view)" v-tooltip="trans('Open in new tab')" />
        <slot name="actions" />
      </div>
    </div>
    <div class="flex aspect-square">
      <iframe title="Preview" :src="document.url.view" class="border-none w-full h-full bg-transparent" />
    </div>
  </div>
  <div v-else class="aspect-square w-full flex items-center justify-center border-2 border-gray-300 border-dashed">
    <div id="wrap">
      {{ trans('Document not provided') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { trans } from '@/munio/i18n/index.js'
import MdlButton from '@component/mdl/Button.vue'
import Icon from '@component/Icon.vue'
import { bytesForHumans } from '@/munio/utils/index.js'
import tooltip from '@directive/tooltip'

type Document = {
  icon: string
  slug: string
  url: {
    view: string
    download: string
  }
  file: {
    name: string
    size: number
    exists: boolean
  }
}

const props = defineProps<{
  document?: Document
}>()

const vTooltip = tooltip

function openInNewTab(url: string) {
  window.open(url, '_blank')
}
</script>
