import { createApp } from 'vue'
import { pendoLabel, userFullname } from '@/munio/utils'
import { trans, transChoice } from '@/munio/i18n'

// Apps
import './AccessLink'
import './AccessLink/Manager'
import './AccessSettings'
import './AccessZoneOverview'
import './AccessZoneCompany'
import './AdminDashboard'
import './ApiManager'
import './Approvals'
import './AuditViewer'
import './Auth'
import './CompanyDepartments'
import './CompanyIndex'
import './ComponentViewer'
import './CompanyManager'
import './CompanyManager/CompanyAccess'
import './CompanyManager/CompanyAccount'
import './CompanyManager/CompanyCourses'
import './CompanyManager/CompanyDetails'
import './CourseBuilder'
import './CourseEnrollment'
import './CourseLauncher'
import './CourseManager'
import './CoursePlayer'
import './FormFilters'
import './GateCardManager'
import './GateCompanyManager'
import './GateEntrypointManager'
import './IntegrationActionsInspector'
import './InviteEditor'
import './IntegrationNotification'
import './LocationManager'
import './MunioDashboard'
import './Notifications'
import './OrderViewer'
import './ProfileSettings'
import './RoleManager'
import './SessionRequests'
import './Shop'
import './ShopCart'
import './ShopCheckout'
import './ShopMembers'
import './ShopProductManager'
import './ShopSuppliers'
import './ThemeCustomizer'
import './UserCourses'
import './UserIndex'
import './UserManager'
import './UserMergeManager'
import './UserProfileConfirm'
import './UserRelationshipManager'
import './UserSettings'
import './ZoneIndex'
import './ZoneStatus'
import './ZoneRequisitions'

// Directives
import Autofocus from '@directive/autofocus.js'
import Tooltip from '@directive/tooltip.js'
import { clickOutside, focusOutside } from '@directive/eventOutside.js'

// Plugins
import VuexPlugin from './VuexPlugin'

export function createMunioApp(options = {}) {
  const app = createApp(options)

  app.directive('autofocus', Autofocus)
  app.directive('tooltip', Tooltip)
  app.directive('click-outside', clickOutside)
  app.directive('focus-outside', focusOutside)

  app.mixin({
    can: window.can,
    implode: window.implode,
    route: Munio.route,
    trans: trans,
    trans_choice: transChoice,
    flag: Munio.flag,
    pendo: pendoLabel,
    fullname: userFullname,
  })

  app.use(VuexPlugin)

  return app
}

Munio.Vue = function (target, options = {}) {
  options.data = () =>
    Object.assign(
      {},
      {
        config: Munio.config,
        debug: Munio.config.debug,
        user: Munio.config.user,
        company: Munio.config.company,
        account: Munio.config.account,
        ...Munio.state,
      },
      options.data,
    )

  const fragment = document.createDocumentFragment()
  const app = createMunioApp(options).mount(fragment)

  target.parentNode.replaceChild(fragment, target)

  return app
}
