<template>
  <Card
    layout="horizontal"
    :thumbnail="item.object.image.medium"
    :title="item.object.title"
    :title-suffix="item.object.identifier"
    class="mb-8 is-clickable"
    :class="{
      course__card: true,
      'course__card--completed': isValid,
      'course__card--expiring': isExpiring,
      'course__card--disabled': disabled,
    }"
    @click="$emit('launch', item.object.id)"
  >
    <div class="course__card-description opaque">{{ item.object.description }}</div>
    <template #thumbnail>
      <i class="material-icons" style="transform: translateX(2px)">{{ icon }}</i>
    </template>
    <template #footer>
      <div>
        <div v-if="hasIltSessionInfo" class="flex items-center mdl-color-text--primary">
          <span>{{ trans('You are enrolled') }}:</span>
          <strong class="ml-4" v-html="item.session.from.datetime"></strong>
        </div>
        <div
          v-else-if="notice"
          :class="{
            flex: true,
            'items-center': true,
            'mdl-color-text--info': isExempted,
            'mdl-color-text--warning-dark': isExpiring,
          }"
        >
          <i v-if="noticeIcon" class="material-icons small mr-4">{{ noticeIcon }}</i>
          <span>{{ notice + (prerequisites.length > 0 ? ':' : '') }}</span>
        </div>

        <div v-if="prerequisites.length > 0" class="mdl-chips mt-2">
          <MdlChip
            v-for="prereq of prerequisites"
            :key="prereq.course.id"
            :image="prereq.course.image.avatar"
            @click.stop="$emit('launch', prereq.course.id)"
          >
            {{ prereq.course.title.default }}
          </MdlChip>
        </div>

        <MdlProgressbar v-if="hasProgress" :progress="item.state.progress" :status="true" />
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@component/Card.vue'
import MdlChip from '@component/mdl/Chip.vue'
import MdlProgressbar from '@component/mdl/ProgressBar.vue'
import { trans } from '@/munio/i18n/index.js'

export default {
  components: {
    Card,
    MdlChip,
    MdlProgressbar,
  },

  props: {
    item: Object,
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    icon() {
      if (this.isExpiring) {
        return 'notification_important'
      }

      if (this.isValid) {
        return 'check'
      }

      if (this.item.requirePurchase) {
        // return 'attach_money'
      }

      if (this.item.unplayableReason && !this.item.requirePurchase) {
        return 'lock'
      }

      return 'play_arrow'
    },

    isValid() {
      return this.item.state.isValid
    },

    isExempted() {
      return this.item.state.expiration?.isExempted
    },

    isExpiring() {
      return this.item.state.expiration?.isExpiring
    },

    isIlt() {
      return this.item.object.isIlt
    },

    hasProgress() {
      if (!this.item.object.isProgressive) {
        return false
      }

      if (this.isValid) {
        return false
      }

      return this.item.isResumable && this.item.state.progress
    },

    hasIltSessionInfo() {
      if (!this.isIlt || this.isValid) {
        return false
      }

      return !!this.item.session
    },

    notice() {
      const ignoreWhenUnplayable = this.item.requirePurchase || this.isExempted

      if (this.item.unplayableReason && !ignoreWhenUnplayable) {
        return this.item.unplayableReasonText
      }

      if (this.item.state.expiration?.date) {
        return this.item.state.expiration?.validText
      }

      if (this.isValid) {
        return null
      }

      return this.item.notice
    },

    noticeIcon() {
      if (this.item.state.expiration?.date && !this.isExpiring) {
        return null
      }

      return this.item.noticeIcon
    },

    prerequisites() {
      const arr = this.item.prerequisites ?? []

      return arr.filter((prereq) => !prereq.isCompleted)
    },
  },

  methods: {
    trans,
  },

  filters: {
    limit(val, limit = 200) {
      if (val.length > limit) return val.substring(0, limit) + '...'
      else return val
    },
  },
}
</script>

<style lang="scss">
@import '@style/variables';

.course__card {
  .card__thumbnail {
    position: relative;

    &:before {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $border-radius-large 0 0 $border-radius-large;
    }
  }

  &--completed:not(.course__card--expiring) {
    .card__thumbnail:before {
      background-color: rgba(mdl-color($color-success), 0.5);
    }
  }

  &--expiring {
    .card__thumbnail:before {
      background-color: rgba(mdl-color($color-warning), 0.5);
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-description {
    overflow: hidden;
    max-height: 41px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.card__thumbnail {
  cursor: pointer;

  * {
    cursor: pointer;
  }

  .material-icons {
    $size: 64px;

    color: rgba(255, 255, 255, 1);
    text-shadow: 0 2px 32px rgba(0, 0, 0, 0.55);
    font-size: $size;
    line-height: $size;
  }
}
</style>
