import Wrapper from '@app/Wrapper.js'

Munio.ProfileSettings = class extends Wrapper {
  constructor(selector, user, departments) {
    super(selector, { user, departments })
  }

  component() {
    return import('./App.vue')
  }
}
