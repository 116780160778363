import Wrapper from '@app/Wrapper.js'
import { defineAsyncComponent } from 'vue'

Munio.ShopCheckout = class extends Wrapper {
  constructor(selector, cart) {
    super(selector, { cart })
  }

  get name() {
    return 'ShopCheckout'
  }

  component() {
    return defineAsyncComponent(() => import('./App.vue'))
  }
}
