<template>
  <div class="course-nodes__node" :class="{ 'is-current': isCurrent }" :data-status="node.status">
    <slot name="before" />

    <div class="course-nodes__node-subway">
      <div v-if="parent" class="subway-track--parent" :class="{ 'is-active': parent.isDone }" />
      <div class="subway-track--in" :class="{ 'is-active': subwayTrackIn }" />
      <div class="subway-track--out" :class="{ 'is-active': subwayTrackOut }" />
      <div class="subway-icon">
        <CircularProgress :size="32" :stroke-width="3" :progress="progress" :angle="subwayAngle" />
        <Icon :name="iconName" />
      </div>
    </div>

    <div class="course-nodes__node-container">
      <div v-if="titleContent" class="course-nodes__node-title" v-html="titleContent" />
      <div v-if="$slots.default" class="course-nodes__node-content">
        <slot />
      </div>
    </div>

    <slot name="after" />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import CircularProgress from '@component/CircularProgress.vue'
import Icon from '@component/Icon.vue'

export default {
  components: {
    CircularProgress,
    Icon,
  },

  name: 'Node',

  props: {
    node: {
      type: Object,
      default: () => ({
        object: {},
        status: 'not-started',
        icon: 'play_arrow',
      }),
    },
    icon: String,
    title: String,
    parent: Object,
    progressAngle: Number,
  },

  computed: {
    isSubcourse() {
      return !isEmpty(this.node.subcourse)
    },

    hasSubnodes() {
      if (!this.isSubcourse) {
        return false
      }

      return this.node.subcourse.nodes.length > 1
    },

    isFirstSubnode() {
      return !isEmpty(this.parent) && !this.node.previous
    },

    isCurrent() {
      if (this.node.isDone) {
        return false
      }

      return this.node.isCurrent && this.node.isResumable
    },

    progress() {
      let progress = this.node.progress

      if (this.isSubcourse && progress > 0 && progress < 50) {
        progress = 50
      }

      return progress
    },

    iconName() {
      return this.node.isDone ? 'check' : this.icon || this.node.icon
    },

    titleContent() {
      return this.title ? this.title : this.node.object.title
    },

    subwayAngle() {
      if (this.progressAngle !== undefined) {
        return this.progressAngle
      }

      const progress = this.progress
      const angleIn = this.isFirstSubnode ? 225 : 270
      const angleOut = 90
      let angle = angleIn
      let degrees = (progress / 100) * 360
      let weight = (angleIn - angleOut) / 360
      let offset = degrees * weight

      if (this.hasSubnodes && progress >= 50) {
        let halfProgress = progress - 50
        offset = (halfProgress / 100) * 360
      }

      return angle - offset
    },

    subwayTrackIn() {
      return this.progress > 0
    },

    subwayTrackOut() {
      if (this.hasSubnodes && this.subwayTrackIn) {
        return true
      }

      return this.progress === 100
    },
  },
}
</script>
