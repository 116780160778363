<template>
  <div
    class="mdl-filters__search"
    :class="{ 'is-active': input }"
    v-click-outside="blurSearch"
    v-focus-outside="blurSearch"
  >
    <input v-if="input" type="hidden" name="search" :value="input" />

    <div v-if="searchEnabled" class="mdl-filters__search-input">
      <MdlButton icon :primary="isDirty" :raised="isDirty || input !== ''" @click="onSearchButtonClick">
        <Icon name="search" />
      </MdlButton>

      <input
        type="text"
        tabindex="0"
        ref="search"
        v-model.trim="input"
        :size="inputSize"
        :placeholder="trans('Search')"
        @focus="focusSearch"
        @keydown.enter.prevent="search"
        @keydown.down.stop="onSearchArrowDown"
      />
    </div>

    <div v-else class="mdl-filters__submit">
      <MdlButton v-if="hasInactiveFilters" icon :disabled="!hasInactiveFilters" @click="onFilterButtonClick">
        <Icon name="filter_list" />
      </MdlButton>
      <MdlButton icon @click="onSearchButtonClick" v-tooltip="trans('Refresh')">
        <Icon name="refresh" />
      </MdlButton>
    </div>

    <div :class="['dropdown', { open: showSearchDropdown }]">
      <BMenu ref="dropdown" @keydown.left="onDropdownNavigateLeft" @keydown.up="onDropdownNavigateUp" @click.stop>
        <BMenuItem v-for="flag in flagsInactive" :key="flag.name" @click.stop="addFlag($event, flag.name)">
          {{ flag.label }}
        </BMenuItem>
        <BMenuItem v-if="showDropdownDivider" divide />
        <BMenuItem v-for="filter in filtersInactive" :key="filter.name" @click="addFilter($event, filter.name)">
          <template v-if="filter.type === 'input' && input">
            {{ filter.label }}
            <span class="opaque">{{ trans(':a contains :b', { a: '', b: '' }) }}</span>
            "{{ input }}"
          </template>
          <template v-else>
            {{ filter.label }}
          </template>
        </BMenuItem>
      </BMenu>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import { focusableNodes } from '@/munio/utils/index.js'
import { trans } from '@/munio/i18n'
import tooltip from '@directive/tooltip'
import { clickOutside, focusOutside } from '@directive/eventOutside'
import Icon from '@component/Icon.vue'
import BMenu from '@component/Menu.vue'
import BMenuItem from '@component/MenuItem.vue'
import MdlButton from '@component/mdl/Button.vue'

export default {
  components: {
    Icon,
    BMenu,
    BMenuItem,
    MdlButton,
  },

  directives: {
    tooltip,
    clickOutside,
    focusOutside,
  },

  computed: {
    ...mapGetters({
      getFilter: 'getFilter',
      filtersActive: 'getFiltersActive',
      filtersInactive: 'getFiltersInactive',
      flagsInactive: 'getFlagsInactive',
    }),

    input: {
      get() {
        return this.$store.state.form.search
      },
      set(value) {
        this.$store.commit('INPUT', value)
      },
    },

    inputSize() {
      return this.input.length < 10 ? 10 : this.input.length
    },

    searchEnabled() {
      return this.$store.state.search.enabled
    },

    searchFocused: {
      get() {
        return this.$store.state.form.searchFocus
      },
      set(value) {
        this.$store.commit('SEARCH_FOCUS', value)
      },
    },

    isDirty: {
      get() {
        return this.input !== this.$store.state.search.input
      },
    },

    isEditing: {
      get() {
        return this.$store.state.form.filter !== null
      },
    },

    hasInactiveFilters() {
      return this.filtersInactive.length > 0 || this.flagsInactive.length > 0
    },

    showFiltersDropdown: {
      get() {
        return this.$store.state.form.filtersShow
      },
      set(value) {
        this.$store.commit('SHOW_FILTERS', value)
      },
    },

    showSearchDropdown() {
      if (this.showFiltersDropdown) {
        return true
      }

      if (this.isEditing || !this.searchFocused) {
        return false
      }

      return this.hasInactiveFilters
    },

    showDropdownDivider() {
      return this.flagsInactive.length > 0
    },
  },

  methods: {
    trans,

    focusSearch() {
      this.searchFocused = true
    },

    blurSearch() {
      this.searchFocused = false
      this.showFiltersDropdown = false
    },

    search() {
      this.blurSearch()
      this.$emit('search')
    },

    onDropdownNavigateLeft(e) {
      if (this.filtersActive.length) {
        if (e) e.stopPropagation()
        $('.mdl-chip[tabindex="0"]:last', this.$parent.$el).trigger('focus')
      }
    },

    onDropdownNavigateUp(e) {
      const firstFocusable = focusableNodes(this.$refs.dropdown.$el)[0]

      if (firstFocusable === e.target) {
        if (e) e.stopPropagation()
        this.$nextTick(() => {
          this.$refs.search.focus()
        })
      }
    },

    onSearchPlaceholderClick(e) {
      this.$refs.search.focus()
    },

    onSearchButtonClick(e) {
      if (e) e.stopPropagation()

      if (this.searchEnabled && !this.input && !this.searchFocused) {
        this.$refs.search.focus()
      } else {
        this.search()
      }
    },

    onFilterButtonClick(e) {
      if (e) e.stopPropagation()

      this.showFiltersDropdown = true
    },

    onSearchArrowDown(event) {
      const nodes = focusableNodes(this.$refs.dropdown.$el)

      if (nodes.length) {
        event.preventDefault()
        nodes[0].focus()
      }
    },

    addFlag(event, name) {
      this.blurSearch()
      this.$store.commit('ADD_FILTER', { name, value: true })
      this.search()
    },

    addFilter(event, name) {
      event.preventDefault()
      event.stopPropagation()

      let value
      let clearSearch = false

      switch (this.getFilter(name).type) {
        case 'input':
          value = this.input
          clearSearch = true
          break
        case 'bool':
          value = true
          clearSearch = true
          break
      }

      this.blurSearch()
      this.$store.commit('ADD_FILTER', { name, value, clearSearch })

      if (value) {
        this.search()
      }
    },
  },
}
</script>
