import Wrapper from '@app/Wrapper.js'

Munio.Shop = class extends Wrapper {
  constructor(selector, shop, isMembershipCustomer, products, productId, detectedRegion, selectedRegion) {
    super(selector, {
      shop,
      isMembershipCustomer: isMembershipCustomer || false,
      products,
      productId,
      detectedRegion,
      selectedRegion,
    })
  }

  component() {
    return import('./App.vue')
  }
}
