import Wrapper from '@app/Wrapper.js'
import App from './App.vue'

Munio.AccessLink = class extends Wrapper {
  constructor(selector, link) {
    super(selector, { link })
  }

  component() {
    return App
  }
}
