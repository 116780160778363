<template>
  <div class="user-search-results">
    <div class="user-search-results__users mx-12">
      <MdlProgressbar v-if="loading" :indeterminate="true" />

      <template v-if="results">
        <template v-if="filteredResult.length">
          <transition-group name="user-search-results">
            <MdlListItem
              v-for="user in filteredResult"
              :key="user.id"
              :class="{ 'user-search-results__user': true, opaque: user.excludeReason }"
              :title="user.fullname || user.email"
              :subtitle="user.company ? user.company.name : null"
              :thumbnail="user.avatar && user.avatar.url ? user.avatar.url : user.thumbnail"
            >
              <template v-if="user.excludeReason" #actions>
                <MdlButton :icon="user.excludeIcon || 'info'" :title="user.excludeReason" />
              </template>
              <template v-else-if="!root.isHardLimitReached" #actions>
                <MdlButton
                  icon="keyboard_arrow_right"
                  :disabled="root.isHardLimitReached"
                  @click="$emit('select', user)"
                />
              </template>
            </MdlListItem>
          </transition-group>
          <!-- Show how many users not shown in list -->
          <p v-if="excludedResults > 0" class="py-8 pl-8">
            {{ trans('+ :n more', { n: excludedResults }) }}
          </p>
        </template>
        <div class="py-12" v-else v-html="root.emptyMessage"></div>
      </template>
    </div>
  </div>
</template>

<script>
import { trans } from '@/munio/i18n/index.js'
import MdlButton from '@component/mdl/Button.vue'
import MdlListItem from '@component/mdl/ListItem.vue'
import MdlProgressbar from '@component/mdl/ProgressBar.vue'

const limit = 25

export default {
  inject: ['root'],

  props: {
    loading: { type: Boolean, default: false },
    results: { type: [Array, Boolean], required: true },
  },

  components: {
    MdlButton,
    MdlListItem,
    MdlProgressbar,
  },

  computed: {
    filteredResult() {
      return this.results
        .filter((user) => {
          return !this.root.added.find(({ id }) => id === user.id)
        })
        .slice(0, limit)
    },
    excludedResults() {
      return this.results.length - limit
    },
  },

  methods: {
    trans,
  },
}
</script>

<style lang="scss" scoped>
@import '@style/variables';

.user-search-results {
  position: relative;
  overflow: auto;

  &__users {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
  }
}

.user-search-results__user {
  transition: all $trans-slow-out;
}

.user-search-results-move {
  transition: all $trans-slow-inout;
}

.user-search-results-enter,
.user-search-results-leave-to {
  opacity: 0;
  transform: translateX(30%);
}

.user-search-results-leave-active {
  transition: all $trans-in;
  height: 0px;
}
</style>
