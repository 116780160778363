<template>
  <div>
    <div class="data-card">
      <div class="data-card__body">
        <div v-if="state.startedAt" class="data-card__info">
          <div class="data-card__info-icon">
            <i class="material-icons">event</i>
          </div>
          <div class="data-card__info-element">
            <div class="data-card__info-label">{{ trans('Started') }}</div>
            <div class="data-card__info-text" v-if="state.startedAt" v-html="state.startedAt.datetime"></div>
          </div>
        </div>

        <div v-if="state.completedAt" class="data-card__info">
          <div class="data-card__info-icon">
            <i class="material-icons">check</i>
          </div>
          <div class="data-card__info-element">
            <div class="data-card__info-label">{{ trans('Completed') }}</div>
            <div class="data-card__info-text" v-if="state.completedAt" v-html="state.completedAt.datetime"></div>
          </div>
        </div>

        <div v-if="language" class="data-card__info">
          <div class="data-card__info-icon">
            <i class="material-icons">language</i>
          </div>
          <div class="data-card__info-element">
            <div class="data-card__info-label">{{ trans('Language') }}</div>
            <div class="data-card__info-text">{{ language.name }}</div>
          </div>
        </div>

        <div v-if="state.expiresAt" class="data-card__info">
          <div class="data-card__info-icon">
            <i class="material-icons">hourglass_empty</i>
          </div>
          <div class="data-card__info-element">
            <div class="data-card__info-label">{{ trans(state.isExpired ? 'Expired' : 'Expires') }}</div>
            <div class="data-card__info-text" v-if="state.expiresAt" v-html="state.expiresAt.date"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="state.isAttempting" class="modal-body modal-body--tinted">
      <h4 class="mdl-subheader">
        {{ trans('Active attempt') }}:
        <span class="opaque">
          {{ trans('Started') }}
          <span v-html="state.attemptedAt.datetime"></span>
        </span>
      </h4>
      <div class="course-nodes-container">
        <NodeCollection :nodes="attempt.nodes" :attempt="attempt"></NodeCollection>
      </div>
    </div>
  </div>
</template>

<script>
import NodeCollection from '@component/UserCourse/NodeCollection.vue'
import { trans } from '@/munio/i18n/index.js'

export default {
  inject: ['root'],
  components: {
    NodeCollection,
  },
  computed: {
    state() {
      return this.root.state
    },
    user() {
      return this.root.user
    },
    attempt() {
      return this.root.attempt
    },
    diploma() {
      return this.root.diploma
    },
    language() {
      let language = null

      if (this.attempt) {
        language = this.attempt.language
      } else if (this.diploma) {
        language = this.diploma.language
      }

      return language ? Munio.language(language) : null
    },
  },
  methods: {
    trans,
  },
}
</script>
