import { watch } from 'vue'
import $ from 'jquery'

Munio.behaviors.counters = {
  selector: '[data-counter]',
  attach: function (context) {
    $(this.selector, context).each(function (i, el) {
      const counter = Munio.Counters.count(el.dataset.counter)

      watch(counter, (value) => {
        const val = value ? String(value) : ''

        if (el.classList.contains('mdl-badge')) {
          el.dataset.badge = val
        } else {
          el.innerText = val
        }
      })
    })
  },
}
