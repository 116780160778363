import { CompanyBasicResourceDto } from './data/CompanyBasicResource.dto'
import { CompanyAddressDto, CompanyResourceDto } from './data/CompanyResource.dto'
import { CollectionResponse, ResourceResponse } from './data/common'

const { $http, route, urlParams } = window.Munio
const cleanup = window.cleanup

type CompanyPayload = {
  name: string
  nameShort: string
  nameLegal: string
  nameLimited: string
  reference?: string
  invoiceEmail: string
}

class Companies {
  search(query: string, country: string, context = false, dropdown = false) {
    return $http.get(route('api.web.companies'), cleanup({ query, country, context, dropdown }))
  }

  setContext(id: number) {
    return $http.post(route('api.web.admin.company.context', { company: id }))
  }

  add(company: CompanyBasicResourceDto, redirect = false) {
    return $http.post(route('api.web.admin.companies.create'), cleanup({ ...company, redirect }))
  }

  index(options?: URLSearchParams) {
    return $http.get<CollectionResponse<CompanyResourceDto>>(route('api.web.admin.companies'), urlParams(options))
  }

  id(id: number, context = false) {
    return new Company(id, context)
  }
}

class Company {
  constructor(
    protected id: number,
    protected context: boolean,
  ) {}

  get params() {
    return {
      company: this.id,
      context: this.context,
    }
  }

  get() {
    return $http.get<ResourceResponse<CompanyResourceDto>>(Munio.route('api.web.admin.company', this.params))
  }

  update(data: object) {
    return $http.put<ResourceResponse<CompanyResourceDto>>(
      Munio.route('api.web.admin.company.update', this.params),
      data,
    )
  }

  saveAddress(address: CompanyAddressDto) {
    return $http.put<ResourceResponse<CompanyResourceDto>>(
      Munio.route('api.web.admin.company.address.save', this.params),
      address,
    )
  }

  deleteAddress(id: number) {
    return $http.delete<ResourceResponse<CompanyResourceDto>>(
      Munio.route('api.web.admin.company.address.delete', {
        ...this.params,
        address: id,
      }),
    )
  }
}

export default Companies
