import Quiz from '@/munio/api/AttemptQuiz'
import { AttemptLegacyDto, ModulePlayback } from '@/munio/api/data/legacy'
import { ScormModel } from '@app/CoursePlayer/course/types.ts'

const { $http, route } = window.Munio

class AttemptNode {
  constructor(
    public attemptId: number,
    public nodeId: number,
  ) {}

  get params() {
    return {
      attempt: this.attemptId,
      node: this.nodeId,
    }
  }

  get() {
    return $http.get<ModulePlayback>(route('api.web.attempt.node', this.params))
  }

  startCourse() {
    return $http.post<AttemptLegacyDto>(route('api.web.attempt.node.course.start', this.params))
  }

  approve(date?: string) {
    return $http.post(route('api.web.attempt.node.approve', this.params), {
      completed_at: date,
    })
  }

  async videoProgress(metadata: unknown) {
    return await navigator.sendBeacon(
      route('api.web.attempt.node.video.progress', {
        attempt: this.attemptId,
        node: this.nodeId,
      }),
      new Blob([JSON.stringify(metadata)], { type: 'application/json' }),
    )
  }

  sessionEnroll(id: number) {
    return $http.post(route('api.web.attempt.node.session.enroll', { ...this.params, session: id }))
  }

  sessionUnenroll(id: number) {
    return $http.delete(route('api.web.attempt.node.session.unenroll', { ...this.params, session: id }))
  }

  scorm(itemId: string) {
    return new Scorm(this.attemptId, this.nodeId, itemId)
  }

  get quiz() {
    return new Quiz(this.attemptId, this.nodeId)
  }
}

class Scorm {
  constructor(
    public attemptId: number,
    public nodeId: number,
    public itemId: string,
  ) {}

  get params() {
    return {
      attempt: this.attemptId,
      node: this.nodeId,
      item: this.itemId,
    }
  }

  init() {
    return $http.get<ScormModel>(route('api.web.attempt.node.scorm.initialize', this.params))
  }

  commit(data: unknown, finish = false) {
    return $http.post<ScormModel>(route('api.web.attempt.node.scorm.commit', this.params), { data, finish })
  }
}

export default AttemptNode
