import $ from 'jquery'

/**
 * Enhanced number input.
 */
Munio.behaviors.quantity = {
  selector: '.input-quantity, .input-qty',
  attach: function (context) {
    $(this.selector, context).each(function (i, el) {
      let $plus = $('.plus', el)
      let $minus = $('.minus', el)
      let $number = $('input', el)

      let min = $number.attr('min')
      let max = $number.attr('max')
      let step = $number.attr('step')

      function ensureValidRange() {
        if (min && Number($number.val()) < min) $number.val(min)
        if (max && Number($number.val()) > max) $number.val(max)
      }

      $plus.on('click', function () {
        $number.val(Number($number.val()) + (step ? Number(step) : 1))
        ensureValidRange()
      })

      $minus.on('click', function () {
        $number.val(Number($number.val()) - (step ? Number(step) : 1))
        ensureValidRange()
      })

      $number.on('blur', function () {
        ensureValidRange()
      })
    })
  },
}
