<template>
  <div class="mdl-filter__form" :class="$attrs.class" @click="emit('activate')">
    <MdlTextfield v-autofocus="autofocus" :name="inputName" :model-value="value" @keydown.enter="emit('submit')" />
  </div>
</template>

<script setup lang="ts">
import vAutofocus from '@directive/autofocus.js'
import MdlTextfield from '@component/mdl/Textfield.vue'

defineOptions({
  name: 'InputFilter',
  inheritAttrs: false,
})

withDefaults(
  defineProps<{
    inputName: string
    value?: object | string | number
    autofocus?: boolean
  }>(),
  {
    autofocus: true,
  },
)

const emit = defineEmits(['activate', 'submit'])
</script>
