import $ from 'jquery'

Munio.behaviors.disabledlinks = {
  selector: 'li.disabled a, a.disabled, li.disable a, a.disable',
  attach: function (context) {
    $(this.selector, context).on('click', function (event) {
      event.preventDefault()
    })
  },
}
