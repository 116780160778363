import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'
import { form } from './helpers.js'

const state = {
  customer: true,
  processing: false,
  parentUrl: '',
  form: form(),
  salespeople: {
    data: [],
    show: false,
    loading: false,
  },
  products: {
    data: [],
    show: false,
    loading: false,
  },
  addresses: [],
  order: {
    data: {},
    permissions: {
      canView: false,
      canEdit: false,
    },
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
