<template>
  <div class="user-search-field flex items-center">
    <input
      class="block w-100"
      type="search"
      :value="modelValue"
      :placeholder="root?.searchPlaceholder"
      :disabled="disabled"
      @input="onInput"
      autofocus
    />
    <MdlButton
      v-if="root?.invitable && root?.validEmail(modelValue)"
      icon="keyboard_arrow_right"
      @click="$emit('invite', modelValue)"
    />
  </div>
</template>

<script>
import MdlButton from '@component/mdl/Button.vue'
import { inject } from 'vue'

export default {
  components: {
    MdlButton,
  },

  props: {
    modelValue: { type: String, required: true },
    email: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  setup() {
    const root = inject('root')

    return {
      root,
    }
  },

  methods: {
    onInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.user-search-field {
  input {
    width: 100%;
  }

  .mdl-button {
    margin-left: 1rem;
  }
}
</style>
