<template>
  <form class="flex flex-col flex-auto justify-between" autocomplete="off" @submit.prevent="next">
    <MdlStepper :steps="steps" :current="step" class="p-0" />

    <!-- step components -->
    <div class="relative min-h-[240px] mt-12">
      <Transition :name="transition">
        <UserProfile ref="currentVm" v-if="currentStep.id === 'profile'" />
        <Employer ref="currentVm" v-else-if="currentStep.id === 'employer'" />
        <MSummary ref="currentVm" v-else-if="currentStep.id === 'summary'" />
      </Transition>
    </div>

    <span v-if="errorMessage">{{ errorMessage }}</span>

    <div class="action-bar">
      <MdlButton
        v-if="isLast"
        type="submit"
        :disabled="!registration.accepted || !verified || isLoading"
        primary
        raised
        :data-qa-id="pendo('createAccount')"
      >
        {{ trans('Create account') }}
      </MdlButton>

      <MdlButton v-else type="submit" :raised="isLast" primary :data-qa-id="pendo(`next.${step}`)">
        {{ trans('Next') }}
      </MdlButton>

      <!-- navigation actions -->
      <MdlButton @click="cancel" :data-qa-id="pendo(`${isFirst ? 'cancel' : 'back'}.${step}`)">
        {{ trans(isFirst ? 'Cancel' : 'Back') }}
      </MdlButton>
    </div>
  </form>
</template>

<script>
import { trans } from '@/munio/i18n'
import { pendoLabel } from '@/munio/utils'
import MSummary from './registration/Summary.vue'
import Employer from './registration/Employer.vue'
import UserProfile from './registration/UserProfile.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlStepper from '@component/mdl/Stepper.vue'

export default {
  name: 'Registration',

  provide() {
    return {
      registration: this.registration,
    }
  },

  components: {
    MSummary,
    Employer,
    UserProfile,
    MdlButton,
    MdlStepper,
  },

  data() {
    return {
      step: 0,
      errorMessage: null,
      registration: {
        firstname: '',
        lastname: '',
        email: '',
        emailRepeat: '',
        password: '',
        company: null,
        accepted: false,
        newsletter: false,
      },
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const { user, userAuthenticated, userInvite } = vm.$store.state

      if (userInvite && !userAuthenticated && user && !user.isInvited) {
        vm.$router.push({ name: 'invite.user.login', params: { invite: userInvite.uuid } })
      }
    })
  },

  created() {
    const { registration } = this
    let { user, username, employer } = this.$store.state

    if (user && user.isInvited) {
      registration.firstname = user.firstname
      registration.lastname = user.lastname
      registration.email = user.email
      registration.emailRepeat = user.email
    }

    if (!employer && user?.company?.id) {
      employer = user.company
    }

    if (employer) {
      registration.company = { ...employer }
    }

    // insert username from previous page
    // as email if it contains a @ character
    if (/@/.test(username) && !registration.email.length) {
      registration.email = username
    }
  },

  computed: {
    transition: {
      get() {
        return this.$store.state.transition
      },

      set(value) {
        this.$store.commit('transition', value)
      },
    },

    verified() {
      return window.Munio.config.captcha.valid || !!this.$store.state.captcha
    },

    steps() {
      let steps = [
        {
          id: 'profile',
          label: trans('User profile'),
          component: UserProfile,
        },
        {
          id: 'employer',
          label: trans('Employer'),
          component: Employer,
        },
        {
          id: 'summary',
          label: trans('Confirm'),
          component: MSummary,
        },
      ]

      // skip the employer step if already defined by invite
      if (this.employer) {
        steps = steps.filter((step) => {
          return step.id !== 'employer'
        })
      }

      return steps
    },

    currentStep() {
      return this.steps[this.step] || this.steps[0]
    },

    isLoading() {
      return this.$store.state.loading
    },

    isFirst() {
      return this.step === 0
    },

    isLast() {
      return this.steps.indexOf(this.currentStep) === this.steps.length - 1
    },

    employer() {
      return this.$store.state.employer
    },
  },

  methods: {
    trans,
    pendo: pendoLabel,

    cancel() {
      if (this.isFirst) {
        this.$store.commit('navigateToEntry')
      } else {
        this.transition = false
        this.step-- // todo: fix back when employer is disabled
      }
    },

    async next() {
      if (await this.validateStep()) {
        if (!this.isLast) {
          this.transition = true
          this.step++
        } else {
          this.$store.dispatch('register', this.registration)
        }
      }
    },

    validateStep() {
      const { currentVm } = this.$refs
      return typeof currentVm.validate === 'function' ? currentVm.validate() : true
    },
  },
}
</script>
