import { computed, ref, reactive } from 'vue'

class Counters {
  constructor() {
    this.refs = {}
    this.ulid = ref(Munio.config.counters.ulid)
    this.list = reactive(Munio.config.counters.list ?? {})
  }

  get(key) {
    return computed(() => {
      let sum = 0

      for (const [counterKey, value] of Object.entries(this.list)) {
        if (counterKey.startsWith(key)) {
          sum += value
        }
      }

      return sum
    })
  }

  count(key) {
    if (this.refs[key]) {
      return this.refs[key]
    }

    return (this.refs[key] = computed(() => {
      const result = this.get(key)

      if (result.value > 99) {
        return '99+'
      }

      if (!result.value) {
        return undefined
      }

      return String(result.value)
    }))
  }

  /**
   * @param {string|null} newUlid
   * @returns {Promise<void>}
   */
  async reload(newUlid = null) {
    if (newUlid && newUlid === this.ulid.value) {
      return
    }

    const {
      data: { ulid: currentUlid, list: currentList },
    } = await Munio.api.currentUser.counters()
    this.ulid.value = currentUlid
    Object.assign(this.list, currentList)
  }
}

Munio.Counters = new Counters()
