import Wrapper from '@app/Wrapper.js'

Munio.CoursePlayer = class CoursePlayer extends Wrapper {
  constructor(selector, { attemptId, isPreview, selectLanguage, backUrl }) {
    super(selector, {
      attemptId,
      isPreview,
      selectLanguage,
      backUrl,
    })
  }

  component() {
    return import('./course/App.vue')
  }
}

Munio.QuizPlayer = class QuizPlayer extends Wrapper {
  constructor(selector, { attemptId, nodeId, language, model, playback }) {
    super(selector, {
      attemptId,
      nodeId,
      language,
      model,
      playback,
    })
  }

  component() {
    return import('./quiz/App.vue')
  }
}
