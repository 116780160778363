import Wrapper from '@app/Wrapper.js'
import store from './store'

Munio.OrderViewer = class extends Wrapper {
  constructor(selector, order, customer = true, parentUrl = '') {
    super(selector, { order, customer, parentUrl })
  }

  store() {
    return store
  }

  component() {
    return import('./App.vue')
  }
}

Munio.OrderCreator = class extends Wrapper {
  constructor(selector, shop) {
    super(selector, { shop })
  }

  component() {
    return import('./Creator.vue')
  }
}
