import AttemptNode from './AttemptNode'
import { AttemptLegacyDto } from '@/munio/api/data/legacy'
import { CourseConditionType } from '@/munio/definitions/course.conditions'

const { $http, route } = window.Munio

class Attempt {
  constructor(public id: number) {}

  get params() {
    return {
      attempt: this.id,
    }
  }

  get(language?: string) {
    return $http.get<AttemptLegacyDto>(route('api.web.attempt', this.params), Munio.urlParams({ language }))
  }

  changeLanguage(language: string) {
    return $http.post<AttemptLegacyDto>(route('api.web.attempt.language.update', this.params), { language })
  }

  updateCondition(type: CourseConditionType, value: unknown) {
    return $http.post<AttemptLegacyDto>(route('api.web.attempt.condition.update', this.params), { type, value })
  }

  rejectAuth() {
    return $http.post<AttemptLegacyDto>(route('api.web.attempt.auth.reject', this.params))
  }

  confirmAuth() {
    return $http.post<AttemptLegacyDto>(route('api.web.attempt.auth.confirm', this.params))
  }

  node(nodeId: number) {
    return new AttemptNode(this.id, nodeId)
  }
}

export default Attempt
