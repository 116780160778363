import { Ref } from 'vue'

const map = new Map<
  string,
  {
    input: HTMLInputElement
    checked: Ref<boolean>
  }[]
>()

export function useRadioGroup() {
  return {
    add(name: string, input: HTMLInputElement, checked: Ref<boolean>) {
      const radios = map.get(name) ?? []
      radios.push({ input, checked })
      map.set(name, radios)
    },
    remove(name: string, input: HTMLInputElement) {
      const radios = map.get(name) ?? []
      map.set(
        name,
        radios.filter(({ input: radioInstance }) => radioInstance !== input),
      )
    },
    onChecked(name: string, input: HTMLInputElement) {
      const radios = map.get(name) ?? []
      radios.forEach(({ input: radioInstance, checked }) => {
        if (radioInstance !== input) {
          checked.value = radioInstance.checked
        }
      })
    },
  }
}
