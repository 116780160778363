<script setup lang="ts">
import { trans } from '@/munio/i18n'
import { SelectOption } from '@/munio/definitions'
import MdlRadio from '@component/mdl/Radio.vue'
import Icon from '@component/Icon.vue'

defineOptions({
  name: 'RadioFilter',
  inheritAttrs: false,
})

withDefaults(
  defineProps<{
    inputName: string
    disabled?: boolean
    value?: string | number
    options?: SelectOption[]
  }>(),
  {
    inputName: 'value',
    options: () => [],
  },
)

const emit = defineEmits(['activate'])

function optionClasses(option: SelectOption) {
  if (option.indent) {
    return [`indent-${option.indent}`]
  }
}
</script>

<template>
  <div class="mdl-filter__form" :class="$attrs.class" @click="emit('activate', $event)">
    <div
      v-for="option of options"
      :key="option.value"
      class="mdl-filter__option"
      :class="{ 'is-disabled': option.disabled }"
    >
      <MdlRadio
        :class="optionClasses(option)"
        :name="inputName"
        :value="option.value"
        :disabled="option.disabled"
        :checked="String(option.value) === String(value)"
      >
        <div class="flex">
          <img v-if="option.image" :src="option.image" :alt="trans('Avatar')" />
          <span class="flex flex-col">
            <small v-if="option.identifier" class="opaque">{{ option.identifier }}</small>
            <span>
              <Icon v-if="option.icon" :name="option.icon" small />
              {{ option.label }}
            </span>
            <span v-if="option.description" class="mdl-radio__description">
              {{ option.description }}
            </span>
          </span>
        </div>
      </MdlRadio>
    </div>
  </div>
</template>
