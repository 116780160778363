<script setup lang="ts">
import { trans } from '@/munio/i18n/index.js'
import vAutofocus from '@directive/autofocus.js'
import DatePicker from '@component/DatePicker.vue'
import Icon from '@component/Icon.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlTextfield from '@component/mdl/Textfield.vue'
import { computed, ref, watch } from 'vue'

defineOptions({
  name: 'DateRangeFilter',
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    inputName: string
    value?: { from: string; to: string }
    disabled?: boolean
    autofocus?: boolean
    min?: string
    max?: string
  }>(),
  {
    inputName: 'value',
    value: () => ({ to: '', from: '' }),
    autofocus: true,
  },
)

defineEmits(['activate'])

const from = ref()
const to = ref()

const openDateFrom = ref(false)
const openDateTo = ref(false)
const inputFrom = ref(props.value.from)
const inputTo = ref(props.value.to)
const dateFromClasses = computed(() => ({
  'is-focused': openDateFrom.value,
  'is-dirty': inputFrom.value,
  'opacity-60': props.disabled,
}))
const dateToClasses = computed(() => ({
  'is-focused': openDateTo.value,
  'is-dirty': inputTo.value,
  'opacity-60': props.disabled,
}))

watch(
  () => props.value,
  (value) => {
    if (value.from !== inputFrom.value || value.to !== inputTo.value) {
      inputFrom.value = value.from
      inputTo.value = value.to
    }
  },
)

function onDateFromChange(value) {
  inputFrom.value = value
  from.value.blur()
  if (inputFrom.value && !inputTo.value) {
    to.value.focus()
  }
}

function onDateToChange(value) {
  inputTo.value = value
  to.value.blur()
}
</script>

<template>
  <div class="mdl-filter__form" :class="$attrs.class" @click="$emit('activate')">
    <div>
      <MdlTextfield
        ref="from"
        readonly
        size="10"
        :class="dateFromClasses"
        :floating-label="trans('From')"
        :name="`${inputName}_from`"
        :model-value="inputFrom"
        v-autofocus="autofocus"
        @click="openDateFrom = true"
        @focus="openDateFrom = true"
      >
        <DatePicker
          :show="openDateFrom"
          :min-date="min"
          :max-date="inputTo || value.to || max"
          :model-value="inputFrom"
          @update:modelValue="onDateFromChange"
          @close="openDateFrom = false"
        />
        <MdlButton v-if="inputFrom" icon @click.stop="onDateFromChange(undefined)" class="top-3">
          <Icon name="cancel" class="smaller" />
        </MdlButton>
      </MdlTextfield>
    </div>
    <div class="mx-4">&rarr;</div>
    <div>
      <MdlTextfield
        ref="to"
        readonly
        size="10"
        :class="dateToClasses"
        :floating-label="trans('To')"
        :name="`${inputName}_to`"
        :model-value="inputTo"
        @click="openDateTo = true"
        @focus="openDateTo = true"
      >
        <DatePicker
          :show="openDateTo"
          :min-date="inputFrom || value.from || min"
          :max-date="max"
          :model-value="inputTo"
          @update:modelValue="onDateToChange"
          @close="openDateTo = false"
        />
        <MdlButton v-if="inputTo" icon @click.stop="onDateToChange(undefined)" class="top-3">
          <Icon name="cancel" class="smaller" />
        </MdlButton>
      </MdlTextfield>
    </div>
  </div>
</template>
