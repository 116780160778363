<template>
  <div class="flex flex-col flex-auto justify-between" v-if="invite">
    <div>
      <component :is="elementComponent" :invite="invite">
        <div v-if="invite.comment" class="my-8">
          <blockquote class="m-0">
            <p>{{ invite.comment }}</p>
            <footer>{{ invite.sender.fullname }}</footer>
          </blockquote>
        </div>

        <div v-else class="my-8">
          <h3 class="mdl-subheader">{{ trans('Invited by') }}</h3>
          <strong>{{ invite.sender.fullname }}</strong>
          <div>{{ invite.company.name }}</div>
        </div>
      </component>
    </div>

    <transition :name="transition">
      <div v-if="justContinue" class="action-bar">
        <MdlButton raised primary @click="next">
          {{ trans(authenticated ? 'Continue' : 'Log in') }}
        </MdlButton>
      </div>

      <div v-else class="action-bar justify-between">
        <MdlButton :disabled="loading" raised primary @click="accept">
          {{ trans('Accept') }}
        </MdlButton>
        <MdlButton :disabled="loading" danger outlined @click="decline">
          {{ trans('Decline') }}
        </MdlButton>
      </div>
    </transition>
  </div>
</template>

<script>
import { trans } from '@/munio/i18n'
import AccessRequisition from './invitation/AccessRequisition.vue'
import AccessZone from './invitation/AccessZone.vue'
import AccessZoneAdmin from './invitation/AccessZoneAdmin.vue'
import Course from './invitation/Course.vue'
import UnknownType from './invitation/UnknownType.vue'
import MdlButton from '@component/mdl/Button.vue'

export default {
  components: {
    AccessRequisition,
    AccessZone,
    AccessZoneAdmin,
    Course,
    MdlButton,
  },

  computed: {
    invite() {
      return this.$store.state.userInvite
    },
    elementType() {
      return this.invite.elementType
    },
    elementUrl() {
      return this.invite.elementUrl
    },
    elementComponent() {
      if (!this.invite) {
        return undefined
      }

      if (this.invite.elementIsCourse) {
        return Course
      }

      switch (this.elementType) {
        case 'access_zone':
          return AccessZone
        case 'access_zone_admin':
          return AccessZoneAdmin
        case 'access_requisition':
          return AccessRequisition
        default:
          return UnknownType
      }
    },
    transition() {
      return this.$store.state.transition
    },
    loading() {
      return this.$store.state.loading
    },
    authenticated() {
      if (!this.$store.state.userAuthenticated) {
        return false
      }

      return this.$store.state.user && !this.$store.state.user.isInvited
    },
    justContinue() {
      if (!this.authenticated) {
        return true
      }

      return this.invite.isAccepted || !this.invite.requireAccept
    },
  },

  methods: {
    trans,

    next() {
      if (this.authenticated && this.justContinue) {
        return this.accept()
      }

      let user = this.$store.state.user
      let route = user && user.isInvited ? 'invite.user.registration' : 'invite.user.login'

      this.$store.commit('transition', true)
      this.$router.push({ name: route, params: { invite: this.invite.uuid } })
    },

    async accept() {
      this.$store.dispatch('acceptUserInvite')
    },

    async decline() {
      if (await Munio.confirm()) {
        this.$store.dispatch('declineUserInvite')
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.userInvite) {
        vm.$store.commit('navigate', 'login')
      }
    })
  },
}
</script>
