import $ from 'jquery'

Munio.behaviors.capslockWarning = {
  selector: '.capslock-warning',
  attach: function (context, config) {
    const isMac = /Mac/.test(navigator.platform)

    $(this.selector, context).each(function (i, element) {
      const $delegate = $(element)
      $delegate.on('keypress', function (e) {
        let capsLock = false,
          prevLock = $delegate.data('capslock') || capsLock,
          charCode = e.which,
          shiftKey = false

        if (e.shiftKey) {
          shiftKey = e.shiftKey
        } else if (e.modifiers) {
          shiftKey = !!(e.modifiers & 4)
        }

        // store whether the caps lock key is down
        if (charCode >= 97 && charCode <= 122) {
          capsLock = shiftKey
        } else if (charCode >= 65 && charCode <= 90 && !(shiftKey && isMac)) {
          capsLock = !shiftKey
        }

        $delegate.data('capslock', capsLock)

        if (prevLock != capsLock) {
          if (capsLock) $delegate.addClass('capslock-on')
          else $delegate.removeClass('capslock-on')
        }
      })
    })
  },
}
