import Wrapper from '@app/Wrapper.js'

Munio.CompanyDepartments = class extends Wrapper {
  constructor(selector, departmentId) {
    super(selector, { departmentId })
  }

  component() {
    return import('./App.vue')
  }
}
