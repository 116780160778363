import { isEmpty } from '@/munio/utils/index.js'
import { toDateString } from '@/munio/utils/date.js'
import { lineForm, getCompanyAddress } from './helpers.js'
import { trans } from '@/munio/i18n/index.js'

export default {
  amount: (state) => (value) => {
    return Munio.Currency.amount(value, state.order.data?.currency?.code, 2)
  },

  money: (state) => (value) => {
    return Munio.Currency.format(value, state.order.data?.currency?.code, 2)
  },

  calcDiscount:
    (state, { amount }) =>
    (price, priceFull) => {
      if ([0, 0.0].includes(priceFull)) {
        return 0
      }

      const result = amount(1).subtract(amount(price).divide(priceFull)).toUnit()

      return parseFloat(result.toPrecision(2))
    },

  salesperson: ({ order, form }) => {
    return form.salesperson || order.data.salesperson
  },

  customer: ({ order, form }) => {
    return form.customer || order.data.customer
  },

  contact: ({ order, form }) => {
    return form.contact || order.data.contact
  },

  paymentCustomer: ({ order, form }) => {
    return form.payment.customer || order.data.payment.customer
  },

  paymentAddress: ({ order, form }) => {
    if (form.payment.address !== undefined) {
      return form.payment.address
    }

    return order.data.payment.address || getCompanyAddress(order.data.payment.customer.company) || undefined
  },

  invoiceEmail: ({ order, form }) => {
    if (!isEmpty(form.payment.invoice.email)) {
      return form.payment.invoice.email
    }

    return order.data.payment.invoice?.email
  },

  invoiceDays: ({ order, form }) => {
    return form.payment.invoice.days || order.data.payment.invoice?.days
  },

  invoiceReference: ({ order, form }) => {
    if (!isEmpty(form.payment.invoice.reference)) {
      return form.payment.invoice.reference
    }

    return order.data.payment.invoice?.reference
  },

  invoiceComment: ({ order, form }) => {
    if (!isEmpty(form.payment.invoice.comment)) {
      return form.payment.invoice.comment
    }

    return order.data.payment.invoice?.comment
  },

  lines: ({ order, form }) => {
    const lines = (order.data?.lines || []).concat(form.linesAdded)

    return lines
      .map((line) => ({
        ...line,
        input: lineForm(form, line),
      }))
      .filter((line) => !line.input.removed)
  },

  canApprove: ({ order }, { isModified }) => {
    if (isModified) {
      return false
    }

    return order.permissions.canApprove
  },

  canDuplicate: ({ order }) => {
    return order.permissions.canDuplicate
  },

  canTransferLicenses: ({ order }, { canDuplicate }) => {
    return canDuplicate && order.data.status.key === 'cancelled'
  },

  canRemove: ({ order }) => {
    return order.permissions.canRemove
  },

  canBill: ({ order }) => {
    return order.data.status.isBillable
  },

  canUpdateLedger: ({ order }) => {
    return order.data.ledger.enabled && order.data.status.isBilled
  },

  isModified: (state) => {
    const form = state.form

    const lines = Object.values(form.lines).length > 0
    const fields =
      [
        form.salesperson,
        form.customer,
        form.contact,
        form.payment.company,
        form.payment.address,
        form.payment.invoice.email,
        form.payment.invoice.days,
        form.payment.invoice.reference,
        form.payment.invoice.comment,
        form.enableAccounting,
        form.enableLicensing,
      ]
        .map((field) => field !== undefined)
        .filter((v) => v).length > 0

    return lines || fields
  },

  enableAccounting: ({ order, form }) => {
    if (!isEmpty(form.enableAccounting)) {
      return form.enableAccounting
    }

    return order.data.enableAccounting
  },

  enableLicensing: ({ order, form }) => {
    if (!isEmpty(form.enableLicensing)) {
      return form.enableLicensing
    }

    return order.data.enableLicensing
  },

  isAdmin: (state) => {
    return !state.customer
  },

  isMember: (state, { customer, paymentCustomer }) => {
    return customer.membership?.active || paymentCustomer?.membership?.active || false
  },

  canEdit: (state) => {
    if (state.customer) {
      return false
    }

    if (!state.order.permissions.canEdit) {
      return false
    }

    return !state.order.data.status.isProcessed
  },

  getLine:
    (state, { lines }) =>
    (uid) => {
      return lines.find((line) => line.uid === uid)
    },

  statusSteps: (state) => {
    const order = state.order.data

    let steps = [
      {
        label: trans('Created'),
        status: 'draft',
        selectable: false,
        done: true,
        failed: false,
        description: toDateString(order.createdAt),
      },
      {
        label: trans('Accepted'),
        status: 'accepted',
        selectable: false,
        done: !!order.acceptedAt,
        failed: false,
        description: toDateString(order.acceptedAt),
      },
      {
        label: trans('Paid'),
        status: 'paid',
        selectable: false,
        done: !!order.paidAt,
        failed: false,
        description: toDateString(order.paidAt),
      },
    ]

    if (order.cancelledAt) {
      steps = steps.map((step) => ({ ...step, failed: true })).filter((step) => step.done)
      steps.push({
        label: trans('Cancelled'),
        status: 'cancelled',
        selectable: false,
        done: true,
        failed: true,
        description: toDateString(order.cancelledAt),
      })
    }

    return steps
  },

  statusStep: (state, getters) => {
    const statusSteps = getters.statusSteps

    return statusSteps.findIndex((step) => step.status === state.order.data.status.key)
  },
}
