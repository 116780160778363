<template>
  <div class="csv-export mdl-button-group">
    <MdlButtonMenu
      v-if="showCsv"
      :disable="disabled || loading"
      :loading="loading"
      :extra-classes="'csv-export mdl-button__action'"
    >
      <template #button>
        CSV
        <Icon :name="'expand_more'" class="mdl-button__dropdown" />
      </template>

      <template #menu>
        <MenuItem class="dropdown-body nowrap" content>
          <h6 class="mdl-subheader">{{ trans('Reports') }}</h6>
          <div v-for="csvDefinition of csv" :key="csvDefinition.id">
            <MdlRadio
              name="report"
              :checked="id === csvDefinition.id"
              @change="id = csvDefinition.id"
              :disabled="csvDefinition?.isDisabled"
            >
              {{ csvDefinition.label }}
              <div>
                <small>{{ csvDefinition.description }}</small>
              </div>
            </MdlRadio>
          </div>
        </MenuItem>

        <MenuItem divide />

        <MenuItem content class="flex">
          <MdlButton primary raised :disabled="!canDownload" @click="download">
            {{ trans('Download') }}
          </MdlButton>
        </MenuItem>
      </template>
    </MdlButtonMenu>

    <MdlButton v-else opaque class="mdl-button__action" :loading="loading" @click="download">
      <Icon name="download" />
      <div>{{ 'CSV' }}</div>
    </MdlButton>

    <MdlButtonMenu :disable="disabled || loading" :extra-classes="'mdl-button__menu-button'">
      <template #button>
        <Icon name="settings" />
      </template>

      <template #menu>
        <MenuItem class="dropdown-body nowrap" content>
          <h6 class="mdl-subheader">{{ trans('Column titles') }}</h6>
          <div class="mb-4">
            <MdlCheckbox v-model:checked="settings.header" :label="trans('Include')" />
          </div>

          <h6 class="mdl-subheader">{{ trans('Delimiter') }}</h6>
          <div v-for="type of delimiters" :key="type.key" class="flex items-center">
            <MdlRadio
              name="delimiter"
              class="flex-1"
              :checked="settings.delimiter === type.key"
              @change="settings.delimiter = type.key"
            >
              {{ type.label }}
            </MdlRadio>
            <div class="flex items-center justify-center w-8 h-8 mdl-color--grey-200 rounded">
              <code v-html="type.char" />
            </div>
          </div>
        </MenuItem>
      </template>
    </MdlButtonMenu>
  </div>
</template>

<script>
import { trans } from '@/munio/i18n'
import Icon from '@component/Icon.vue'
import MdlButtonMenu from '@component/mdl/ButtonMenu.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlRadio from '@component/mdl/Radio.vue'
import MdlCheckbox from '@component/mdl/Checkbox.vue'
import MenuItem from '@component/MenuItem.vue'

export default {
  components: {
    MdlButtonMenu,
    Icon,
    MdlButton,
    MdlRadio,
    MenuItem,
    MdlCheckbox,
  },

  props: {
    loading: Boolean,
    disabled: Boolean,
    csv: Array,
  },

  data() {
    const { delimiter, header, includeHeadings } = Munio.Storage.getWithDefault('csvSettings', Munio.config.csv)

    return {
      id: null,
      delimiters: [
        { key: 'semicolon', label: trans('Semicolon'), char: ';' },
        { key: 'comma', label: trans('Comma'), char: ',' },
      ],
      settings: {
        delimiter: String(delimiter).toLowerCase(),
        header: Boolean(includeHeadings ?? header),
      },
      showDropdown: false,
    }
  },

  watch: {
    csv: {
      immediate: true,
      handler() {
        if (!this.id && this.csv) {
          this.id = this.csv[0]?.id
        }
      },
    },
    'settings.delimiter': function () {
      this.saveSettings()
    },
    'settings.header': function () {
      this.saveSettings()
    },
  },

  computed: {
    showCsv() {
      return this.csv?.length > 1
    },
    canDownload(event) {
      let selectedCsv = this.csv.filter((csv) => csv.id === this.id)[0]

      return !selectedCsv?.isDisabled && !this.loading
    },
  },

  methods: {
    trans,
    hideDropdown() {
      this.showDropdown = false
    },
    download(event) {
      this.hideDropdown()
      this.$emit('export', { ...this.settings, id: this.id })
      Munio.Events.$emit('csv.export', { ...this.settings, id: this.id })
    },
    saveSettings() {
      Munio.Storage.set('csvSettings', { ...this.settings })
    },
  },
}
</script>
