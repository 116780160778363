import Wrapper from '@app/Wrapper.js'

Munio.MunioDashboard = class extends Wrapper {
  get name() {
    return 'Dashboard'
  }

  component() {
    return import('./App.vue')
  }
}

Munio.MunioIpRules = class extends Wrapper {
  get name() {
    return 'IpRules'
  }

  component() {
    return import('./IpRules/App.vue')
  }
}
