const inputTags = ['input', 'textarea', 'select']
const isInput = (el) => inputTags.includes(el.tagName.toLowerCase())
const getInput = (el) => (isInput(el) ? el : el.querySelector(inputTags.join(',')))

const defaultDelay = 250

export default {
  mounted(el, { value = defaultDelay }) {
    if (value === false) {
      return
    }

    if ((el = getInput(el))) {
      setTimeout(() => el.focus(), value === true ? defaultDelay : value)
    }
  },
}
