<template>
  <div
    class="dropdown w-[200px] flex items-center rounded-sm"
    :class="{ open: showDropdown }"
    rel="tooltip"
    :title="$attrs.title"
  >
    <MSelect v-model="selected" :disabled="disabled" value-format="object" :options="countryNames" searchable />
  </div>
</template>

<script>
import { map } from 'lodash'
import MSelect from '@component/Select.vue'

export default {
  inheritAttrs: false,

  components: {
    MSelect,
  },

  props: {
    modelValue: { type: String, default: Munio.config.company.country },
    local: { type: Boolean },
    disabled: { type: Boolean },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      countries: Munio.countries(this.local),
      showDropdown: false,
      selected: null,
    }
  },
  watch: {
    selected(newValue, oldValue) {
      if (!newValue || newValue === oldValue) {
        return
      }

      this.$emit('update:modelValue', newValue.data.iso)
      this.showDropdown = false
    },

    modelValue() {
      this.getFallbackCountry()
    },
  },

  computed: {
    countryNames() {
      return map(this.countries, (country) => ({
        id: country.iso,
        label: `${country.flag} ${country.i18n}`,
        data: country,
      }))
    },
  },

  created() {
    this.getFallbackCountry()
  },

  methods: {
    getFallbackCountry() {
      let currentCountry = this.countries.find((country) => country.iso === this.modelValue)

      this.selected = {
        id: currentCountry.iso,
        label: `${currentCountry.flag} ${currentCountry.name}`,
        data: currentCountry,
      }
    },
  },
}
</script>
