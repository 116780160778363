<template>
  <div v-if="button && hasOptions" :class="wrapperClasses" v-click-outside="hideMenu" v-focus-outside="hideMenu">
    <slot name="button" :toggle-menu="toggleMenu" :active="active">
      <MdlButton :icon="buttonIcon" :raised="buttonRaised" @click.stop="toggleMenu" />
    </slot>
    <ul :class="menuClasses" @click.stop="hideMenu" v-bind="$attrs" role="menu">
      <slot></slot>
    </ul>
  </div>

  <ul v-else-if="hasOptions" :target="target" :class="menuClasses" v-bind="$attrs" role="menu">
    <slot></slot>
  </ul>
</template>

<script>
import { useSlots } from 'vue'
import { clickOutside, focusOutside } from '@directive/eventOutside.js'
import useSlotCheck from '@composable/useSlotCheck.js'
import MdlButton from '@component/mdl/Button.vue'

let lastMenu
export default {
  inheritAttrs: false,

  components: {
    MdlButton,
  },

  directives: {
    clickOutside,
    focusOutside,
  },

  props: {
    target: { type: String, required: false },
    right: { type: Boolean, default: false },
    top: Boolean,
    button: { type: Boolean, default: false },
    buttonIcon: { type: String, default: 'more_vert' },
    buttonRaised: { type: Boolean },
  },

  emits: ['open', 'close'],

  data() {
    return {
      active: false,
    }
  },

  setup() {
    const hasOptions = useSlotCheck(useSlots().default)

    return {
      hasOptions,
    }
  },

  computed: {
    wrapperClasses() {
      return {
        'dropdown inline-block': true,
        open: this.active,
        dropup: this.top,
      }
    },

    menuClasses() {
      return {
        'dropdown-menu': true,
        'dropdown-menu-right': this.right,
      }
    },
  },

  methods: {
    hideMenu() {
      if (this.active) {
        this.active = false
        this.$emit('close')
      }
    },
    toggleMenu() {
      if (lastMenu && lastMenu !== this) {
        lastMenu.hideMenu()
      }

      lastMenu = this
      this.active = !this.active
      if (this.active) {
        this.$emit('open')
      }
    },
  },
}
</script>
