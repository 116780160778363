import Wrapper from '@app/Wrapper.js'

Munio.CompanyAccess = class extends Wrapper {
  constructor(selector, accountId) {
    super(selector, { accountId })
  }

  component() {
    return import('./App.vue')
  }
}
