export default class Certificates {
  constructor(zoneId) {
    this.zoneId = zoneId
  }

  get params() {
    return { zone: this.zoneId }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.access.zone.certificates', this.params))
  }

  add(certificateTypeId) {
    return Munio.$http.post(
      Munio.route('api.web.access.zone.certificate.add', { ...this.params, type: certificateTypeId }),
    )
  }

  remove(certificateTypeId) {
    return Munio.$http.delete(
      Munio.route('api.web.access.zone.certificate.remove', { ...this.params, type: certificateTypeId }),
    )
  }
}
