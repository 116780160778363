<script setup lang="ts">
import { computed, reactive, ref, watch, onMounted, nextTick } from 'vue'
import { Timer, cssTimeToMilliseconds } from '@/munio/utils/index.js'
import { trans } from '@/munio/i18n/index.js'

const ANIMATION_LENGTH = 250
const TIMEOUT_MAX = 10000
const TIMEOUT_MIN = 4000

defineOptions({
  name: 'MdlSnackbar',
})

defineExpose({
  show,
  hide,
})

type Toast = {
  id?: string | number
  type?: 'warning' | 'error' | 'info' | 'success'
  message: string
  dismissable?: boolean
  timeout?: number | boolean
  actionHandler?: string | Function
  actionText?: string
}

const props = defineProps<{
  toast?: Toast
}>()

const state = reactive({
  animationLength: ANIMATION_LENGTH,
  display: false,
  active: null as Toast | null,
  timer: null as Timer | null,
  toasts: [] as Toast[],
})

const $el = ref()
const $timer = ref()
const hasAction = computed(() => ![null, undefined].includes(state.active?.actionHandler))

watch(
  () => props.toast,
  (toast) => {
    if (toast) {
      render(prepare(toast))
    } else {
      state.active = null
    }
  },
  { immediate: true },
)

function show(toast: Toast) {
  const prepared = prepare(toast)
  window.dev.log('Showing toast', { toast, prepared })
  if (state.active) {
    if (prepared.id && state.active.id === prepared.id) {
      return render(prepared)
    }

    if (isBlocking(state.active)) {
      state.toasts.unshift(state.active)
      state.toasts.unshift(prepared)
      return hide()
    } else {
      return queue(prepared)
    }
  }

  render(prepared)
}

function hide(id?: string) {
  if (id) {
    if (state.active?.id !== id) {
      const queueIndex = state.toasts.find((toast) => toast.id === id)

      if (queueIndex >= 0) {
        state.toasts.splice(queueIndex, 1)
      }

      return
    }
  }

  state.display = false
  setTimeout(() => {
    state.active = null
    state.timer?.end()
    state.timer = null
    checkQueue()
  }, state.animationLength)
}

function isBlocking(toast: Toast) {
  if (!toast || toast.dismissable) {
    return false
  }

  return toast && [null, false].includes(toast.timeout)
}

function onMouseOver() {
  if (state.timer) {
    state.timer.pause()
    $timer.value.style.animationPlayState = 'paused'
  }
}

function onMouseOut() {
  if (state.timer) {
    state.timer.resume()
    $timer.value.style.animationPlayState = 'running'
  }
}

function onAction() {
  hide()
  const callback = state.active?.actionHandler

  if (typeof callback === 'function') {
    callback()
  }
}

function checkQueue() {
  if (state.toasts.length > 0) {
    show(state.toasts.shift()!)
  }
}

function queue(toast: Toast) {
  let queued

  if (toast.id && (queued = state.toasts.find((queued) => queued.id === toast.id))) {
    Object.assign(queued, toast)
  } else {
    state.toasts.push(toast)
  }

  state.toasts.sort((a, b) => {
    const ab = isBlocking(a)
    const bb = isBlocking(b)

    if (ab && !bb) return 1
    if (!ab && bb) return -1
    return 0
  })
}

function prepare(toast: Toast): Toast {
  let {
    message,
    type = null,
    dismissable = null,
    timeout = null,
    actionHandler = null,
    actionText = null,
    id = null,
  } = toast

  if (timeout === true) {
    timeout = TIMEOUT_MIN
  }

  if (typeof timeout === 'number') {
    if (timeout > TIMEOUT_MAX) timeout = TIMEOUT_MAX
    if (timeout < TIMEOUT_MIN) timeout = TIMEOUT_MIN
  } else if (timeout === null && dismissable !== null) {
    timeout = dismissable ? TIMEOUT_MAX : TIMEOUT_MIN
  } else if (timeout === null) {
    timeout = TIMEOUT_MIN
  }

  if (dismissable && !actionHandler) {
    actionText = trans('Close')
  }

  if (typeof actionHandler === 'string' && !actionText) {
    actionText = trans('Redirect')
  }

  return {
    id,
    type,
    message,
    actionHandler,
    actionText,
    dismissable,
    timeout,
  }
}

function render(toast: Toast) {
  window.dev.log('Rendering toast', toast, $timer.value)
  state.display = true
  state.active = toast

  if (toast.timeout) {
    state.timer = new Timer(hide, toast.timeout)
    $timer.value.style.animationDuration = toast.timeout + 'ms'
  }
}

onMounted(() => {
  const toasts = window.Munio.toasts as Toast[] | undefined
  state.animationLength = cssTimeToMilliseconds(getComputedStyle($el.value).transitionDuration) || state.animationLength

  if (toasts) {
    setTimeout(() => {
      toasts.forEach((toast) => show(toast))
    }, 500)
  }
})
</script>

<template>
  <div
    ref="$el"
    data-upgraded="MaterialSnackbar"
    class="mdl-snackbar mdl-js-snackbar"
    :class="{ 'mdl-snackbar--active': state.display, 'has-action': hasAction }"
    :inert="!state.display"
    :data-type="state.active?.type"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
  >
    <div class="mdl-snackbar__text" v-html="state.active?.message" />

    <a
      v-if="typeof state.active?.actionHandler === 'string'"
      :href="state.active.actionHandler"
      class="mdl-snackbar__action"
    >
      {{ state.active.actionText }}
    </a>

    <button
      v-else-if="state.active?.actionText"
      class="mdl-snackbar__action"
      :class="{ 'is-active': state.active.actionText }"
      type="button"
      @click="onAction"
    >
      {{ state.active.actionText }}
    </button>

    <div ref="$timer" class="mdl-snackbar__timer" :class="{ 'is-active': state.timer }" />
  </div>
</template>
