import { createApp, h, reactive } from 'vue'
import Wrapper from '@app/Wrapper.js'
import CourseEnrollment from '@app/CourseEnrollment/CourseEnrollment.vue'
import CourseEnrollmentIltStore from './CourseEnrollmentIltStore.js'
import CourseEnrollmentInfo from '@app/CourseEnrollment/CourseEnrollmentInfo.vue'

Munio.CourseEnrollment = class CourseEnrollement {
  instance = null
  state = reactive({
    open: false,
  })

  constructor(selector, id) {
    this.selector = selector
    this.id = id
  }

  mount() {
    if (this.instance) {
      return
    }

    this.instance = createApp({
      name: 'CourseEnrollment',
      data: () => this.state,
      render: (context) =>
        h(CourseEnrollment, {
          id: this.id,
          open: context.open,
          onClose: () => {
            this.state.open = false
          },
        }),
    })

    this.instance.mount(this.selector)
  }

  open() {
    this.mount()

    this.state.open = true
  }
}

Munio.CourseEnrollmentInfo = class {
  instance = null
  state = reactive({
    userId: null,
  })

  constructor(selector, id) {
    this.selector = selector
    this.id = id
  }

  mount() {
    if (this.instance) {
      return
    }

    this.instance = createApp({
      name: 'CourseEnrollmentInfo',
      data: () => this.state,
      render: (context) =>
        h(CourseEnrollmentInfo, {
          id: this.id,
          userId: context.userId,
          onClose: () => {
            this.state.userId = null
          },
        }),
    })

    this.instance.mount(this.selector)
  }

  open(userId) {
    this.mount()
    this.state.userId = userId
  }
}

Munio.CourseEnrollmentIlt = class extends Wrapper {
  constructor(selector, id) {
    super(selector, { id })
  }

  store() {
    return CourseEnrollmentIltStore
  }

  component() {
    return import('./CourseEnrollmentIlt.vue')
  }
}
