<template>
  <div
    class="mdl-searchbar"
    :class="{
      'is-searching': searching,
      'is-focused': focused,
      'is-dropdown': dropdown,
      'has-results': $slots.default,
    }"
    v-click-outside="onBlur"
    v-focus-outside="onBlur"
  >
    <div class="mdl-searchbar__container">
      <slot name="search" />

      <MdlTextfield
        v-bind="attrs"
        :label="label"
        v-model="input"
        v-autofocus="autofocus ? 500 : false"
        @focus="onFocus"
        @keydown.enter.stop.prevent="onEnter"
        @keydown.esc.stop.prevent="onBlur"
      >
        <MdlButton v-if="input" class="mdl-searchbar__clear" icon="clear" @click="onClear" />
      </MdlTextfield>

      <MdlButton primary outlined :loading="searching" :disabled="isSearchDisabled" @click="onClick">
        {{ trans('Search') }}
      </MdlButton>
    </div>
    <div v-if="$slots.default" class="mdl-searchbar__results">
      <slot />
    </div>
  </div>
</template>

<script>
import MdlTextfield from './Textfield.vue'
import MdlButton from './Button.vue'
import autofocus from '@directive/autofocus'
import { clickOutside, focusOutside } from '@directive/eventOutside'

import { omit, debounce } from 'lodash'
import { trans } from '@/munio/i18n/index.js'

export default {
  inheritAttrs: false,

  components: {
    MdlTextfield,
    MdlButton,
  },

  directives: {
    autofocus,
    clickOutside,
    focusOutside,
  },

  props: {
    searching: { type: Boolean },
    modelValue: { type: String, default: '' },
    label: { type: String, default: trans('Type to search') },
    minLength: { type: Number, default: 3 },
    delay: { type: [Boolean, Number], default: 400 },
    autofocus: { type: Boolean, default: true },
    dropdown: { type: Boolean },
    searchOnClick: { type: Boolean },
  },

  data() {
    return {
      focused: false,
      debounceSearch: null,
    }
  },

  computed: {
    input: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)

        if (this.debounceSearch && !this.searchOnClick) {
          this.debounceSearch(value)
        }
      },
    },

    attrs() {
      const attrs = omit(this.$attrs, ['label'])

      return attrs
    },

    isSearchDisabled() {
      return this.input.length < this.minLength || this.attrs.disabled || this.searching
    },
  },

  methods: {
    trans,

    onBlur(event) {
      this.focused = false
    },

    onFocus(event) {
      this.focused = true
    },

    onClear(event) {
      this.input = ''
      this.$emit('clear')
      if (!this.$attrs.onClear) {
        this.$emit('submit', '')
      }
    },

    onEnter(event) {
      if (this.isSearchDisabled) {
        return
      }

      this.submit(event.target.value)
    },

    onClick(event) {
      this.submit(this.modelValue)
    },

    submit(value) {
      if (this.minLength && value.length < this.minLength) {
        return
      }

      this.debounceSearch?.cancel()
      this.$emit('submit', value)
    },
  },

  created() {
    if (this.delay) {
      this.debounceSearch = debounce(this.submit, this.delay === true ? 400 : this.delay)
    }
  },

  mounted() {
    if (this.minLength === 0) {
      this.$nextTick(() => {
        this.submit(this.modelValue)
      })
    }
  },
}
</script>
