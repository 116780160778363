import $ from 'jquery'

/**
 * Make tab link active when clicked.
 * Bootstrap takes care of the rest.
 */
Munio.behaviors.paneltabs = {
  selector: '.panel-tabs',
  attach: function (context) {
    $(this.selector, context).each(function () {
      let $this = $(this)
      $('a', $this).on('click', function () {
        $('a', $this).removeClass('active')
        $(this).addClass('active')
      })
    })
  },
}
