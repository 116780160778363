import { createApp, h } from 'vue'
import { createPinia } from 'pinia'
import { createStore } from 'vuex'
import VuexPlugin from './VuexPlugin'

export default class Wrapper {
  selector = ''
  data = {}
  _app
  _store
  _router

  constructor(selector, data = {}) {
    this.selector = selector
    this.data = data

    this.mount()
  }

  get name() {
    return this.constructor.name || 'Root'
  }

  get replace() {
    return false
  }

  component() {
    throw new Error('No component defined')
  }

  store() {
    return null
  }

  router() {
    return null
  }

  async mount() {
    const component = await this.component()
    const store = await this.store()
    const router = await this.router()

    if (store) {
      const initialState = {
        ...store.state,
        ...this.data,
      }

      this._store = createStore({
        ...addNamespace(store),
        state: initialState,
      })
    }

    if (router) {
      this._router = router

      // makes router available as this.$router in actions and mutations
      if (this._store) {
        this._store.$router = this._router
      }
    }

    return (this._app = this.mountComponent(this.selector, component.default || component))
  }

  mountComponent(selector, component) {
    const props = Object.assign({}, this.data)
    const options = {
      name: this.name,
      render: () => h(component, props),
    }

    const app = createApp(options)

    Object.entries(props).forEach(([key, value]) => {
      app.provide(key, value)
    })

    if (this._store) app.use(this._store)
    if (this._router) app.use(this._router)

    app.use(VuexPlugin)
    app.use(createPinia())

    if (this.replace) {
      const fragment = document.createDocumentFragment()
      app.mount(fragment)
      document.querySelector(selector).replaceWith(fragment)

      return app
    }

    app.mount(selector)

    return app
  }
}

/**
 * Add namespace to all submodules.
 */
export function addNamespace(store) {
  if (typeof store.namespaced === 'undefined') {
    store.namespaced = true
  }

  for (const key in store.modules) {
    addNamespace(store.modules[key])
  }

  return store
}
