<script setup lang="ts">
import {
  computed,
  ref,
  provide,
  useSlots,
  watch,
  ComputedRef,
  reactive,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from 'vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlProgressBar from '@component/mdl/ProgressBar.vue'
import MdlTab from './Tab.vue'
import type { TabValue } from './Tab'

defineOptions({
  name: 'MdlTabs',
})

type Tab = {
  index?: string | number
  value: TabValue
  label: string
  modified: boolean
  disabled: boolean
  badge?: number
}
type TabKey = Tab['value']
type TabRef = ComputedRef<Tab>

const props = defineProps<{
  loading?: boolean | TabValue
  fixed?: boolean
}>()

const emit = defineEmits<{
  select: (value: TabKey) => void
}>()

const refs = reactive<{
  tabBar: HTMLDivElement
  arrowBack: HTMLDivElement
  arrowForward: HTMLDivElement
}>({})

const slots = useSlots()
const tabsData = ref<Record<TabKey, TabRef[]>>({})
const tabsIndex = ref<Record<TabKey, string>>({})
const tabsOrder = computed(() => {
  return Object.keys(tabsIndex.value).sort((a, b) => tabsIndex.value[a].localeCompare(tabsIndex.value[b]))
})
const tabs = computed<Tab[]>(() => tabsOrder.value.map((value) => tabsData.value[value]).filter(Boolean))
const currentTab = defineModel<TabValue>()

const overflow = reactive({
  scrolling: false,
  scrollDelay: 250,
  scrollTimer: 0,
  scrollMax: 0,
  scroll: {
    smooth: false,
    pos: 0,
  },
  drag: false,
  observer: null,
})

const scrollBackVisible = computed(() => overflow.scrollMax > 0 && overflow.scroll.pos > 0)
const scrollForwardVisible = computed(() => overflow.scrollMax > 0 && overflow.scroll.pos < overflow.scrollMax)

provide('currentTab', currentTab)
provide('registerTab', registerTab)
provide('unregisterTab', unregisterTab)

function registerTab(tab: TabRef) {
  const { value, index } = tab.value
  tabsData.value[value] = tab
  tabsIndex.value[value] = `${index || `${Object.entries(tabsIndex.value).length}_${value}`}`
}

function unregisterTab(value: TabKey) {
  delete tabsData.value[value]
  delete tabsIndex.value[value]
}

function classes(tab: Tab) {
  return {
    'is-active': tab.value === currentTab.value,
    'is-loading': isLoading(tab.value),
    'is-modified': tab.modified,
    'is-disabled': tab.disabled,
  }
}

function isLoading(value: TabValue): boolean {
  if (typeof props.loading === 'boolean') {
    return props.loading && currentTab.value === value
  }

  return props.loading && props.loading === value
}

function select(tab?: Tab) {
  if (!tab || tab.disabled) {
    return
  }

  if (tab.value !== currentTab.value) {
    currentTab.value = tab.value
    emit('select', currentTab.value)

    nextTick(handleResize)
  }
}

function offsets() {
  const bar = refs.tabBar
  const margin = refs.arrowBack?.clientWidth || refs.arrowForward?.clientWidth || 0

  return {
    margin,
    start: bar.scrollLeft,
    end: bar.scrollLeft + bar.clientWidth,
  }
}

function handleResize() {
  const bar = refs.tabBar

  if (!bar) {
    return
  }

  overflow.scrollMax = bar.scrollWidth - bar.clientWidth

  nextTick(() => {
    scrollToElement(bar.querySelector('.mdl-tabs__tab.is-active'))
  })
}

function scrollToElement(element) {
  if (!element) {
    return
  }

  let pos = overflow.scroll.pos
  const { margin, start, end } = offsets()
  const edgeThreshold = 25

  const tabStart = element.offsetLeft
  const tabEnd = tabStart + element.clientWidth

  if (start > tabStart - margin) {
    pos = tabStart - margin * 1.5
  } else if (end < tabEnd + margin) {
    pos = start + tabEnd - end + margin * 1.5
  }

  if (pos < edgeThreshold) pos = 0
  if (pos > overflow.scrollMax - edgeThreshold) pos = overflow.scrollMax

  overflow.scroll = {
    smooth: true,
    pos,
  }
}

function getTabOnPosition(pos) {
  const bar = refs.tabBar
  const tabs = Array.from(bar.querySelectorAll('.mdl-tabs__tab'))

  return tabs.find((tab) => {
    const tabStart = tab.offsetLeft
    const tabEnd = tabStart + tab.clientWidth

    return tabStart <= pos && tabEnd >= pos
  })
}

function startScroll(forward = false) {
  if (overflow.scrolling) {
    return
  }
  overflow.scrollTimer = setTimeout(() => {
    clearInterval(overflow.scrollTimer)
    overflow.scrolling = true
    overflow.scrollTimer = setInterval(() => {
      if (scrollElement(forward)) {
        stopScroll(forward)
      }
    }, 5)
  }, overflow.scrollDelay)
}

function stopScroll(forward = false) {
  clearInterval(overflow.scrollTimer)
  if (overflow.scrolling) {
    overflow.scrolling = false
  } else {
    const { start, end, margin } = offsets()
    const tab = getTabOnPosition(forward ? end - margin : start + margin)
    scrollToElement(tab)
  }
}

function scrollElement(forward = false) {
  const direction = forward ? 1 : -1
  let pos = overflow.scroll.pos + direction * 5
  let done = false

  if (pos < 0) {
    pos = 0
    done = true
  } else if (pos > overflow.scrollMax) {
    pos = overflow.scrollMax
    done = true
  }

  overflow.scroll = {
    smooth: false,
    pos,
  }

  return done
}

watch(
  currentTab,
  (value) => {
    if (!tabsOrder.value.length) {
      return
    }

    if (value && value !== currentTab.value) {
      select(tabsData.value[value])
    }
  },
  { immediate: true },
)

watch(
  () => overflow.scroll,
  ({ smooth, pos }) => {
    refs.tabBar.scrollTo({ left: pos, behavior: smooth ? 'smooth' : 'auto' })
  },
)

onMounted(() => {
  nextTick(() => {
    const observer = new ResizeObserver(handleResize)
    observer.observe(refs.tabBar)
    overflow.observer = observer
  })

  if (!currentTab.value) {
    currentTab.value = tabs.value.find((tab) => !tab.disabled)?.value
  }
})

onBeforeUnmount(() => {
  if (overflow.observer) {
    overflow.observer.disconnect()
  }
})
</script>

<template>
  <div class="mdl-tabs is-upgraded" :class="{ 'mdl-tabs--fixed': fixed }">
    <div
      :ref="(el) => (refs.arrowBack = el)"
      v-if="scrollBackVisible"
      class="mdl-tabs__arrow mdl-tabs__arrow--back"
      @mousedown.left="startScroll(false)"
      @mouseup="stopScroll(false)"
      @touchstart.prevent="startScroll(false)"
      @touchend.prevent="stopScroll(false)"
    >
      <MdlButton icon="chevron_left" />
    </div>
    <div
      :ref="(el) => (refs.arrowForward = el)"
      v-if="scrollForwardVisible"
      class="mdl-tabs__arrow mdl-tabs__arrow--forward"
      @mousedown.left="startScroll(true)"
      @mouseup="stopScroll(true)"
      @touchstart.prevent="startScroll(true)"
      @touchend.prevent="stopScroll(true)"
    >
      <MdlButton icon="chevron_right" />
    </div>

    <div class="mdl-tabs__tab-bar" :ref="(el) => (refs.tabBar = el)">
      <button
        v-for="tab in tabs"
        :key="tab.value"
        type="button"
        class="mdl-tabs__tab"
        :class="classes(tab)"
        @click="select(tab)"
      >
        <span>{{ tab.label }}</span>
        <span v-if="tab.modified" class="mdl-tabs__tab-dot" />
        <span
          v-if="tab.badge"
          class="mdl-tabs__tab-badge mdl-badge"
          :data-badge="typeof tab.badge === 'number' && tab.badge > 99 ? '99+' : tab.badge"
        />
        <MdlProgressBar v-if="isLoading(tab.value)" indeterminate height="2" />
      </button>
    </div>

    <slot></slot>
  </div>
</template>
