<template>
  <MdlListItem
    class="user-search__user"
    :class="classes"
    :title="user.fullname || user.email"
    :subtitle="user.company ? user.company.name : null"
    :icon="user.icon"
    :thumbnail="user.avatar?.url"
  >
    <!-- Show an input if the user has no email address -->
    <template v-if="user.noEmail && !user.placeholder" #actions>
      <input
        class="is-invalid"
        type="email"
        :id="`user-search-email-${user.id}`"
        :placeholder="trans('Enter email')"
        :value="user.email"
        @input="$emit('change:email', $event.target.value)"
      />
    </template>

    <!-- Remove action -->
    <template v-else-if="!user.placeholder" #actions>
      <MdlButton icon="close" @click="$emit('remove')" />
    </template>
  </MdlListItem>
</template>

<script>
import MdlListItem from '@component/mdl/ListItem.vue'
import MdlButton from '@component/mdl/Button.vue'

export default {
  components: {
    MdlListItem,
    MdlButton,
  },
  props: {
    user: { type: Object, required: true },
  },
  computed: {
    classes() {
      return { 'is-placeholder': this.user.placeholder }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@style/variables';

input {
  height: auto;
  font-size: 0.9em;
  padding: 2px 5px;

  &::placeholder {
    font-size: 0.9em;
  }

  &.is-invalid {
    border-color: $munio-red;
  }
}

:deep(.is-placeholder) {
  background-color: rgba(#000, 0.12);
  outline: 2px solid white;

  .mdl-list__item-avatar {
    background-color: rgba(#000, 0.12);
  }

  .mdl-list__item-primary-content {
    color: rgba(#000, 0.54);
  }
}
</style>
