import Wrapper from '@app/Wrapper.js'

Munio.ShopProductManager = class extends Wrapper {
  constructor(selector, shop) {
    super(selector, {
      shop,
    })
  }

  get name() {
    return 'ShopProductManager'
  }

  component() {
    return import('./App.vue')
  }
}
