<template>
  <div>
    <template v-if="locations.length">
      <MdlTabs fixed>
        <MdlTab
          v-for="entry in locations"
          :key="entry.location.id"
          :value="`location-${entry.location.id}`"
          :label="entry.location.name"
        >
          <div class="p-8">
            <a :href="entry.location.url" target="_blank">
              <Icon class="small" name="place" />
              {{ entry.location.address }}
            </a>
          </div>

          <SessionNode
            v-for="session in entry.sessions"
            :key="session.id"
            :session="session"
            :can-enroll="canEnroll"
            @enrolled="$emit('enrolled', session)"
          />
        </MdlTab>
      </MdlTabs>
    </template>

    <div class="text-danger text-center p-8" v-else>
      {{ trans('There are no enrollable sessions at the moment.') }}
    </div>

    <slot />
  </div>
</template>

<script>
import { trans } from '@/munio/i18n'
import Icon from '@component/Icon.vue'
import MdlTabs from '@component/mdl/Tabs.vue'
import MdlTab from '@component/mdl/Tab.vue'
import SessionNode from './SessionNode.vue'

export default {
  components: {
    Icon,
    MdlTabs,
    MdlTab,
    SessionNode,
  },

  props: {
    sessions: {
      type: Array,
      default: () => [],
    },
    canEnroll: { type: Boolean, default: true },
  },

  computed: {
    locations() {
      return Object.values(
        this.sessions.reduce((result, current) => {
          const locationId = current.location.id

          if (locationId in result) result[locationId].sessions.push(current)
          else {
            result[locationId] = {
              location: current.location,
              sessions: [current],
            }
          }

          return result
        }, {}),
      )
    },
  },

  methods: {
    trans,
  },
}
</script>
