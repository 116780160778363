<template>
  <details class="mdl-details" :open="isOpen">
    <summary ref="summary" @click.prevent="onToggle">{{ summary }}</summary>
    <div ref="content" :class="{ flip: flip && isOpen }">
      <slot />
    </div>
  </details>
</template>

<script setup lang="ts">
defineOptions({
  name: 'MdlDetails',
})

defineProps<{
  summary: string
  flip?: boolean
}>()

const emit = defineEmits<{
  toggle: (open: boolean) => void
}>()

const isOpen = defineModel<boolean>()

function onToggle() {
  isOpen.value = !isOpen.value
  emit('toggle', isOpen.value)
}
</script>
