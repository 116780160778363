import Wrapper from '@app/Wrapper.js'

Munio.UserSettings = class extends Wrapper {
  constructor(selector) {
    super(selector, {})
  }

  component() {
    return import('./App.vue')
  }
}
