<template>
  <div>
    <MdlProgressBar :indeterminate="true" v-if="fetching" />

    <div class="flex items-center p-8" style="border-top: 1px solid #eaeaea">
      <div
        class="avatar flex items-center justify-center mdl-color--default mdl-color-text--default-contrast"
        style="margin-right: 16px"
      >
        <Icon name="event" />
      </div>

      <div>
        <strong>{{ session.from.datetimeName }}</strong>
        <p>
          <span>
            <Icon class="small opaque" name="language" />
            {{ language }}
          </span>
          <span class="ml-4">
            <Icon class="small opaque" name="timelapse" />
            {{ session.duration.human }}
          </span>
        </p>
      </div>

      <MdlButton raised :disabled="fetching || !canEnroll" v-confirm="enroll" style="margin-left: auto">
        {{ trans('Enroll') }}
      </MdlButton>
    </div>
  </div>
</template>

<script>
import { trans } from '@/munio/i18n'
import Confirm from '@directive/confirm.js'
import Icon from '@component/Icon.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlProgressBar from '@component/mdl/ProgressBar.vue'

export default {
  directives: {
    Confirm,
  },

  components: {
    Icon,
    MdlButton,
    MdlProgressBar,
  },

  props: {
    session: Object,
    canEnroll: { type: Boolean, default: true },
  },

  emits: ['enrolled'],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    language() {
      return Munio.language(this.session.language).name
    },
  },

  methods: {
    trans,
    async enroll() {
      this.fetching = true
      await Munio.api.currentUser.sessions.enroll(this.session.id)
      this.$emit('enrolled', this.session)
      this.fetching = false
    },
  },
}
</script>
