import { h } from 'vue'
import $ from 'jquery'
import { createMunioApp } from '@app'
import CompanySelector from '@app/CompanySelector/index.vue'

Munio.behaviors.companySelector = {
  selector: '#header .company-selector',
  attach: function (context) {
    $(this.selector, context).each(function (i, el) {
      createMunioApp({
        name: 'CompanySelector',
        render: function () {
          return h(CompanySelector)
        },
      }).mount(el)
    })
  },
}
