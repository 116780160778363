import { trans } from '@/munio/i18n'
import Login from './Login.vue'
import Registration from './Registration.vue'
import Recover from './Recover.vue'
import Reset from './Reset.vue'
import UserInvite from './UserInvite.vue'

export default [
  {
    path: '/',
    redirect: '/login',
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      title: trans('Log in'),
    },
  },
  {
    name: 'recover',
    path: '/login/recover',
    component: Recover,
    meta: {
      title: trans('Forgot password'),
    },
  },
  {
    name: 'reset',
    path: '/login/reset/:token',
    component: Reset,
    meta: {
      title: trans('Create new password'),
    },
  },
  {
    name: 'registration',
    path: '/registration',
    component: Registration,
    meta: {
      title: trans('Create account'),
    },
  },
  {
    name: 'invite',
    path: '/registration/:invite',
    component: Login,
    meta: {
      title: trans('Log in'),
    },
  },
  {
    name: 'invite.user',
    path: '/invite/user/:invite',
    component: UserInvite,
    meta: {
      title: trans('Invitation'),
    },
  },
  {
    name: 'invite.user.registration',
    path: '/invite/user/:invite/registration',
    component: Registration,
    meta: {
      title: trans('Create account'),
    },
  },
  {
    name: 'invite.user.login',
    path: '/invite/user/:invite/login',
    component: Login,
    meta: {
      title: trans('Log in'),
    },
  },
]
