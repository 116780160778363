<script setup lang="ts">
import { computed, provide, ref, useSlots, watch } from 'vue'
import { isEqual, difference, differenceWith } from 'lodash'
import { trans, transChoice } from '@/munio/i18n/index.js'
import useScrollPan from '@composable/useScrollPan'
import useTableEnhancement from '@composable/useTableEnhancement'
import useSlotCheck from '@composable/useSlotCheck.ts'

defineOptions({
  name: 'MdlTable',
})

export type RowValue = string | number

export type Row = {
  value?: RowValue
  selectable?: boolean
  modified?: boolean
  name?: string
  valign?: 'baseline' | 'top' | 'middle' | 'bottom'
}

const props = withDefaults(
  defineProps<{
    loading?: boolean
    selectable?: boolean
    selectionLabel?: string
    title?: string | null
    modelValue?: RowValue[]
  }>(),
  {
    loading: false,
    selectable: false,
    selectionLabel: ':num selected',
    title: null,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: RowValue[]): void
}>()

const selected = ref<RowValue[]>([])

watch(
  () => props.modelValue,
  (value) => {
    selected.value = [...value]
  },
)

function update() {
  emit('update:modelValue', selected.value)
}

const slots = useSlots()
const hasSelectionSlot = useSlotCheck(slots.selection)
const hasToolbarSlot = useSlotCheck(slots.toolbar)
const rows = ref<Row[]>([])
const $table = ref()
const $thead = computed(() => $table.value?.querySelector('thead'))
const $tableWrap = ref()
const numSelected = computed(() => selected.value.length)
const showCaption = computed(() => {
  return selected.value.length > 0 || !!props.title || hasSelectionSlot.value || hasToolbarSlot.value
})
const { hasScrollLeft, hasScrollRight } = useTableEnhancement($tableWrap, $thead)

function addRow(row: Row) {
  rows.value.push(row)
}

function removeRow(row: Row) {
  const index = rows.value.findIndex((r) => r.value === row.value)

  if (index >= 0) {
    rows.value.splice(index, 1)
  }
}

function toggleRow(checked: boolean, value: RowValue) {
  if (checked) {
    selected.value.push(value)
  } else {
    const index = selected.value.findIndex((v) => v === value)

    if (index >= 0) {
      selected.value.splice(index, 1)
    }
  }

  update()
}

function toggleAll(checked: boolean, values: RowValue[]) {
  if (checked) {
    const diff = difference(values, selected.value)
    selected.value = [...selected.value, ...diff]
  } else {
    const diff = differenceWith(selected.value, values, isEqual)
    selected.value = diff
  }

  update()
}

function clearAll() {
  selected.value = []

  update()
}

useScrollPan(
  $tableWrap,
  computed(() => {
    return hasScrollLeft.value || hasScrollRight.value
  }),
)

function isRowSelected(value: RowValue) {
  return selected.value.includes(value)
}

provide('addRow', addRow)
provide('removeRow', removeRow)
provide('toggleRow', toggleRow)
provide('toggleAll', toggleAll)
provide(
  'hasSelectionEnabled',
  computed(() => props.selectable),
)
provide('isRowSelected', isRowSelected)
provide('rows', rows)
provide('selected', selected)
</script>

<template>
  <div
    class="mdl-data-table-container"
    :class="{
      'mdl-data-table--loading': loading,
      'mdl-data-table--selectable': selectable,
      'mdl-data-table--scroll-left': hasScrollLeft,
      'mdl-data-table--scroll-right': hasScrollRight,
    }"
  >
    <div ref="tableWrap" class="mdl-data-table-wrapper">
      <table ref="table" class="mdl-data-table mdl-js-data-table is-upgraded" data-upgraded="MaterialDataTable">
        <caption v-if="showCaption" :class="[{ 'is-active': numSelected > 0 }]">
          <div class="mdl-data-table__caption">
            <template v-if="numSelected">
              <div class="mdl-data-table__caption-text">
                {{ transChoice(selectionLabel, numSelected, { num: numSelected }) }}
                <a href="#" @click.prevent.stop="clearAll">{{ trans('Clear all') }}</a>
              </div>
              <div class="mdl-data-table__caption-actions">
                <slot name="selection" />
              </div>
            </template>
            <template v-else>
              <h3 v-if="title" class="mdl-data-table__caption-title">{{ title }}</h3>
              <div v-else-if="$slots.title" class="mdl-data-table__caption-title">
                <slot name="title" />
              </div>
              <div class="mdl-data-table__caption-toolbar">
                <slot name="toolbar" />
              </div>
            </template>
          </div>
        </caption>

        <slot name="default"></slot>
      </table>
    </div>
  </div>
</template>
