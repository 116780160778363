<template>
  <div>
    <h3 class="mt-0 flex flex-col">
      <span>{{ zone.name }}</span>
      <small v-if="gate.id != zone.id">{{ gate.name.default }}</small>
    </h3>

    <p v-if="image"><img :src="image.header" class="rounded" /></p>

    <slot />

    <h3 class="mdl-subheader">{{ trans('Information') }}</h3>
    <p>{{ trans('Start the clearance procedure by clicking the button below.') }}</p>
  </div>
</template>

<script>
import { trans } from '@/munio/i18n'

export default {
  props: {
    invite: { type: Object, required: true },
  },

  computed: {
    zone() {
      return this.invite.element
    },

    gate() {
      return this.zone.gate
    },

    image() {
      if (this.zone.image.exists) {
        return this.zone.image
      }

      if (this.zone.gate?.image?.exists) {
        return this.zone.gate.image
      }

      return null
    },

    imageName() {
      if (this.zone.image.exists) {
        return this.zone.name
      }

      if (this.zone.gate?.image?.exists) {
        return this.zone.gate.name
      }

      return this.zone.name
    },
  },

  methods: {
    trans,
  },
}
</script>
