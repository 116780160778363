<script lang="ts">
import { defineComponent, PropType } from 'vue'

const { key, valid } = window.Munio.config.captcha

export default defineComponent({
  name: 'Captcha',

  props: {
    sitekey: { type: String, default: () => key },
    autoReset: Boolean,
    resetTimeout: { type: Number, default: 295 * 1000 },
    recaptchaCompat: Boolean,
    explicitRender: Boolean,
    language: { type: String, default: 'auto' },
    appearance: {
      type: String as PropType<Appearance>,
      default: 'always',
    },
    theme: { type: String as PropType<Theme>, default: 'light' },
    size: { type: String as PropType<Size>, default: 'flexible' },
    modelValue: { type: String, default: null },
    always: Boolean,
  },

  emits: ['rendering', 'rendered', 'error', 'update:modelValue'],

  data() {
    return {
      token: null as string | null,
      widgetId: null as string | null,
    }
  },

  computed: {
    isEnabled() {
      if (!this.sitekey) {
        return false
      }

      return this.always || !valid
    },
  },

  methods: {
    initTurnstile() {
      if (!this.isEnabled) {
        return
      }

      const script: HTMLScriptElement = document.createElement('script')
      const turnstileSrc = 'https://challenges.cloudflare.com/turnstile/v0/api.js'
      const callback = 'onloadTurnstileCallback'
      const compat = this.recaptchaCompat ? '&compat=recaptcha' : ''
      const render = this.explicitRender ? '&render=explicit' : ''

      script.src = `${turnstileSrc}?onload=${callback}${compat}${render}`
      script.async = true
      script.defer = true

      document.head.appendChild(script)
    },

    reset() {
      if (window.turnstile && this.widgetId) {
        window.turnstile.reset(this.widgetId)
      }
    },

    remove() {
      if (window.turnstile && this.widgetId) {
        window.turnstile.remove(this.widgetId)
        this.widgetId = null
      }
    },

    render() {
      if (!this.isEnabled || !window.turnstile) {
        return
      }

      this.widgetId = window.turnstile.render('#cf-turnstile', {
        sitekey: this.sitekey,
        theme: this.theme,
        size: this.size,
        appearance: this.appearance,
        language: this.language,
        callback: (cfToken: string) => {
          this.token = cfToken
          this.$emit('update:modelValue', cfToken)

          if (this.autoReset) {
            setTimeout(() => {
              this.reset()
            }, this.resetTimeout)
          }
        },
        'expired-callback': (): void => {
          this.reset()
        },
        'error-callback': (error: unknown): void => {
          console.error(`Error callback: ${error}`)

          this.$emit('error', error)
        },
      })

      this.$emit('rendered')
    },
  },

  beforeMount() {
    if (window.turnstile === undefined || !window.turnstile) {
      this.initTurnstile()
    }
  },

  mounted() {
    this.$emit('rendering')

    if (window.turnstile === undefined || !window.turnstile) {
      window.onloadTurnstileCallback = () => {
        this.render()
      }
    } else {
      this.render()
    }
  },

  beforeUnmount() {
    this.remove()
  },

  watch: {
    modelValue: function (value: string | null) {
      this.token = value
      if (!this.modelValue) {
        this.reset()
      }
    },
  },
})

declare global {
  interface Window {
    turnstile?: {
      getResponse: () => Promise<string>
      render: (
        element: string | HTMLElement,
        options: {
          sitekey: string
          theme: string
          size: string
          appearance: string
          language: string
          callback: Function
          'expired-callback': Function | void
          'error-callback': Function | void
        },
      ) => string
      remove: (widgetId: string) => void
      reset: (widgetId: string) => void
    }
    onloadTurnstileCallback?: () => void
  }
}

type Appearance = 'always' | 'execute' | 'interaction-only'
type Size = 'compact' | 'normal' | 'flexible'
type Theme = 'light' | 'dark' | 'auto'
</script>

<template>
  <div v-if="isEnabled" id="cf-turnstile">
    <input v-if="token" type="hidden" name="captcha" :value="token" />
    <slot />
  </div>
</template>
