<template>
  <form
    ref="form"
    autocomplete="off"
    :class="wrapperClasses"
    @keydown.left="onFormNavigate"
    @keydown.right="onFormNavigate"
    @reset="onFormReset"
    @submit="onFormSubmit"
  >
    <MdlFilter
      v-for="filter of filtersActive"
      :key="filter.name"
      :data-filter="filter.name"
      :name="filter.name"
      :data-qa-id="pendoLabel(`filterBy.${filter.name}`)"
      @apply="onFilterApply"
      @remove="onFilterRemove"
    />

    <MdlFilterSearch @search="onSearchSubmit" />

    <div class="mdl-filters__reset">
      <MdlSpinner v-if="isLoading" />
      <MdlButton
        v-else-if="!isInitial"
        type="reset"
        v-tooltip="trans('Clear filters')"
        icon="filter_alt_off"
        small
        opaque
      />
    </div>

    <div class="spacer" />
  </form>
</template>

<script>
import { defineComponent } from 'vue'
import { Store, mapState, mapGetters } from 'vuex'
import $ from 'jquery'
import { trans } from '@/munio/i18n'
import { pendoLabel } from '@/munio/utils/index.js'
import tooltip from '@directive/tooltip.js'
import MdlFilter from './Filter.vue'
import MdlFilterSearch from './Search.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlSpinner from '@component/mdl/Spinner.vue'
import store from './store.js'

export default defineComponent({
  name: 'MdlFilters',

  components: {
    MdlButton,
    MdlFilter,
    MdlFilterSearch,
    MdlSpinner,
  },

  directives: {
    tooltip,
  },

  store() {
    return new Store(store)
  },

  props: {
    inline: Boolean,
    center: Boolean,
    loading: Boolean,
    submit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => Munio.state.filters,
    },
  },

  emits: ['update:filters', 'change', 'submit'],

  mounted() {
    this.$store.commit('INIT', { ...this.options, inline: this.inline })

    this.sync()

    if (!this.legacy && history?.pushState) {
      window.onpopstate = this.onHistoryChange
    }

    Munio.Events.$on('filters.refresh', this.onFiltersRefresh)
  },

  updated() {
    Munio.attachBehaviors(this.$el)
  },

  watch: {
    options: {
      handler(options) {
        this.$store.commit('INIT', { ...options, inline: this.inline })
      },
    },
  },

  data() {
    return {
      queryString: Munio.urlParams(),
      isSubmitting: false,
    }
  },

  computed: {
    ...mapState({
      store: (state) => state,
      legacy: (state) => state.legacy,
      filters: (state) => state.filters,
      form: (state) => state.form,
      searchFocused: (state) => state.form.searchFocus,
    }),
    ...mapGetters({
      getFilter: 'getFilter',
      filtersActive: 'getFiltersActive',
      filtersInactive: 'getFiltersInactive',
      filtersValues: 'getFiltersValues',
      isInitial: 'isInitial',
    }),

    wrapperClasses() {
      return [
        'mdl-filters',
        {
          'mdl-filters--center': this.center,
          'is-active': this.searchFocused,
          'is-editing': this.editing,
          'is-dirty': !this.isInitial,
          'has-input': !!this.input,
          'has-options': this.filtersInactive.length > 0,
        },
      ]
    },

    isLoading() {
      return this.loading || (this.legacy && this.isSubmitting)
    },

    editing: {
      get() {
        return this.form.filter !== null
      },
      set(value) {
        this.$store.commit('EDIT_FILTER', value)
      },
    },

    input: {
      get() {
        return this.form.search
      },
      set(value) {
        this.$store.commit('INPUT', value)
      },
    },
  },

  methods: {
    trans,
    pendoLabel,

    createQueryString() {
      const urlParams = Munio.urlParams()

      // grab active filter values
      const params = Munio.urlParams(this.filtersValues)

      // attach special params
      for (const k of ['rows', 'dir', 'sort']) {
        if (!params.has(k) && urlParams.has(k)) {
          params.set(k, urlParams.get(k))
        }
      }

      // let's not forget search
      if (this.form.search) {
        params.set('search', this.form.search)
      } else {
        params.delete('search')
      }

      return params
    },

    sync() {
      this.queryString = this.createQueryString()

      if (this.submit) {
        this.$emit('change', { ...this.form, queryString: this.queryString })
      }
    },

    filter(name, value) {
      if (value) {
        return this.setFilter(name, value)
      }

      return this.getFilter(name)
    },

    onSubmit() {
      if (this.legacy) {
        this.isSubmitting = true
      }

      this.$store.dispatch('submit')

      this.$nextTick(() => {
        if (this.legacy) {
          this.$refs.form.submit()
        } else {
          this.onFormSubmit()
        }
      })
    },

    onFilterApply() {
      this.onSubmit()
    },

    onFilterRemove() {
      this.onSubmit()
    },

    onFormReset() {
      this.$store.dispatch('reset')
      this.onSubmit()
    },

    onSearchSubmit() {
      this.onSubmit()
    },

    onFiltersRefresh(e) {
      this.$store.dispatch('restore', e.state)
      this.queryString = this.createQueryString()
    },

    onHistoryChange(e) {
      if (document.location.hash !== '') {
        return
      }

      if (this.legacy) {
        return
      }

      this.$store.dispatch('restore', e.state)

      this.$nextTick(() => {
        this.onFormSubmit(undefined, false)
      })
    },

    onFormSubmit(e, updateHistory = true) {
      if (this.legacy) {
        return
      }

      e?.preventDefault()

      this.sync()

      if (!this.inline) {
        if (updateHistory && history?.pushState) {
          const queryString = this.queryString.toString()
          const path = [location.pathname, queryString].filter((v) => v).join('?')

          history.pushState(queryString, '', path)
        }

        Munio.Events.$emit('filters.submit', this.queryString)
      }

      this.$emit('submit', this.queryString)
    },

    onFormNavigate(event) {
      if (this.searchFocused || this.form.filter) {
        return
      }

      switch (event.code) {
        case 'ArrowRight':
        case 'ArrowLeft':
          const $el = $(this.$el)
          const $items = $el.find('[tabindex="0"]')

          if (!$items.length) return

          let index = $items.index(event.target)
          if (event.code === 'ArrowLeft' && index > 0) index--
          if (event.code === 'ArrowRight' && index < $items.length - 1) index++
          if (!~index) index = 0

          $items.eq(index).trigger('focus')
          break
      }
    },
  },
})
</script>
