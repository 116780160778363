<script lang="ts">
import { defineComponent } from 'vue'
import { isSameDay } from 'date-fns/isSameDay'
import { isWithinInterval } from 'date-fns/isWithinInterval'
import Flatpickr from 'flatpickr'

// Events to emit, copied from flatpickr source
const events = {
  // 'change': 'onChange',
  open: 'onOpen',
  close: 'onClose',
  'change:month': 'onMonthChange',
  'change:year': 'onYearChange',
  ready: 'onReady',
  // 'input': 'onValueUpdate',
  // 'day:create': 'onDayCreate',
}

const options = {
  altFormat: String,
  altInput: { type: Boolean, default: undefined },
  altInputClass: String,
  allowInput: { type: Boolean, default: undefined },
  ariaDateFormat: String,
  clickOpens: { type: Boolean, default: undefined },
  dateFormat: String,
  defaultHour: Number,
  defaultMinute: Number,
  disable: Array,
  disableMobile: { type: Boolean, default: undefined },
  enable: Array,
  enableTime: { type: Boolean, default: undefined },
  enableSeconds: { type: Boolean, default: undefined },
  formatDate: Function,
  hourIncrement: Number,
  inline: Boolean,
  maxDate: String,
  minDate: String,
  minuteIncrement: Number,
  mode: { type: String, validator: (value: string) => ['single', 'multiple', 'range'].includes(value) },
  nextArrow: String,
  noCalendar: { type: Boolean, default: undefined },
  parseDate: Function,
  position: { type: String, validator: (value: string) => ['auto', 'above', 'below'].includes(value) },
  prevArrow: String,
  shorthandCurrentMonth: { type: Boolean, default: undefined },
  weekNumbers: { type: Boolean, default: undefined },
  wrap: { type: Boolean, default: undefined },
}

export default defineComponent({
  inheritAttrs: false,

  props: {
    modelValue: {
      default: null,
      required: false,
      validator: (value) => {
        return (
          value === null ||
          value instanceof Date ||
          value instanceof String ||
          value instanceof Array ||
          ['string', 'number'].includes(typeof value)
        )
      },
    },
    show: Boolean,
    disabled: Boolean,
    hidden: Boolean,
    ampm: Boolean,
    highlight: { type: Array, default: () => [] },
    highlightClass: String,
    ...options,
  },

  emits: [...Object.keys(events), 'blur', 'update:modelValue'],

  data() {
    return {
      instance: null as Flatpickr.Instance | null,
    }
  },

  computed: {
    config() {
      const conf = Object.keys(options).reduce(
        (acc, name) => {
          if (this.$props[name] === undefined) {
            return acc
          }

          acc[name] = this.$props[name]
          return acc
        },
        {} as Record<string, unknown>,
      )

      if (!this.ampm) {
        conf.time_24hr = true
      }

      if (this.highlight.length > 0) {
        conf.onDayCreate = function (dObj, dStr, fp, dayElem) {
          const highlighted = this.highlight.filter((d) => {
            const { dateObj } = dayElem
            if (Array.isArray(d)) {
              return isWithinInterval(dateObj, { start: d[0], end: d[1] })
            }
            return isSameDay(dateObj, d)
          })

          if (highlighted.length > 0) {
            dayElem.classList.add('highlighted')
            if (this.highlightClass) dayElem.classList.add(this.highlightClass)
          } else {
            dayElem.classList.remove('highlighted')
            if (this.highlightClass) dayElem.classList.remove(this.highlightClass)
          }
        }.bind(this)
      }

      return conf
    },

    element(): HTMLInputElement {
      return this.wrap ? this.$el.parentNode : this.$el
    },

    /**
     * @return HTMLInputElement
     */
    inputElement() {
      return this.instance?.altInput || this.instance?.input
    },
  },

  methods: {
    /**
     * Watch for value changed by date-picker itself and notify parent component
     *
     * @param event
     */
    onInput(event) {
      this.$nextTick(() => {
        this.$emit('update:modelValue', event.target.value)
      })
    },

    onChange(values, inputValue, instance) {
      if (Array.isArray(this.modelValue)) {
        values = values.map((date) => instance.formatDate(date, instance.config.dateFormat))
      } else {
        values = inputValue
      }

      this.$emit('update:modelValue', values)
    },

    /**
     * Blur event is required by many validation libraries
     *
     * @param event
     */
    onBlur(event) {
      this.$emit('blur', event.target.value)
    },

    /**
     * Watch for the disabled property and sets the value to the real input.
     *
     * @param newState
     */
    watchDisabled(newState) {
      if (!this.inputElement) {
        return
      }

      if (newState) {
        this.inputElement.setAttribute('disabled', newState)
      } else {
        this.inputElement.removeAttribute('disabled')
      }
    },

    watchShow(newState, oldState) {
      if (newState) {
        this.instance?.open()
      } else if (oldState) {
        this.instance?.close()
      }
    },
  },

  watch: {
    modelValue(newValue) {
      if (this.instance && newValue !== this.element.value) {
        this.instance.setDate(newValue, true, this.dateFormat)
      }
    },

    inline(value) {
      this.instance?.set('inline', value)
      this.instance?.redraw()
    },

    config(newConfig) {
      this.instance?.set(newConfig)
    },
  },

  mounted() {
    // Return early if flatpickr is already loaded
    if (this.instance) return

    const config = {
      ...this.config,
      static: true,
      appendTo: this.$el,
      defaultDate: this.modelValue,
      onChange: this.onChange,
    }

    // Emit all events
    Object.keys(events).forEach((name) => {
      const hook = events[name]
      config[hook] = (...args) => {
        this.$emit(name, ...args)
      }
    })

    // Init flatpickr
    this.instance = new Flatpickr(this.element, config)

    // Attach blur event
    if (this.inputElement) {
      this.inputElement.addEventListener('blur', this.onBlur)
    }

    // Immediate watch will fail before fp is set,
    // so need to start watching after mount
    this.$watch('disabled', this.watchDisabled, { immediate: true })
    this.$watch('show', this.watchShow, { immediate: true })
  },

  /**
   * Free up memory
   */
  beforeUnmount() {
    if (this.instance) {
      if (this.inputElement) {
        this.inputElement.removeEventListener('blur', this.onBlur)
      }

      this.instance.destroy()
      this.instance = null
    }
  },
})
</script>

<template>
  <input v-show="!hidden" type="text" />
</template>
