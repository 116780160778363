<template>
  <div :class="['toolbar', { 'is-hidden': hide }]">
    <div v-if="title" class="toolbar__title">
      <h4>{{ title }}</h4>
    </div>
    <div v-else-if="hasTitleSlot" class="toolbar__title">
      <slot name="title" />
    </div>

    <Filters
      v-if="filters"
      ref="filters"
      :loading="loading"
      :inline="inline"
      :options="options"
      :center="center"
      :submit="submit"
      @submit="$emit('submit', $event)"
      @change="$emit('change', $event)"
    />

    <div v-if="hasDefaultSlot || hasActionsSlot || showCSV" class="toolbar__actions">
      <slot name="actions" />
      <CSV
        v-if="showCSV"
        ref="csv"
        :loading="exporting"
        :disabled="loading || exporting"
        :csv="options?.csv"
        @export="onExport"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import useSlotCheck from '@composable/useSlotCheck'
import Filters from './Filters.vue'
import CSV from './CSV.vue'

export { Filters, CSV }

export default {
  inheritAttrs: false,

  name: 'Toolbar',

  components: {
    Filters,
    CSV,
  },

  props: {
    filters: [Boolean, Object],
    center: Boolean,
    exportUrl: String,
    inline: Boolean,
    hide: Boolean,
    loading: Boolean,
    submit: {
      type: Boolean,
      default: true,
    },
    title: String,
  },

  emits: ['change', 'submit'],

  setup(props, { slots }) {
    return {
      hasDefaultSlot: useSlotCheck(slots.default),
      hasTitleSlot: useSlotCheck(slots.title),
      hasActionsSlot: useSlotCheck(slots.actions),
    }
  },

  data() {
    return {
      exporting: false,
    }
  },

  computed: {
    options() {
      if (typeof this.filters === 'object') {
        return this.filters
      }

      return this.filters === true && !this.inline ? Munio.state.filters : null
    },

    showCSV() {
      if (this.exportUrl) {
        return true
      }

      return (this.options?.csv?.length ?? 0) > 0
    },
  },

  methods: {
    filter(name, value) {
      if (this.$refs.filters) {
        return this.$refs.filters.filter(name, value)
      }
    },
    async onExport(options) {
      try {
        const url =
          this.exportUrl || window.location.protocol + '//' + window.location.hostname + window.location.pathname
        const params = Munio.urlParamsString(this.$refs.filters?.queryString)
        this.exporting = true
        await Munio.downloadCSV(url, options, params)
      } catch (err) {
        console.error(err)
      } finally {
        this.exporting = false
      }
    },
  },
}
</script>
