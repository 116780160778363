<script setup lang="ts">
import { inject, computed, Ref } from 'vue'
import MdlCheckbox from '@component/mdl/Checkbox.vue'
import MdlProgressBar from '@component/mdl/ProgressBar.vue'
import type { Row } from './DataTable.vue'

defineOptions({
  name: 'MdlTableHeader',
})

const hasSelectionEnabled = inject('hasSelectionEnabled') as boolean
const toggleAll = inject('toggleAll') as Function
const isRowSelected = inject('isRowSelected') as Function

const rows = inject('rows') as Ref<Row[]>
const rowsSelectable = computed(() => rows.value.filter((row) => row.selectable))
const rowsSelectableValues = computed(() => rowsSelectable.value.map((row) => row.value))

const selectedVisible = computed(() =>
  hasSelectionEnabled ? rows.value.filter((row) => isRowSelected(row.value)).map((row) => row.value) : [],
)

const isSelectable = computed(() => hasSelectionEnabled && rowsSelectable.value.length > 0)
const isAllSelected = computed(() => isSelectable.value && selectedVisible.value.length === rowsSelectable.value.length)
const isIndeterminate = computed(() => isSelectable.value && selectedVisible.value.length > 0 && !isAllSelected.value)

function onToggle(checked: boolean) {
  toggleAll(checked, rowsSelectableValues.value)
}
</script>

<template>
  <thead>
    <tr class="mdl-data-table__progressbar">
      <td colspan="999">
        <MdlProgressBar indeterminate />
      </td>
    </tr>
    <tr>
      <th v-if="hasSelectionEnabled">
        <MdlCheckbox
          :checked="isAllSelected"
          :disabled="!isSelectable"
          :indeterminate="isIndeterminate"
          @update:checked="onToggle"
        />
      </th>
      <slot></slot>
    </tr>
  </thead>
</template>
