<template>
  <form v-if="username" class="flex flex-col flex-auto justify-between" @submit.prevent="reset">
    <Profile />

    <div>
      <MdlTextfieldPassword
        ref="password"
        class="block"
        type="password"
        v-model="password"
        autofocus
        :floatingLabel="trans('Password')"
        :error="passwordError"
        @error="(error) => (passwordError = error)"
      />

      <MdlTextfield
        ref="passwordRepeat"
        class="block"
        type="password"
        v-model="passwordRepeat"
        :error="passwordRepeatError"
        :floatingLabel="trans('Repeat password')"
      />
    </div>

    <div class="action-bar">
      <MdlButton type="submit" :raised="isValid" primary :disabled="!isValid || isLoading">
        {{ trans('Log in') }}
      </MdlButton>

      <MdlButton @click="cancel">{{ trans('Cancel') }}</MdlButton>
    </div>
  </form>

  <template v-else>
    <Alert type="danger">{{ trans('This link is not valid.') }}</Alert>

    <div class="action-bar">
      <MdlButton @click="cancel">
        {{ trans('Back') }}
      </MdlButton>
    </div>
  </template>
</template>

<script>
import { trans } from '@/munio/i18n'
import MdlButton from '@component/mdl/Button.vue'
import MdlTextfield from '@component/mdl/Textfield.vue'
import MdlTextfieldPassword from '@component/mdl/TextfieldPassword.vue'
import Profile from './Profile.vue'
import Alert from '@component/Alert.vue'

export default {
  components: {
    Alert,
    MdlButton,
    MdlTextfield,
    MdlTextfieldPassword,
    Profile,
  },

  data() {
    return {
      password: '',
      passwordError: null,
      passwordRepeat: '',
      passwordRepeatError: null,
    }
  },

  computed: {
    isLoading() {
      return this.$store.state.loading
    },

    isValid() {
      return !this.passwordError && this.password === this.passwordRepeat
    },

    resetToken() {
      return this.$store.state.resetToken || {}
    },

    username() {
      return this.resetToken.email || this.resetToken.number
    },
  },

  methods: {
    trans,

    cancel() {
      this.$store.commit('navigate', 'login')
    },

    reset() {
      if (this.isValid) {
        this.$store.dispatch('reset', this.password)
      }
    },
  },
}
</script>
