<script lang="ts">
export type TabValue = string | number
export type Props = {
  index?: string | number
  value: TabValue
  label: string
  disabled?: boolean
  modified?: boolean
  badge?: string | number
  body?: boolean
  persist?: boolean
}
</script>

<script setup lang="ts">
import { inject, onMounted, onBeforeUnmount, computed, watch, useSlots, nextTick } from 'vue'
import useSlotCheck from '@composable/useSlotCheck.ts'

defineOptions({
  name: 'MdlTab',
})

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  modified: false,
  body: false,
  persist: false,
})

const emit = defineEmits<{
  select: (value: TabValue) => void
}>()

const currentTab = inject('currentTab')
const registerTab = inject('registerTab')
const unregisterTab = inject('unregisterTab')
const active = computed(() => currentTab.value === props.value)
const hasContent = useSlotCheck(useSlots().default)

onMounted(() => {
  registerTab(
    computed(() => ({
      index: props.index,
      value: props.value,
      label: props.label,
      modified: props.modified,
      disabled: props.disabled,
      badge: props.badge,
    })),
  )
})

onBeforeUnmount(() => {
  unregisterTab(props.value)
})

watch(active, (value) => {
  if (value) {
    emit('select', props.value)
  }
})
</script>

<template>
  <div
    v-if="hasContent && (active || persist)"
    class="mdl-tabs__panel"
    :class="{ 'mdl-tabs__panel-body': body, 'is-active': active }"
  >
    <slot />
  </div>
</template>
