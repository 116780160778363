<script setup lang="ts">
import { defineComponent, ref } from 'vue'
import { trans } from '@/munio/i18n/index.js'
import MdlTextfield from './Textfield.vue'
import MdlButton from './Button.vue'

defineOptions({
  name: 'MdlFile',
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    name?: string
    file?: File | { name: string; size?: number; type?: string }
    outlined?: boolean
    opaque?: boolean
    accept?: string
    disabled?: boolean
    error?: boolean | string
  }>(),
  {
    outlined: true,
    opaque: true,
    disabled: false,
  },
)

const emit = defineEmits<{
  (e: 'change', file: File): void
}>()

const $file = ref<HTMLInputElement>()
const input = ref<File | undefined>()

function onSelect(event: InputEvent) {
  input.value = (event.target as HTMLInputElement).files?.[0]
  emit('change', input.value)
}
</script>

<template>
  <div class="mdl-file">
    <input
      ref="$file"
      type="file"
      :name="name"
      :accept="accept"
      :disabled="disabled"
      v-bind="$attrs"
      @change="onSelect"
    />
    <MdlButton v-bind="$attrs" :outlined="outlined" :opaque="opaque" :disabled="disabled" @click="$file.click()">
      {{ trans('Choose file') }}
    </MdlButton>
    <MdlTextfield
      readonly
      compact
      :model-value="input?.name || file?.name"
      :error="error"
      :disabled="disabled"
      :placeholder="trans('No file selected')"
      @click="$file.click()"
    />
  </div>
</template>
