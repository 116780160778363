<template>
  <Modal :show="showModal" size="xlarge" :title="modalTitle" @hidden="$emit('close')">
    <UserSearch
      ref="search"
      v-model="users"
      :search-handler="searchUsers"
      :submit-handler="addUsers"
      :submit-label="submitLabel"
      :limit-warning="limitWarning"
      :invite-message-placeholder="trans('Write a short message to the recipients.')"
      :invitable="!course.isIlt"
    >
      <template #actions>
        <MdlButton @click="showModal = false">
          {{ trans('Cancel') }}
        </MdlButton>
      </template>
    </UserSearch>

    <MdlProgressbar v-if="loading" :indeterminate="true" />
  </Modal>
</template>

<script>
import UserSearch from '@component/UserSearch/UserSearch.vue'
import MdlButton from '@component/mdl/Button.vue'
import { trans } from '@/munio/i18n/index.js'
import MdlProgressbar from '@component/mdl/ProgressBar.vue'
import Modal from '@component/Modal.vue'
import { userFullname } from '@/munio/utils/index.js'

export default {
  props: {
    id: { type: [String, Number], required: true },
    open: { type: Boolean, required: true },
  },

  emits: ['close'],

  components: {
    UserSearch,
    MdlButton,
    MdlProgressbar,
    Modal,
  },

  provide() {
    return {
      root: this,
    }
  },

  data() {
    return {
      showModal: this.open,
      loading: false,
      sessionId: 0,
      users: [],
    }
  },

  created() {
    if (Array.isArray(Munio.state.enrollable) && Munio.state.enrollable.length) {
      this.sessionId = Munio.state.enrollable[0].id
    }
  },

  computed: {
    api() {
      return Munio.api.companyCourse(this.id)
    },

    companyCourse() {
      return Munio.state.courseRelation
    },

    course() {
      return this.companyCourse.course
    },

    availableLicenses() {
      return this.companyCourse.licensesUnlimited
        ? Number.MAX_SAFE_INTEGER || 99999999
        : this.companyCourse.licensesAvailable
    },

    modalTitle() {
      return trans('Invite user(s)')
    },

    submitLabel() {
      return trans('Add')
    },

    limitWarning() {
      return trans('The number of users exceeds the number of available seats.')
    },
  },

  methods: {
    trans,

    async searchUsers(q) {
      const {
        data: { data, enrolled, invited },
      } = await this.api.users({ search: q })

      if (enrolled || invited) {
        for (const i in data) {
          const userId = data[i].id

          data[i].fullname = userFullname(data[i])

          if (enrolled && enrolled.indexOf(userId) >= 0) {
            data[i].excludeIcon = 'checked'
            data[i].excludeReason = trans('Already enrolled')
          } else if (invited && invited.indexOf(userId) >= 0) {
            data[i].excludeIcon = 'mail'
            data[i].excludeReason = trans('Already invited')
          }
        }
      }

      return data
    },

    async addUsers(users, comment) {
      this.loading = true

      for (const i in users) {
        const user = users[i]

        if (user.email && !user.id) {
          const { first, last } = Munio.parseName(user.fullname)
          user.firstname = first
          user.lastname = last
        }
      }

      try {
        await this.api.enroll(users, comment, this.sessionId || null)
        window.location.reload()
      } catch (err) {
        window.Munio.Flash.error(err.message)
      } finally {
        this.loading = false
      }
    },
  },

  watch: {
    open(val) {
      this.showModal = val
    },
  },
}
</script>
