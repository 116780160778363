<template>
  <div class="flex flex-col items-center text-center gap-y-12">
    {{ trans('Please enter the verification code we sent to your number') }}:

    <div class="flex flex-col items-center gap-y-4">
      <div class="flex w-fit gap-x-4">
        <input
          v-for="(_item, index) in items"
          :key="'otp_input' + index"
          :disabled="areInputsDisabled"
          :value="items[index]"
          :ref="(el) => (itemRefs[index] = el)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          pattern="[0-9]"
          class="w-[36px] flex font-black text-xl text-center focus:bg-gray-50"
          @focus="autoSelect"
          @keydown.tab.shift.exact.prevent="moveToPrevious(index)"
          @keydown.left.exact.prevent="moveToPrevious(index)"
          @keydown.tab.exact.prevent="moveToNext(index)"
          @keydown.right.exact.prevent="moveToNext(index)"
          @keyup="navigateItems($event, index)"
        />
      </div>

      <div class="flex gap-x-2" v-if="success !== true">
        <span v-if="hasTimerExpired" class="mdl-color-text--danger">{{ trans('Code has expired') }}</span>
        <div v-else class="flex gap-x-2">
          <span class="mdl-color-text--grey-400">{{ trans('Code expires in:') }}</span>
          <Timer
            ref="timer"
            :codeExpirationDate="codeExpirationDate"
            :colorClass="hasTimerExpired ? 'mdl-color-text--red-600' : 'mdl-color-text--grey-400'"
            @expired="hasTimerExpired = true"
          />
        </div>
      </div>

      <div v-if="success === true" class="text-success flex items-center gap-x-2">
        <Icon name="check_circle" />
        <span>{{ trans('The mobile number has been verified') }}</span>
      </div>

      <div v-if="success === false && !hasTimerExpired" class="text-danger flex items-start">
        <Icon name="error" />
        <span v-if="hasExceededMaxCodeAttempts">
          {{ trans('You have exceeded the maximum number of attempts!') }}
          <br />
          {{ transChoice('Please try again in :num minutes', retryCodeTimeout) }}
        </span>
        <span v-else>{{ trans('Invalid code') }}. {{ trans('Please try again') }}.</span>
      </div>
    </div>

    <MdlSpinner v-if="isCodeSubmitting" indeterminate />

    <div v-if="showResendCode" class="flex flex-col gap-2">
      <span>{{ trans('Did you not receive a code?') }}</span>

      <span class="mdl-color-text--primary font-bold cursor-pointer hover:underline" @click="resendCode">
        {{ trans('Resend') }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { trans, transChoice } from '@/munio/i18n/index.js'
import { nextTick, ref, onMounted, onUnmounted, watch, computed, watchEffect } from 'vue'

import Timer from './Timer.vue'
import Icon from '@component/Icon.vue'
import MdlSpinner from '@component/mdl/Spinner.vue'

const props = withDefaults(
  defineProps<{
    shown?: boolean
    codeExpirationDate: string
    digits?: number
    showResetButtonAfter?: number
    success?: boolean
    attempts?: number
    hasExceededMaxCodeAttempts: boolean
    retryCodeTimeout: number
  }>(),
  {
    shown: true,
    digits: 4,
    showResetButtonAfter: 0.5 * 60000,
  },
)

const emit = defineEmits<{
  (e: 'submitCode', code: string): void
  (e: 'resendCode'): void
}>()

//Extra
const isCodeSubmitting = ref(false)

//Inputs values
const items = ref<string[] | null[]>([])
const itemRefs = ref<HTMLInputElement[]>([])
const showResetButton = ref(false)
resetCodeInputs()

// Timer values
const timer: any = ref(null)
const hasTimerExpired = ref(false)

const valid = computed(() => {
  return items.value.every((item: string | null) => item?.match(/\d/))
})

const areInputsDisabled = computed(
  () => hasTimerExpired.value || isCodeSubmitting.value || props.hasExceededMaxCodeAttempts || props.success,
)

const showResendCode = computed(
  () =>
    (!props.hasExceededMaxCodeAttempts && props.codeExpirationDate && showResetButton.value && !props.success) ||
    hasTimerExpired.value,
)

watch(
  items,
  () => {
    if (valid.value && !hasTimerExpired.value && !props.hasExceededMaxCodeAttempts) {
      isCodeSubmitting.value = true
      setTimeout(() => {
        emit('submitCode', items.value.join(''))
        isCodeSubmitting.value = false
      }, 1000)
    }
  },
  { deep: true },
)

watch(
  () => props.attempts,
  () => resetCodeInputs(),
)

watch(
  () => props.shown,
  (value) => {
    if (value) {
      moveTo(0)
    }
  },
)

watchEffect(() => {
  if (props.hasExceededMaxCodeAttempts || props.success === true) {
    timer.value?.stopTimer()
  }
})

onMounted(() => {
  window.addEventListener('paste', handlePasteEvent)
  timer.value?.startTimer()
  showResendButtonInterval()
  moveTo(0)
})

onUnmounted(() => {
  window.removeEventListener('paste', handlePasteEvent)
})

function handlePasteEvent(event: any) {
  if (event.target.type === 'text') {
    let values = event.clipboardData.getData('Text').split('')
    items.value.forEach((_element: string | null, index: number) => {
      items.value[index] = values[index]
    })
  }
}

function navigateItems(event: KeyboardEvent, index: number) {
  const key = event.key
  const modifier = event.getModifierState(key)

  if (modifier) {
    return
  }

  if (key === 'Backspace' || key === 'Delete') {
    items.value[index] = null
    moveToPrevious(index)
    return
  }

  if (!key.match(/\d/)) {
    event.preventDefault()
    return
  }

  items.value[index] = key
  moveToNext(index)
}

function moveTo(index: number) {
  nextTick(() => {
    itemRefs.value[index]?.focus()
    itemRefs.value[index]?.select()
  })
}

function moveToNext(index: number) {
  if (index < props.digits - 1) {
    moveTo(index + 1)
  }
}

function moveToPrevious(index: number) {
  if (index > 0) {
    moveTo(index - 1)
  }
}

function autoSelect(event: Event) {
  const el = event.target as HTMLInputElement
  el.select()
}

// for reactivity purposes
function resetCodeInputs() {
  items.value = Array(props.digits).fill(null)

  moveTo(0)
}

function showResendButtonInterval() {
  setTimeout(() => {
    showResetButton.value = true
  }, props.showResetButtonAfter)
}

function resetTimer() {
  timer.value?.stopTimer()
  timer.value?.startTimer()

  showResetButton.value = false
  hasTimerExpired.value = false
  showResendButtonInterval()
}

function resendCode() {
  resetTimer()
  resetCodeInputs()
  emit('resendCode')
}
</script>
