import { mapArray } from '@/munio/utils'
import { trans } from '@/munio/i18n'

const state = {
  loading: false,
  requirements: {
    addable: [],
    list: [],
    map: {},
    loading: false,
    selected: null,
    processing: null,
  },
  certificates: {
    list: [],
    addable: [],
    loading: false,
  },
  responsibles: {
    list: [],
    meta: null,
    loading: false,
    selected: null,
    search: '',
  },
  admins: {
    list: [],
    map: {},
    meta: null,
    filters: undefined,
    searchOptions: undefined,
    roles: [],
    scopes: [],
    loading: false,
    processing: null,
    selected: null,
    showResponsiblesModal: false,
  },
}

const actions = {
  load(store, action) {
    switch (action) {
      case 'admins':
        return store.dispatch('fetchAdmins')
      case 'responsibles':
        return store.dispatch('fetchResponsibles')
      case 'requirements':
        return store.dispatch('fetchRequirements')
      case 'certificates':
        return store.dispatch('fetchCertificates')
    }
  },

  //
  // Settings
  //

  async updateSettings(store, data) {
    store.commit('SET_LOADING', true)
    const zoneId = store.rootState.selectedZoneId
    const {
      data: { data: updated },
    } = await Munio.api.access.zone(zoneId).update(data)
    store.dispatch('updateZone', updated, { root: true })
    store.commit('SET_LOADING', false)
  },

  //
  // Requirements
  //

  async fetchRequirements(store, callback) {
    store.commit('UPDATE_REQUIREMENTS', { data: { requirements: [], addable: [] } })
    store.commit('TOGGLE_REQUIREMENT', null)
    store.commit('LOADING_REQUIREMENTS', true)

    if (typeof callback !== 'function') {
      callback = async () => {
        return Munio.api.access.zone(store.rootState.selectedZoneId).requirements.get()
      }
    }

    const { data } = await callback()

    store.commit('UPDATE_REQUIREMENTS', data)
    store.commit('LOADING_REQUIREMENTS', false)
  },

  toggle(store, courseId) {
    store.commit('TOGGLE_REQUIREMENT', courseId)
  },

  async addCourse(store, courseId) {
    await store.dispatch('fetchRequirements', async () => {
      return Munio.api.access.zone(store.rootState.selectedZoneId).requirements.addCourse(courseId)
    })
  },

  async removeCourse(store, courseId) {
    store.commit('PROCESS_REQUIREMENT', courseId)
    await store.dispatch('fetchRequirements', async () => {
      return Munio.api.access.zone(store.rootState.selectedZoneId).requirements.removeCourse(courseId)
    })
    store.commit('PROCESS_REQUIREMENT', null)
  },

  async addCourseModule(store, { course: courseId, module: moduleId }) {
    store.commit('PROCESS_REQUIREMENT', courseId)
    await store.dispatch('fetchRequirements', async () => {
      return Munio.api.access.zone(store.rootState.selectedZoneId).requirements.addCourseModule(courseId, moduleId)
    })
    store.commit('PROCESS_REQUIREMENT', null)
  },

  async removeCourseModule(store, { course: courseId, module: moduleId }) {
    store.commit('PROCESS_REQUIREMENT', courseId)
    await store.dispatch('fetchRequirements', async () => {
      return Munio.api.access.zone(store.rootState.selectedZoneId).requirements.removeCourseModule(courseId, moduleId)
    })
    store.commit('PROCESS_REQUIREMENT', null)
  },

  //
  // Certificates
  //

  async fetchCertificates(store) {
    store.commit('UPDATE_CERTIFICATES', { data: [], addable: [] })
    store.commit('LOADING_CERTIFICATES', true)
    const { data } = await Munio.api.access.zone(store.rootState.selectedZoneId).certificates.get()
    store.commit('UPDATE_CERTIFICATES', data)
    store.commit('LOADING_CERTIFICATES', false)
  },

  async addCertificate(store, id) {
    try {
      await Munio.api.access.zone(store.rootState.selectedZoneId).certificates.add(id)
      await store.dispatch('fetchCertificates')
    } catch (err) {
      const msg = err.response?.data?.error?.message
      Munio.Flash.error(trans('Unable to add certificate'), trans(msg))
    }
  },

  async removeCertificate(store, id) {
    try {
      await Munio.api.access.zone(store.rootState.selectedZoneId).certificates.remove(id)
      await store.dispatch('fetchCertificates')
    } catch (err) {
      const msg = err.response?.data?.error?.message
      Munio.Flash.error(trans('Unable to remove certificate'), trans(msg))
    }
  },

  //
  // Responsibles
  //

  async fetchResponsibles(store, { search = undefined, page = 1 } = {}) {
    store.commit('LOADING_RESPONSIBLES', true)
    const { data } = await Munio.api.access.gate(store.rootState.gate.id).responsibles.get({
      zoneId: store.rootState.selectedZoneId,
      search: search || store.state.responsibles.search,
      page,
    })
    store.commit('UPDATE_RESPONSIBLES', data)
    store.commit('LOADING_RESPONSIBLES', false)
  },

  toggleResponsible(store, responsibleId) {
    store.commit('TOGGLE_RESPONSIBLE', responsibleId)
  },

  async addResponsible(store, company) {
    store.commit('LOADING_RESPONSIBLES', true)
    try {
      const {
        data: { data },
      } = await Munio.api.access.gate(store.rootState.selectedZoneId).responsibles.add(company)
      store.commit('TOGGLE_RESPONSIBLE', data.id)
      const responsibles = [data, ...store.state.responsibles.list]
      store.commit('UPDATE_RESPONSIBLES', { data: responsibles, meta: store.state.responsibles.meta })
    } catch (err) {
      Munio.Flash.error(trans('Unable to add company'))
    } finally {
      store.commit('LOADING_RESPONSIBLES', false)
    }
  },

  async searchResponsibles(store, query) {
    store.commit('UPDATE_RESPONSIBLES_SEARCH', query)
    store.dispatch('fetchResponsibles', { search: query })
  },

  async paginateResponsibles(store, page) {
    store.dispatch('fetchResponsibles', { page })
  },

  async updateResponsible(store, { responsible, data }) {
    try {
      store.commit('LOADING_RESPONSIBLES', true)
      await Munio.api.access.gate(store.rootState.selectedZoneId).responsibles.update(responsible.company.id, data)
      await store.dispatch('fetchResponsibles')
    } catch (err) {
      Munio.Flash.error(trans('Unable to update company'))
    } finally {
      store.commit('LOADING_RESPONSIBLES', false)
    }
  },

  async removeResponsible(store, responsible) {
    if (
      await Munio.confirm(trans('Are you sure about this?'), trans('This action is irreversible.'), {
        type: 'warning',
        confirmButtonText: trans('Remove'),
      })
    ) {
      store.commit('LOADING_RESPONSIBLES', true)
      await Munio.api.access.gate(store.rootState.selectedZoneId).responsibles.remove(responsible.company.id)
      await store.dispatch('fetchResponsibles', {
        search: store.state.responsibles.search,
        page: store.state.responsibles.meta?.current_page,
      })
      store.commit('LOADING_RESPONSIBLES', false)
    }
  },

  //
  // Admins
  //

  async fetchAdmins(store, options) {
    //store.commit('UPDATE_ADMINS', { data: [], roles: [], scopes: [] })
    //store.commit('UPDATE_ADMIN_SELECTED', null)
    store.commit('LOADING_ADMINS', true)
    store.commit('UPDATE_ADMINS_SEARCH', options)
    const { data } = await Munio.api.access.zone(store.rootState.selectedZoneId).admins.get(options)
    store.commit('UPDATE_ADMINS', data)
    store.commit('LOADING_ADMINS', false)
  },

  async addAdmins(store, users) {
    store.commit('LOADING_ADMINS', true)
    const { data } = await Munio.api.access.zone(store.rootState.selectedZoneId).admins.add(users)
    store.commit('UPDATE_ADMIN_SELECTED', users.length === 1 ? users[0] : undefined)
    store.commit('UPDATE_ADMINS', data)
    store.commit('LOADING_ADMINS', false)
  },

  async updateAdmin(store, fields) {
    const selected = store.getters.getAdmin(store.state.admins.selected)
    const userId = selected.user.id
    store.commit('PROCESS_ADMIN', userId)
    try {
      const {
        data: { data: admin },
      } = await Munio.api.access.zone(store.rootState.selectedZoneId).admins.update(userId, fields)
      const index = store.state.admins.map[userId]
      const copy = [...store.state.admins.list]
      copy[index] = admin
      store.state.admins.list = copy
    } catch (e) {
      console.error(e)
      Munio.Flash.error(trans('Unable to update'))
    } finally {
      store.commit('PROCESS_ADMIN', null)
    }
  },

  async removeAdmin(store) {
    const selected = store.getters.getAdmin(store.state.admins.selected)
    const userId = selected.user.id
    if (selected?.id && (await Munio.confirm())) {
      store.commit('LOADING_ADMINS', true)
      store.commit('UPDATE_ADMIN_SELECTED', null)
      try {
        await Munio.api.access.zone(store.rootState.selectedZoneId).admins.remove(userId)
        const { data } = await Munio.api.access.zone(store.rootState.selectedZoneId).admins.get()
        store.commit('UPDATE_ADMINS', data)
      } catch (e) {
        console.error(e)
        Munio.Flash.error(trans('Unable to remove'))
      } finally {
        store.commit('LOADING_ADMINS', false)
      }
    }
  },

  addAdminResponsible(store, company) {
    let responsibles = [...store.state.admins.selectedResponsibles]
    responsibles.push({ company: company })
    store.commit('UPDATE_ADMIN_SELECTED_RESPONSIBLES', responsibles)
  },

  removeAdminResponsible(store, companyId) {
    let responsibles = [...store.state.admins.selectedResponsibles]
    const index = responsibles.findIndex((r) => r.company.id === companyId)
    if (index !== -1) {
      responsibles = [...responsibles.slice(0, index), ...responsibles.slice(index + 1)]
    }
    store.commit('UPDATE_ADMIN_SELECTED_RESPONSIBLES', responsibles)
  },

  selectAdmin(store, admin) {
    const selected = state.admins.selected !== admin.user.id ? admin.user.id : null
    store.commit('UPDATE_ADMIN_SELECTED', selected)
  },

  //
  // Entrypoints
  //

  async saveEntrypoints(store, entrypointIds) {
    try {
      store.commit('SET_LOADING', true)
      const { data } = await Munio.api.access.zone(store.rootState.selectedZoneId).entrypoints.set(entrypointIds)
      await store.dispatch('getZones', null, { root: true })
      Munio.Events.$emit('integration.updated', data.integration)
    } catch (err) {
      console.error(err)
    } finally {
      store.commit('SET_LOADING', false)
    }
  },

  async deactivateIntegration(store) {
    if (!store.rootState.gate.integration) {
      throw new Error('No integration found')
    }

    try {
      store.commit('SET_LOADING', true)
      const { data } = await Munio.api.integration(store.rootState.gate.integration.id).changeMode('readonly')
      store.commit('SET_INTEGRATION', data.data, { root: true })
      Munio.Events.$emit('integration.updated', data.data)
    } catch (err) {
      console.error(err)
    } finally {
      store.commit('SET_LOADING', false)
    }
  },
}

const mutations = {
  RESET(state) {
    state.responsibles.list = []
    state.responsibles.meta = null
    state.responsibles.search = ''
    state.admins.list = []
    state.admins.map = {}
    state.requirements.list = []
    state.requirements.map = {}
    state.certificates.list = []
  },
  SET_ID(state, zoneId) {
    state.id = zoneId
  },
  LOADING_REQUIREMENTS(state, loading = true) {
    state.requirements.loading = loading
  },
  LOADING_CERTIFICATES(state, loading = true) {
    state.certificates.loading = loading
  },
  LOADING_RESPONSIBLES(state, loading = true) {
    state.responsibles.loading = loading
  },
  LOADING_ADMINS(state, loading = true) {
    state.admins.loading = loading
  },
  TOGGLE_REQUIREMENT(state, courseId) {
    state.requirements.selected = state.requirements.selected === courseId ? null : courseId
  },
  TOGGLE_RESPONSIBLE(state, responsibleId) {
    state.responsibles.selected = responsibleId !== state.responsibles.selected ? responsibleId : null
  },
  PROCESS_REQUIREMENT(state, courseId) {
    state.requirements.processing = courseId
  },
  PROCESS_ADMIN(state, userId) {
    state.admins.processing = userId
  },
  UPDATE_REQUIREMENTS(state, response) {
    const {
      data: { requirements, addable },
    } = response

    // let newZones = zones.filter(zone => ! state.zones.list.find(z => z.id === zone.id))
    // state.zones.list = [ ...state.zones.list, ...newZones ]
    // state.zones.map = mapArray(state.zones.list, zone => zone.id)

    state.requirements.list = requirements
    state.requirements.map = mapArray(requirements, (requirement) => requirement.id)
    state.requirements.addable = addable
  },
  UPDATE_CERTIFICATES(state, response) {
    state.certificates.list = response.data
    state.certificates.addable = response.addable
  },
  UPDATE_RESPONSIBLES(state, response) {
    state.responsibles.list = response.data
    state.responsibles.meta = response.meta
  },
  UPDATE_ADMINS(state, response) {
    state.admins.list = response.data
    state.admins.map = mapArray(state.admins.list, (admin) => admin.user.id)
    state.admins.filters = response.filters
    state.admins.meta = response.meta
    state.admins.roles = response.roles
    state.admins.scopes = response.scopes
  },
  UPDATE_ADMINS_SEARCH(state, searchOptions) {
    state.admins.searchOptions = searchOptions
  },
  UPDATE_ADMIN_SELECTED(state, admin) {
    state.admins.selected = admin
    state.admins.selectedResponsibles = admin?.responsibles ?? []
  },
  UPDATE_ADMIN_SELECTED_RESPONSIBLES(state, responsibles) {
    state.admins.selectedResponsibles = responsibles
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  UPDATE_RESPONSIBLES_SEARCH(state, query) {
    state.responsibles.search = query
  },
}

const getters = {
  getRequirement: (state) => (id) => {
    const index = state.requirements.map[id]

    return state.requirements.list[index]
  },

  getRequirementModules: (state, getters) => (id) => {
    const requirement = getters.getRequirement(id)

    return state.requirements.list.filter((r) => !r.isCourse && r.courseId === requirement.objectId)
  },

  getAdmin: (state) => (userId) => {
    const index = state.admins.map[userId]

    return state.admins.list[index]
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
