<script setup>
import { getCurrentInstance, onMounted } from 'vue'
import MdlSnackbar from '@component/mdl/Snackbar.vue'

onMounted(() => {
  window.Munio.Notifications.instance = getCurrentInstance()
})
</script>

<template>
  <MdlSnackbar ref="snackbar" />
</template>
