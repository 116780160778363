import { trans } from '@/munio/i18n'

const buttonClasses = ['mdl-button--raised', 'mdl-button--danger']

export default {
  mounted(el, binding) {
    el.handleClick = async (e) => {
      e.preventDefault()

      if (!el._confirm) {
        e.stopPropagation()

        el._confirm = true
        el._innerHtml = el.innerHTML
        el._classList = []

        for (let className of buttonClasses) {
          if (!el.classList.contains(className)) {
            el._classList.push(className)
          }
        }

        el.innerHTML = trans('Are you sure?')
        el.classList.add(...el._classList)
      } else {
        await binding.value()
        reset()
      }
    }

    el.clickAway = (e) => {
      if (el._confirm && e.target !== el && !el.contains(e.target)) {
        reset()
      }
    }

    function reset() {
      el.innerHTML = el._innerHtml
      el.classList.remove(...el._classList)
      el._confirm = false
    }

    el.addEventListener('click', el.handleClick)
    window.addEventListener('click', el.clickAway)
  },
  unmounted(el) {
    el.removeEventListener('click', el.handleClick)
    window.removeEventListener('click', el.clickAway)
  },
}
