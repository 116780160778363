import Wrapper from '@app/Wrapper.js'

Munio.ZoneStatus = class extends Wrapper {
  constructor(selector, zoneId, gateId) {
    super(selector, { zoneId, gateId })
  }

  get name() {
    return 'ZoneStatus'
  }

  component() {
    return import('./App.vue')
  }
}
