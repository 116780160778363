import Wrapper from '@app/Wrapper.js'

Munio.CompanyAccount = class extends Wrapper {
  constructor(selector, company) {
    super(selector, { company })
  }

  component() {
    return import('./App.vue')
  }
}
