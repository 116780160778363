<template>
  <div class="mdl-searchbar__result" :class="classes" @click="onClick">
    <div class="flex-grow">
      <div :class="{ opaque: disabled }">{{ company.name }}</div>
      <div class="opaque flex flex-col">
        <div v-if="company.address">
          <small>
            <strong>{{ trans('Address') }}:</strong>
            {{ formatAddress(company.address) }}
            <span v-if="showAddressCountry" class="flag" :title="countryName(company.address.country)">
              {{ flag(company.address.country) }}
            </span>
          </small>
        </div>

        <div v-if="company.orgnr || (!showAddressCountry && !country)">
          <small>
            <template v-if="company.orgnr">
              <strong class="select-none">{{ trans('Org.nr.') }}:</strong>
              <span class="select-all">{{ company.orgnr }}</span>
            </template>
            <template v-if="!showAddressCountry && !country">
              <span class="flag" :title="countryName(company.country)">
                {{ flag(company.country) }} {{ countryName(company.country) }}
              </span>
            </template>
          </small>
        </div>
      </div>
    </div>

    <Icon v-if="disabled" name="block" class="self-center" style="opacity: 0.25" />
    <Icon v-else-if="selected" name="done" />
    <img
      v-else-if="company.id && can('system')"
      :src="logoUrl"
      class="self-center w-[16px] h-[16px] opaque"
      style="margin-right: 3px"
      height="16"
      v-tooltip="trans('Registered with Munio' + ' (' + company.id + ')')"
    />
    <slot />
  </div>
</template>

<script>
import Icon from '@component/Icon.vue'
import vTooltip from '@directive/tooltip.js'

export default {
  name: 'Company',

  components: {
    Icon,
  },

  directives: {
    tooltip: vTooltip,
  },

  props: {
    company: { type: Object, required: true },
    country: { type: String },
    selectable: { type: Boolean },
    selected: { type: Boolean },
    disabled: { type: Boolean },
    logoUrl: { type: String, default: window.Munio.config.ecoonline.badge.black },
  },

  computed: {
    classes() {
      return {
        'is-selectable': this.selectable && !this.disabled,
        'is-selected': this.selected && !this.disabled,
        'is-disabled': this.disabled,
      }
    },
    showAddressCountry() {
      return this.company.address && this.company.address?.country !== this.company.country
    },
  },

  methods: {
    can: window.can,
    trans: window.trans,
    flag: window.Munio.flag,

    countryName(value) {
      return Munio.country(value).i18n
    },

    formatAddress(address) {
      const line1 = address.line1
      const line2 = address.line2
      const line3 = [address.postcode, address.city].filter((v) => v).join(' ')

      return [line1, line2, line3].filter((v) => v).join(', ')
    },

    onClick(e) {
      if (this.disabled) {
        return e.preventDefault()
      }

      this.$emit('click', e)
    },
  },
}
</script>
