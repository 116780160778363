import $ from 'jquery'

Munio.behaviors.tableSelect = {
  selector: 'table:not(.mdl-data-table)',
  attach: function (context) {
    $(this.selector, context).each(function (i, el) {
      // The main checkbox in table head.
      const $main = $('thead > tr > th:first-child input', el)

      // All other checkboxes.
      const $checkboxes = $('tbody > tr > td:first-child input', el)

      // Count all checked boxes. Uncheck and remove
      // minus if none is checked. Add minus icon and
      // check the main box if any boxes are checked.
      $checkboxes.on('change', function () {
        let checked = 0
        $checkboxes.each(function (i, checkbox) {
          if (checkbox.checked) checked++
        })
        $main.prop('checked', checked !== 0).toggleClass('minus', checked !== 0)
      })

      // Disable minus icon and uncheck all boxes if
      // the main box is unchecked. Check all boxes
      // if it gets checked.
      $main.on('change', function (event) {
        const target = event.target
        $main.prop('checked', target.checked === true).toggleClass('minus', target.checked === true)
        $checkboxes.each((i, checkbox) => {
          checkbox.checked = target.checked
        })
      })
    })
  },
}
