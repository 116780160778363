import mitt from 'mitt'
import { IntegrationResourceDto } from '@/munio/api/data'

const emitter = mitt<Events>()

window.Munio.Events = {
  $on: (...args) => emitter.on(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
}

export type Events = {
  'filters.submit': URLSearchParams
  'filters.refresh': { state: URLSearchParams }
  'csv.export': { id: string; delimiter: string; header: boolean }
  'integration.updated': IntegrationResourceDto
}

export interface EventBus {
  $on<K extends keyof Events>(type: K, handler: (event: Events[K]) => void): void

  $off<K extends keyof Events>(type: K, handler: (event: Events[K]) => void): void

  $emit<K extends keyof Events>(type: K, event: Events[K]): void
}

export default window.Munio.Events
