<script lang="jsx">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'vue-treeselect--tip',

  props: {
    type: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
  },

  render () {
    return (
      <div class={`vue-treeselect__tip vue-treeselect__${this.type}-tip`}>
        <div class={`icon.length ? 'vue-treeselect__icon-container' : ''`}>
          <span class={`vue-treeselect__icon-${this.icon}`} />
        </div>
        <span class={`vue-treeselect__tip-text vue-treeselect__${this.type}-tip-text`}>
          {this.$slots.default?.()}
        </span>
      </div>
    )
  },
})
</script>
