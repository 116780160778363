<template>
  <Modal v-bind="attrs" :show="show" :title="trans('Add zone')" :loading="loading">
    <template v-if="!loading" #body>
      <MdlSearchbar @submit="onSearch" />

      <div class="max-h-75vh overflow-y-auto mt-8">
        <div v-for="company of companies" :key="company.id" class="mb-8">
          <h4 class="mdl-subheader">{{ company.name }}</h4>
          <div class="flex flex-col gap-y-2">
            <div v-for="zone of company.zones" :key="zone.id">
              <MdlCheckbox name="zone" :value="zone.id" v-model:checked="selected">
                {{ zone.name }}
                <Icon v-if="zone.isRoot" name="account_tree" class="small opaque" v-tooltip="trans('Root')" />
                <Icon v-if="zone.isGate" name="login" class="small opaque" v-tooltip="trans('Access gate')" />
                <span v-if="zone.isTenant && zone.gate" class="mdl-checkbox__description">
                  {{ trans('Belongs to :company', { company: zone.gate.name }) }}
                </span>
              </MdlCheckbox>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!loading" #actions>
      <MdlButton :disabled="!selected.length" :raised="selected.length > 0" primary @click="$emit('add', selected)">
        {{ trans('Add') }}
      </MdlButton>
      <MdlButton @click="$emit('hide')">{{ trans('Cancel') }}</MdlButton>

      <div v-if="selected.length" class="mr-auto self-center ml-8">
        {{ trans('Number of selected') }}: {{ selected.length }}
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import { trans } from '@/munio/i18n/index.js'
import MdlCheckbox from '@component/mdl/Checkbox.vue'
import MdlSearchbar from '@component/mdl/Searchbar.vue'
import Modal from '@component/Modal.vue'
import MdlButton from '@component/mdl/Button.vue'
import Icon from '@component/Icon.vue'
import tooltip from '@directive/tooltip.js'

export default {
  components: { MdlCheckbox, MdlButton, MdlSearchbar, Modal, Icon },
  props: {
    company: { type: Object },
    added: { type: Array, default: () => [] },
    show: { type: Boolean },
    loading: { type: Boolean },
  },

  directives: { tooltip },

  data() {
    return {
      search: '',
      selected: [],
    }
  },

  computed: {
    attrs() {
      const { show, title, ...attrs } = this.$attrs
      return attrs
    },

    loadedZones() {
      return this.$store.state.zones?.data || []
    },

    zones() {
      let zones = [...this.loadedZones].filter((z) => !this.added.includes(z.id)).filter((z) => z.isEnrollable)

      if (this.company) {
        zones = zones.filter((z) => z.company.id === this.company.id)
      }

      if (this.search) {
        zones = zones.filter((z) => {
          if (this.selected.includes(z.id)) {
            return true
          }

          const searchable = z.name + z.company.name + z.gate?.name
          return searchable.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return zones
    },

    companies() {
      let companyMap = {}
      const sortedZones = [...this.zones].sort((a, b) =>
        (a.company.name + a.name).localeCompare(b.company.name + b.name, Munio.config.i18n.language),
      )

      return sortedZones.reduce(function (acc, zone) {
        let company = null

        if (companyMap[zone.company.id] === undefined) {
          company = {
            ...zone.company,
            zones: [],
          }

          companyMap[zone.company.id] = acc.push(company) - 1
        } else {
          company = acc[companyMap[zone.company.id]]
        }

        company.zones.push(zone)

        return acc
      }, [])
    },
  },

  methods: {
    trans,
    ...mapActions(['fetchZones']),

    onSearch(value) {
      this.search = value
    },

    onToggle(zone, checked) {
      if (checked) {
        this.selected.push(zone.id)
      } else {
        this.selected = this.selected.filter((id) => id !== zone.id)
      }
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.search = ''
        this.selected = []

        if (!this.loadedZones.length) {
          this.fetchZones()
        }
      }
    },
  },
}
</script>
