<template>
  <Modal v-if="gateCard" v-model:show="show" @hidden="onHidden" :loading="loading || processing">
    <template #header>
      <div class="flex flex-col gap-y-4">
        <h4 class="modal-title">{{ trans('Access card') }} #{{ gateCard.number }}</h4>
        <dl class="flex items-start gap-x-8 m-0">
          <div>
            <dt class="mdl-subheader m-0">{{ trans('Card type') }}</dt>
            <dd>
              <Icon :name="getTypeIcon(gateCard.type)" small />
              {{ getTypeName(gateCard.type) }}
            </dd>
          </div>
          <div v-if="gateCard.matchedAt">
            <dt class="mdl-subheader m-0">{{ trans('Matched at') }}</dt>
            <dd>
              <Date time short :value="gateCard.matchedAt" />
            </dd>
          </div>
          <div v-if="gateCard.matchedAt && gateCard.admin">
            <dt class="mdl-subheader m-0">{{ trans('Matched by') }}</dt>
            <dd>{{ gateCard.admin.fullname }}</dd>
          </div>
        </dl>
      </div>
    </template>

    <div class="flex items-end mdl-color--grey-200 h-[200px]">
      <img :src="avatar" alt="" class="h-full" />
      <div class="grid grid-cols-2 gap-x-4 gap-y-2 px-6 py-4 w-full">
        <div>
          <MdlTextfield :floating-label="trans('Firstname')" readonly :modelValue="gateCard.firstname || '–'" />
        </div>
        <div>
          <MdlTextfield :floating-label="trans('Lastname')" readonly :modelValue="gateCard.lastname || '–'" />
        </div>
        <div>
          <MdlTextfield :floating-label="trans('E-mail')" readonly :modelValue="gateCard.email || '–'" />
        </div>
        <div>
          <MdlTextfield
            :floating-label="trans('Mobile')"
            readonly
            :modelValue="gateCard.mobile ? formatPhone(gateCard.mobile) : '–'"
          />
        </div>
        <div class="col-span-2">
          <MdlTextfield :floating-label="trans('Employer')" readonly :modelValue="employer || '–'" />
        </div>
      </div>
    </div>

    <MdlTabs>
      <MdlTab value="settings" :label="trans('Settings')" body>
        <section v-if="gateCard.user">
          <h4 class="mdl-subheader mt-0">{{ trans('Connected user') }}</h4>
          <div class="flex items-center">
            <User v-if="gateCard.user" :user="gateCard.user" contact />

            <MdlButton
              raised
              icon="edit"
              small
              :disabled="edit"
              v-tooltip="trans('Edit user match')"
              @click="onClickEdit"
            />
            <MdlButton
              v-if="gateCard.user"
              danger
              raised
              icon="link_off"
              small
              :disabled="edit"
              v-tooltip="trans('Unmatch card')"
              @click="unmatch"
            />
          </div>
        </section>

        <section v-if="edit || gateCard.type !== 'person'">
          <h4 class="mdl-subheader mt-0">{{ trans('Select card type') }}</h4>
          <div class="flex flex-col">
            <div class="action-bar mt-0">
              <MdlButton @click="saveMatch" :disabled="processing || !isModified" primary raised>
                {{ trans('Save') }}
              </MdlButton>
              <MdlButton :disabled="processing" @click="cancelMatch">{{ trans('Cancel') }}</MdlButton>
              <div class="mr-auto w-48">
                <MunSelect :options="typeOptions" v-model="type" />
              </div>
            </div>

            <div v-if="type === 'person' && !processing" class="mt-8">
              <MdlSearchbar
                :label="trans('Search for user')"
                v-model="search.query"
                :searching="!!search.request"
                @submit="onSearchSubmit"
              >
                <template v-if="!(search.request || loading)">
                  <div v-if="!hasMatches" class="empty-state" style="background-image: url(/img/empty-state/users.svg)">
                    <span class="opaque">{{ trans('No users found.') }}</span>
                  </div>

                  <Candidate
                    v-else
                    v-for="candidate in matches"
                    :key="candidate.user.id"
                    :candidate="candidate"
                    :selected="isCandidateSelected(candidate)"
                    @select="selectUser"
                  />
                </template>
              </MdlSearchbar>
            </div>
          </div>
        </section>
      </MdlTab>

      <MdlTab value="entrypoints" :label="trans('Entrypoints')" body>
        <div class="mdl-chips">
          <div v-if="!gateCard.entrypoints" class="opaque">
            {{ trans('No entries') }}
          </div>
          <MdlChip v-else v-for="entrypoint of gateCard.entrypoints || []" :key="entrypoint.id">
            {{ entrypoint.name }}
          </MdlChip>
        </div>
      </MdlTab>

      <MdlTab value="integration" :label="trans('Integration log')" :disabled="true || !gateCard.integrationId">
        <ActionList
          v-if="gateCard.actions && gateCard.actions.length"
          :actions="gateCard.actions"
          :integration-id="gateCard.integrationId"
          hide-model
          live
        />
        <div v-else class="opaque">
          {{ trans('No entries') }}
        </div>
      </MdlTab>
    </MdlTabs>
  </Modal>
</template>

<script>
import { formatPhone } from '@/munio/utils/index.js'
import { toDateString } from '@/munio/utils/date.js'
import Candidate from './Candidate.vue'
import ActionList from '@component/IntegrationActions/index.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlChip from '@component/mdl/Chip.vue'
import Modal from '@component/Modal.vue'
import Date from '@component/Date.vue'
import MdlSearchbar from '@component/mdl/Searchbar.vue'
import MdlTab from '@component/mdl/Tab.vue'
import MdlTabs from '@component/mdl/Tabs.vue'
import MunSelect from '@component/Select.vue'
import Icon from '@component/Icon.vue'
import MdlTextfield from '@component/mdl/Textfield.vue'
import User from '@component/User.vue'
import { trans } from '@/munio/i18n/index.js'
import tooltip from '@directive/tooltip.js'

export const typeIcon = function (type) {
  switch (type) {
    case 'person':
      return 'person'
    case 'vehicle':
      return 'directions_car'
    case 'guest':
      return 'work_outline'
    default:
      return 'help_outline'
  }
}

export const typeName = function (type) {
  switch (type) {
    case 'person':
      return trans('Person')
    case 'vehicle':
      return trans('Vehicle')
    case 'guest':
      return trans('Guest')
    default:
      return trans('Unknown')
  }
}

export default {
  components: {
    Candidate,
    ActionList,
    MdlButton,
    MdlChip,
    Modal,
    MdlSearchbar,
    MdlTab,
    MdlTabs,
    MunSelect,
    User,
    Date,
    Icon,
    MdlTextfield,
  },

  directives: {
    tooltip,
  },

  props: {
    card: { type: Object, required: false },
  },

  model: {
    prop: 'card',
    event: 'update:card',
  },

  data() {
    return {
      loading: false,
      processing: false,
      data: null,
      user: null,
      edit: false,
      type: 'unknown',
      search: {
        query: '',
        request: null,
        result: null,
      },
      selected: {
        userId: null,
        action: null,
      },
    }
  },

  computed: {
    show: {
      get() {
        return !!this.card
      },
      set(value) {
        this.$emit('update:card', value ? this.card : null)
      },
    },

    typeOptions() {
      return ['person', 'vehicle', 'guest', 'unknown'].map((type) => ({
        id: type,
        label: this.getTypeName(type),
      }))
    },

    gateCard() {
      return this.data || this.card
    },

    matches() {
      if (this.search.result) {
        return this.search.result.data || []
      }

      return this.gateCard?.matches || []
    },

    hasMatches() {
      return this.matches.length > 0
    },

    avatar() {
      return this.gateCard.image?.large
    },

    employer() {
      return this.gateCard.employer?.name
    },

    typeColor() {
      if (!this.gateCard.matchedAt) {
        return undefined
      }
      return this.gateCard.type === 'person'
        ? 'mdl-color--success mdl-color-text--success-contrast'
        : 'mdl-color--info mdl-color-text--info-contrast'
    },

    isModified() {
      if (this.type !== this.gateCard.type) {
        return true
      }

      if (this.type === 'person') {
        return this.selected.userId !== (this.gateCard.user?.id || null)
      }

      return false
    },
  },

  methods: {
    formatPhone,
    toDateString,
    trans,

    getTypeIcon: typeIcon,
    getTypeName: typeName,

    async load() {
      this.loading = true
      try {
        const {
          data: { data },
        } = await Munio.api.access.gate(this.card.gateId).card(this.card.identifier).get()
        this.data = data
      } catch (err) {
        console.error(err)
        Munio.Flash.error('Could not load access card')
      } finally {
        this.loading = false
      }
    },

    setInitialState(card) {
      Object.assign(this.$data, {
        edit: card?.matchedAt || false,
        data: null,
        type: card?.type || 'unknown',
        user: null,
        search: {
          query: '',
          request: null,
          result: null,
        },
        selected: {
          userId: card?.user?.id || null,
          action: null,
        },
      })
    },

    isCandidateSelected(candidate) {
      return candidate.user.id === this.selected.userId
    },

    onClickEdit() {
      this.edit = true
      this.load()
    },

    onActionToggle(action) {
      this.selected.action = action
    },

    selectUser(id) {
      this.selected.userId = id === this.selected.userId ? null : id
    },

    async onSearchSubmit() {
      this.search.request && this.search.request.cancel()

      this.search.result = null

      if (this.search.query) {
        this.search.request = Munio.api.access
          .gate(this.gateCard.gateId)
          .card(this.gateCard.identifier)
          .search(this.search.query)
        this.search.request.then((response) => {
          this.search.result = response.data
          this.search.request = null
        })
      }
    },

    async saveMatch() {
      if (!(await Munio.confirm())) {
        return
      }

      try {
        this.processing = true
        const {
          data: { data },
        } = await Munio.api.access
          .gate(this.card.gateId)
          .card(this.card.identifier)
          .match(this.type, this.selected.userId)
        this.data = data
        this.edit = !data.matchedAt
        this.$emit('update:card', data)
      } catch (e) {
        console.error(e)
        Munio.Flash.error(trans('Something went wrong'))
      } finally {
        this.processing = false
      }
    },

    unmatch() {
      if (this.type === 'person') {
        this.selected.userId = null
        this.saveMatch()
      }
    },

    cancelMatch() {
      this.setInitialState(this.card)
      this.edit = !this.gateCard.matchedAt
    },

    onHidden() {
      this.setInitialState()
      this.show = false
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.load()
      }
    },
    card: {
      immediate: true,
      handler(value) {
        if (value) {
          this.setInitialState(value)
          this.data = value
          this.edit = !value.matchedAt
          if (this.edit) {
            this.load()
          }
        }
      },
    },
  },
}
</script>
