import $ from 'jquery'

/**
 * Header tabs
 *
 * Make tab link active when clicked.
 */
Munio.behaviors.headertabs = {
  selector: '.header-tabs',
  attach: function (context, config) {
    $(this.selector, context).each(function (i) {
      const $this = $(this)

      if (!$this.data('enabled')) {
        $('a[href^="#"]', $this).on('click', function (e) {
          e.preventDefault()

          const $tab = $(this),
            $current = $('a.is-active', $this)

          if (!$tab.hasClass('is-active')) {
            $current.removeClass('is-active')
            $($current.attr('href')).removeClass('is-active')
            $tab.addClass('is-active')
            $($tab.attr('href')).addClass('is-active')
          }
        })

        $this.data('enabled', true)
      }
    })
  },
}
