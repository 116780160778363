<template>
  <div>
    <Toolbar :filters="filters" :loading="loading === true" @submit="filters = $event">
      <MdlButton raised primary @click="onNew">{{ trans('New link') }}</MdlButton>
    </Toolbar>

    <MdlList>
      <AccessLink v-if="creating" key="creating" expanded />
      <AccessLink v-for="link of links.data" :key="link.uuid" :link="link" />

      <div class="action-bar">
        <MdlPaginator
          :rows-from="meta.from"
          :rows-to="meta.to"
          :rows-total="meta.total"
          :page-rows="meta.per_page"
          :page-current="meta.current_page"
          :page-last="meta.last_page"
          :loading="paginating"
          @goto="onPaginate"
        />
      </div>
    </MdlList>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { trans } from '@/munio/i18n/index.js'
import AccessLink from './AccessLink.vue'
import Toolbar from '@component/Toolbar/index.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlPaginator from '@component/mdl/Paginator.vue'
import MdlList from '@component/mdl/List.vue'

export default {
  name: 'AccessLinkManager',

  components: {
    AccessLink,
    Toolbar,
    MdlButton,
    MdlPaginator,
    MdlList,
  },

  props: {
    gateId: { type: Number, required: true },
  },

  computed: {
    ...mapState({
      loading: (state) => state.loading,
      paginating: (state) => state.paginating,
      creating: (state) => state.creating,
      links: (state) => state.links || {},
    }),

    filters: {
      get() {
        return Munio.state.filters
      },
      async set(queryString) {
        this.$store.dispatch('fetchAll', queryString)
      },
    },

    meta() {
      return this.links.meta || {}
    },
  },

  methods: {
    trans,
    onNew() {
      this.$store.commit('SET_CREATING', true)
    },
    onPaginate(page) {
      this.$store.dispatch('paginate', page)
    },
  },

  mounted() {
    this.$store.dispatch('init', this.gateId)
    this.$store.dispatch('fetchAll')
  },
}
</script>
