import Wrapper from '@app/Wrapper.js'

Munio.CourseManager = class extends Wrapper {
  constructor(selector, classifications, regions, categories) {
    super(selector, {
      classifications,
      regions,
      categories,
    })
  }

  component() {
    return import('./App.vue')
  }
}
