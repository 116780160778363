<script setup lang="ts">
import { ref } from 'vue'
import { clickOutside as vClickOutside, focusOutside as vFocusOutside } from '@directive/eventOutside'
import MdlButton from './Button.vue'
import Menu from '@component/Menu.vue'

withDefaults(
  defineProps<{
    primary?: boolean
    raised?: boolean
    opaque?: boolean
    disabled?: boolean
    loading?: boolean
    extraClasses?: string | string[] | Record<string, boolean>
  }>(),
  {
    primary: false,
    raised: false,
    opaque: true,
  },
)

const isDropdownVisible = ref(false)

function hideDropdown(): void {
  isDropdownVisible.value = false
}

function toggleDropdown(): void {
  isDropdownVisible.value = !isDropdownVisible.value
}
</script>

<template>
  <div class="mdl-button-group">
    <div v-click-outside="hideDropdown" v-focus-outside="hideDropdown">
      <MdlButton
        :class="extraClasses"
        :loading="loading"
        :primary="primary"
        :raise="raised"
        :opaque="opaque"
        :disabled="disabled"
        @click="toggleDropdown"
      >
        <slot name="button" />
      </MdlButton>

      <div class="dropdown" :class="{ open: isDropdownVisible }">
        <Menu right>
          <slot name="menu" />
        </Menu>
      </div>
    </div>
  </div>
</template>
