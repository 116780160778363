import { h } from 'vue'
import $ from 'jquery'
import LanguageSelector from '@component/LanguageSelector/index.vue'

/**
 * Language selector
 */
Munio.behaviors.panelLanguagesSelector = {
  selector: '.panel-languages',
  attach: function (context) {
    $(this.selector, context).each(function (i, el) {
      Munio.Vue(el, {
        render: function () {
          return h(
            LanguageSelector,
            JSON.parse(el.dataset.options || '{}') || {
              onlySiteLanguages: false,
              selected: [],
              delete: false,
            },
          )
        },
      })
    })
  },
}
