import { defineStore } from 'pinia'
import { reactive } from 'vue'

export const useRequirementsStore = defineStore('requirementsStore', () => {
  const state = reactive({
    loaded: false,
    zones: [],
    certificates: [],
    requirements: [],
    owner: null,
  })

  function setRequirements(payload) {
    state.requirements = payload
  }

  function setZones(payload) {
    const userZones = payload.filter((z) => z.user.isConnected)
    state.loaded = true
    state.zones = payload
    state.certificates = userZones.reduce(
      (a, b) =>
        a.concat(
          b.certificates.map((c) => {
            return {
              ...c,
              identifier: undefined,
              bornAt: undefined,
            }
          }),
        ),
      [],
    )
  }

  function setOwner(payload) {
    state.owner = payload
  }

  function setZoneLoadingState(payload) {
    const zone = state.zones.find((g) => g.id === payload.id)
    if (!zone) return

    zone.loading = payload.state
  }

  function setRequirementSession(payload) {
    const req = state.requirements.find((r) => r.id === payload.id)
    if (!req) return

    req.session = payload.session
  }

  return { state, setRequirements, setZones, setOwner, setZoneLoadingState, setRequirementSession }
})
