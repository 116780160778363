<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { omit } from 'lodash'
import { trans } from '@/munio/i18n/index.js'
import MdlProgressBar from '@component/mdl/ProgressBar.vue'
import MdlTextfield, { Props } from './Textfield.vue'
import Icon from '@component/Icon.vue'

type Requirement = {
  key: string
  regex: string
  label: string
  valid: boolean
}

defineOptions({
  name: 'MdlTextfieldPassword',
  inheritAttrs: false,
})

defineProps(omit(Props, 'type'))

const emit = defineEmits(['update:error'])

defineExpose({
  focus,
})

const password = defineModel()
const error = ref()
const input = ref()
const rules = window.Munio.config.auth.password.rules
const focused = ref(false)
const requirements = computed(() => {
  return rules.map((rule) => ({
    key: rule.key,
    regex: rule.regex,
    label: rule.label,
    valid: new RegExp(rule.regex).test(password.value ?? ''),
  }))
})
const valid = computed(() => {
  return requirements.value.every((req) => req.valid)
})
const score = computed(() => {
  let score = requirements.value.reduce((score, req) => {
    return score + (req.valid ? 1 : 0)
  }, 0)

  return Math.min(100, Math.round((score / rules.length) * 100))
})

function focus() {
  input.value.focus()
}

function handleFocusEnter() {
  focused.value = true
  updateError()
}

function handleFocusLeave() {
  focused.value = false
  updateError(error.value)
}

function updateError(errorMsg?: string | null = null) {
  emit('update:error', errorMsg)
}

function colorClass(requirement: Requirement) {
  return requirement.valid ? 'text-green-600 green-600' : 'text-gray-500 gray-500'
}

watch(password, () => {
  error.value = valid.value ? null : trans('Ensure the password conditions are met')
})
</script>

<template>
  <div class="mdl-textfield-password" :class="$attrs.class" :style="$attrs.style">
    <MdlTextfield
      ref="input"
      :floating-label="trans('Password')"
      v-bind="{ ...omit($attrs, 'class'), ...$props }"
      v-model="password"
      type="password"
      @focusin="handleFocusEnter"
      @focusout="handleFocusLeave"
      @keydown.enter="updateError(error)"
    >
      <div v-if="focused" class="mdl-textfield-password--rules">
        <div>{{ trans('Strength') }}: {{ score }}%</div>
        <MdlProgressBar :progress="score" :success="valid" class="my-2" />
        <div v-for="(requirement, index) in requirements" :key="index" class="flex items-center gap-x-1">
          <Icon small :name="requirement.valid ? 'check' : 'close'" :class="colorClass(requirement)" />
          <span :class="colorClass(requirement)">
            {{ requirement.label }}
          </span>
        </div>
      </div>
    </MdlTextfield>
  </div>
</template>
