<template>
  <form method="post" :action="route('lms.user.access.link.register')">
    <CSRF />

    <h3>{{ trans('Select zone(s)') }}:</h3>

    <div v-for="company of companies" :key="company.id">
      <h4 class="mdl-subheader mt-8">{{ company.name }}</h4>
      <div v-for="zone of company.zones" :key="zone.id">
        <MdlCheckbox name="zones[]" :value="zone.id" v-model:checked="selected">{{ zone.name }}</MdlCheckbox>
      </div>
    </div>

    <div class="form-actions">
      <MdlButton href="/" :disabled="submitting">{{ trans('Cancel') }}</MdlButton>
      <MdlButton :loading="submitting" :disabled="submitting || selected.length === 0" raised primary @click="onSubmit">
        {{ trans('Enroll') }}
      </MdlButton>
    </div>
  </form>
</template>

<script>
import CSRF from '@component/CSRF.vue'
import MdlCheckbox from '@component/mdl/Checkbox.vue'
import MdlButton from '@component/mdl/Button.vue'
import { sortZones, groupZonesByCompany } from './Manager/store.js'
import { trans } from '@/munio/i18n/index.js'

export default {
  components: { MdlCheckbox, CSRF, MdlButton },
  props: {
    link: { type: Object, required: true },
  },

  data() {
    return {
      submitting: false,
      selected: [],
    }
  },

  computed: {
    zones() {
      return sortZones(this.link.zones)
    },

    companies() {
      return groupZonesByCompany(this.zones)
    },
  },

  methods: {
    trans,
    route: window.Munio.route,
    onSubmit(e) {
      this.submitting = true
      this.$el.submit()
    },
  },
}
</script>
