import Wrapper from '@app/Wrapper.js'
import '@app/CourseBuilder/assets/course-styles.css'

Munio.CourseCreator = class extends Wrapper {
  get name() {
    return 'CourseCreator'
  }

  component() {
    return import('./Creator/index.vue')
  }
}

Munio.CourseEditor = class extends Wrapper {
  get name() {
    return 'CourseEditor'
  }

  component() {
    return import('./Editor/index.vue')
  }
}
