<template>
  <Modal :show="showModal" @hidden="close">
    <template #header>
      <div class="flex content-center">
        <div>
          <h4 class="modal-title">{{ trans('Participant status') }}</h4>
        </div>
        <div v-if="info">
          <small v-if="statusLabel" :class="statusClasses">{{ trans(statusLabel) }}</small>
        </div>
      </div>
    </template>

    <template v-if="info">
      <div class="modal-body">
        <User :user="user" />
      </div>

      <div v-if="userInvite" class="modal-body modal-body--tinted">
        <blockquote v-if="userInvite.comment">{{ userInvite.comment }}</blockquote>
        <div>{{ trans('Invited by') }} {{ userInvite.sender.fullname }}</div>
      </div>

      <CourseContent v-else-if="userCourse && userCourse.course.isCourse" />

      <div class="modal-actions">
        <MdlButton class="ml-auto" @click="close">
          {{ trans('Close') }}
        </MdlButton>
        <a v-if="diploma && !diploma.isExpired" class="mdl-button" :href="diploma.url" target="_blank">
          {{ trans('Diploma') }}
        </a>
        <MdlButton v-if="state.isAttempting" danger @click="cancelAttempt">
          {{ trans('Cancel attempt') }}
        </MdlButton>
        <MdlButton v-if="info.isUnenrollable" danger @click="unenroll">
          {{ trans('Unenroll') }}
        </MdlButton>
      </div>
    </template>

    <MdlProgressbar v-if="isLoading" indeterminate />
  </Modal>
</template>

<script>
import CourseContent from './Content/Course.vue'
import Modal from '@component/Modal.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlProgressbar from '@component/mdl/ProgressBar.vue'
import User from '@component/User.vue'
import { trans } from '@/munio/i18n/index.js'

export default {
  props: {
    id: { type: [String, Number], required: true },
    userId: { type: Number },
  },

  data() {
    return {
      info: null,
      isLoading: false,
      showModal: !!this.userId,
    }
  },

  components: {
    CourseContent,
    Modal,
    MdlButton,
    MdlProgressbar,
    User,
  },

  provide() {
    return {
      root: this,
    }
  },

  computed: {
    api() {
      return Munio.api.companyCourse(this.id)
    },

    state() {
      return this.info.state
    },

    user() {
      return this.info.user
    },

    userCourse() {
      return this.info.userCourse
    },

    userInvite() {
      return this.info.userInvite
    },

    attempt() {
      return this.userCourse ? this.userCourse.attempt : undefined
    },

    diploma() {
      return this.userCourse ? this.userCourse.diploma : undefined
    },

    statusClasses() {
      const classes = ['label', 'ml-4']

      if (this.userInvite) {
        classes.push('label-info')
      }

      if (this.state) {
        classes.push({
          'label-danger': this.state.isExpired,
          'label-success': this.state.isCompleted && !this.state.isExpired,
          'label-default': this.state.isAttempting,
        })
      }

      return classes
    },

    statusLabel() {
      if (!this.state) {
        return null
      }

      if (this.state.isExpired) {
        return 'Expired'
      }

      if (this.state.isCompleted) {
        return 'Completed'
      }

      if (this.state.isAttempting) {
        return 'Started'
      }

      if (this.userInvite) {
        return 'Invited'
      }

      return null
    },
  },

  watch: {
    userId(value) {
      if (value) {
        this.open()
      }
    },
  },

  methods: {
    trans,

    async open() {
      this.info = null
      this.isLoading = true
      this.showModal = true
      await this.load()
      this.isLoading = false
    },

    close() {
      this.showModal = false
      this.isLoading = false
      this.$emit('close')
    },

    async load() {
      if (this.userId) {
        const { data } = await this.api.enrollment(this.userId).get()

        this.info = data.data
      }
    },

    async cancelAttempt() {
      if (
        !(await Munio.confirm(
          trans('Are you sure about this?'),
          trans('The course will be reset and the user must start over.'),
        ))
      ) {
        return
      }

      this.isLoading = true
      await Munio.api.user(this.userCourse.userId).course(this.userCourse.courseId).cancel()
      this.load()
      this.isLoading = false
    },

    async unenroll() {
      const ok = await Munio.confirm()

      if (!ok) {
        return
      }

      try {
        const { data } = await this.api.enrollment(this.userId).unenroll()

        if (this.reload) {
          window.location.reload(true)
        } else {
          this.$emit('unenrolled', data.data)
        }
      } catch (err) {
        Munio.Flash.error(err.message)
      } finally {
        this.isLoading = false
      }
    },

    async reserveLicense() {
      // @todo
    },
  },
}
</script>
