<template>
  <Card
    class="rounded-b-none"
    :title="company.name"
    :label="label"
    :href="link ? company.accessUrl : undefined"
    :thumbnail="company.logoUrl"
    :thumbnail-cover="false"
    thumbnail-show
    alignment="center"
  >
    <div v-if="state.isValid" class="flex justify-center items-center font-bold" :class="stateClass">
      <template v-if="expiration.isExpiring">
        <i class="material-icons small">warning</i>
        <span class="ml-4">{{ expiration.text }}</span>
      </template>
      <template v-else>
        <i class="material-icons small">check_circle</i>
        <span class="ml-4">{{ trans('Completed') }}</span>
      </template>
    </div>
  </Card>
</template>

<script>
import Card from '@component/Card.vue'
import { trans } from '@/munio/i18n/index.js'

export default {
  components: {
    Card,
  },

  props: {
    company: { type: Object, required: true },
    state: { type: Object, required: true },
    link: Boolean,
  },

  computed: {
    expiration() {
      return this.state.expiration || {}
    },

    stateClass() {
      return {
        'mdl-color-text--success': this.state.isValid && !this.expiration.isExpiring,
        'mdl-color-text--warning-dark': this.state.isValid && this.expiration.isExpiring,
      }
    },

    label() {
      if (this.company.isDemo && window.can('munio')) {
        return trans('Demo')
      }

      return undefined
    },
  },
  methods: {
    trans,
  },
}
</script>
