<template>
  <li class="company-selector" v-click-outside="hide">
    <form
      ref="$form"
      method="post"
      :action="route('lms.context')"
      :class="['dropdown', { open: dropdown }]"
      @keydown.esc="hide"
      @keydown.stop
    >
      <CSRF />
      <input type="hidden" name="company_id" :value="selectedCompany.id" />

      <a class="btn" @click="toggle">
        <Icon name="business" />
        {{ selectedCompany.name }}
      </a>
      <MdlButton icon="business" outlined @click="toggle" />

      <BMenu v-if="dropdown" right class="dropdown-menu left w-[32rem]" @keydown="type">
        <BMenuItem v-if="companies.length > 10">
          <MdlTextfield
            ref="$search"
            tabindex="1"
            autofocus
            v-model="search.query"
            :placeholder="trans('Search')"
            @keydown.stop
            @keydown.esc="hide"
            @keydown.down="onSearchArrowDown"
            @keydown.enter.prevent="onSearchSubmit(false)"
          />
        </BMenuItem>

        <BMenuItem
          v-if="companiesOptions.length"
          ref="$companies"
          class="max-h-50vh overflow-y-scroll overflow-x-hidden"
        >
          <template #content>
            <template v-if="search.active">
              <a>
                <MdlSpinner small />
              </a>
            </template>

            <template v-else>
              <a
                v-for="company of companiesOptions"
                :key="company.id"
                tabindex="1"
                @keydown.enter="select(company)"
                @keydown.up="onDropdownNavigateUp"
                @keydown.down="onDropdownNavigateDown"
                @click="select(company)"
                class="flex justify-between items-baseline gap-x-4"
              >
                <span class="flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">{{ company.name }}</span>
                <small class="whitespace-nowrap overflow-x-visible">
                  <span v-if="company.orgnr" class="opaque">{{ company.orgnr }}</span>
                  {{ flag(company.country) }}
                </small>
              </a>
            </template>
          </template>
        </BMenuItem>
      </BMenu>
    </form>
  </li>
</template>

<script setup lang="ts">
import { computed, ref, nextTick, watch } from 'vue'
import { route } from 'ziggy'
import { trans } from '@/munio/i18n/index.js'
import { focusableNodes } from '@/munio/utils/index.js'
import { clickOutside as vClickOutside } from '@directive/eventOutside'
import CSRF from '@component/CSRF.vue'
import Icon from '@component/Icon.vue'
import BMenu from '@component/Menu.vue'
import BMenuItem from '@component/MenuItem.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlTextfield from '@component/mdl/Textfield.vue'
import MdlSpinner from '@component/mdl/Spinner.vue'
import { CompanyBasicResourceDto } from '@/munio/api/data'

type Company = CompanyBasicResourceDto & {
  nameLegal?: string
  nameShort?: string
}

const flag = window.Munio.flag

// data
const $form = ref()
const $search = ref()
const $companies = ref()
const companies = ref<Company[]>(window.Munio.config.companies)
const selectedCompany = ref(window.Munio.config.company)
const dropdown = ref(false)
const search = ref({
  query: '',
  results: [],
  active: false,
  debounce: window.debounce(onSearchSubmit, 500),
})

// computed
const companiesFiltered = computed(() => {
  if (!search.value.query) {
    return companies.value
  }

  const keywords = search.value.query
    .split(' ')
    .filter((v) => v)
    .map((v) => v.toLocaleLowerCase())

  return companies.value.filter((c) => {
    const matches = keywords.filter((keyword) => {
      if (keyword == c.id || keyword == c.orgnr) {
        return true
      }

      return (
        c.name.toLocaleLowerCase().includes(keyword) ||
        c.nameLegal?.toLocaleLowerCase().includes(keyword) ||
        c.nameShort?.toLocaleLowerCase().includes(keyword)
      )
    })

    return matches.length === keywords.length
  })
})
const companiesOptions = computed<CompanyBasicResourceDto[]>(() => {
  if (!search.value.query) {
    return companies.value
  }

  if (!search.value.active && search.value.results.length) {
    return search.value.results
  }

  return companiesFiltered.value
})

// Methods
function toggle() {
  dropdown.value = !dropdown.value
  nextTick(() => {
    if (dropdown.value && $search.value) {
      $search.value.focus()
    }
  })
}

function hide() {
  dropdown.value = false
}

function select(company) {
  selectedCompany.value = company
  hide()
  nextTick(() => {
    $form.value.submit()
  })
}

function type(e) {
  if (e.key.length === 1) {
    nextTick(() => {
      $search.value.focus()
    })
  }
}

async function onSearchSubmit(debounce = false) {
  if (search.value.query.length < 3) {
    return
  }

  if (debounce && companiesFiltered.value.length) {
    return
  }

  if (window.can('munio')) {
    try {
      search.value.active = true
      const { data } = await window.Munio.api.companies.search(search.value.query, undefined, undefined, true)
      search.value.results = data
    } catch (error) {
      console.error(error)
    } finally {
      search.value.active = false
    }

    return
  }

  const firstCompany = companiesOptions.value[0]

  if (firstCompany) {
    select(firstCompany)
  }
}

function onSearchArrowDown(e) {
  if (!$companies.value) {
    return
  }

  const nodes = focusableNodes($companies.value.$el)

  if (nodes.length) {
    e.preventDefault()
    nodes[0].focus()
  }
}

function onDropdownNavigateUp(e) {
  if (!$companies.value) {
    return
  }

  const nodes = focusableNodes($companies.value.$el)
  const index = nodes.indexOf(e.target)

  if (index === 0) {
    e.stopPropagation()
    nextTick(() => {
      $search.value.focus()
    })
  } else if (index > 0) {
    e.preventDefault()
    nodes[index - 1].focus()
  }
}

function onDropdownNavigateDown(e) {
  const nodes = focusableNodes($companies.value.$el)
  const index = nodes.indexOf(e.target)

  if (nodes[index + 1]) {
    e.preventDefault()
    nodes[index + 1].focus()
  }
}

watch(
  () => search.value.query,
  () => {
    search.value.results = []
    search.value.debounce(true)
  },
)
</script>
