const elementMap = new WeakMap()

const eventOutside = (eventName) => ({
  beforeMount: function (element, binding) {
    const handleOutsideEvent = (event) => {
      if (!(element === event.target || element.contains(event.target))) {
        binding.value(event)
      }
    }

    if (!elementMap.has(element)) {
      elementMap.set(element, new Map())
    }

    elementMap.get(element).set(eventName, handleOutsideEvent)

    document.body.addEventListener(eventName, handleOutsideEvent)
  },

  unmounted: function (element) {
    const handleOutsideEvent = elementMap.get(element)?.get(eventName)

    if (handleOutsideEvent) {
      document.body.removeEventListener(eventName, handleOutsideEvent)
      elementMap.get(element)?.delete(eventName)
    }
  },
})

export const clickOutside = eventOutside('click')
export const vClickOutside = clickOutside
export const focusOutside = eventOutside('focus')
export const vFocusOutside = focusOutside
export const keydownOutside = eventOutside('keydown')
export const vKeydownOutside = keydownOutside
