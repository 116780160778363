<script setup lang="ts">
// source official docs: https://developer.mozilla.org/en-US/docs/Web/API/Media_Capture_and_Streams_API/Taking_still_photos
import { onMounted, ref, watch } from 'vue'
import { trans } from '@/munio/i18n/index.js'
import { filter, map } from 'lodash'
import MdlProgressBar from '@component/mdl/ProgressBar.vue'
import MdlButton from '@component/mdl/Button.vue'
import MSelect from '@component/Select.vue'

type DeviceOption = {
  id: string
  label: string
  data: MediaDeviceInfo
}

const emit = defineEmits<{
  (e: 'closeStream'): void
  (e: 'changeImage', val: unknown): void
}>()

const width = 380
const height = ref(0)

const streaming = ref(false)
const stream = ref<MediaStream>()
const $video = ref<HTMLVideoElement>()

const isLoading = ref(false)
const hasImage = ref(false)

const imageSrc = ref('')
const track = ref<MediaStreamTrack>()
const imgCapture = ref<ImageCapture>()
const file = ref<File>()

const selectedCamera = ref<DeviceOption>()
const videoDevices = ref<DeviceOption[]>([])

const setIntervalId = ref<number>()

onMounted(async () => {
  isLoading.value = true
  await init()
  isLoading.value = false
})

watch(
  () => selectedCamera.value,
  async () => {
    await initializeStreamWithDevice()
  },
)

async function init() {
  try {
    await checkForDevices()
    await initializeStreamWithDevice()
  } catch (err) {
    console.error(err)
  } finally {
    setIntervalId.value = setInterval(async () => {
      await checkForDevices()
    }, 1000)
  }
}

async function getVideoDevices() {
  const devices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices()

  return filter(devices, (device: MediaDeviceInfo) => {
    return device.kind === 'videoinput' && device.deviceId && device.deviceId.length > 0
  })
}

async function checkForDevices() {
  try {
    const devices = await getVideoDevices()

    if (devices.length === 0) {
      selectedCamera.value = null
      stream.value = null
      return
    }

    videoDevices.value = map(devices, (device: MediaDeviceInfo) => {
      return {
        id: device.deviceId,
        label: device.label,
        data: device,
      }
    })

    if (!selectedCamera.value) {
      selectedCamera.value = videoDevices.value[0]
    }
  } catch (err) {
    console.log(err)
  }
}

async function initializeStreamWithDevice() {
  try {
    stream.value = await navigator.mediaDevices.getUserMedia({
      video: selectedCamera.value?.id
        ? {
            deviceId: { exact: selectedCamera.value?.id },
          }
        : true,
      audio: false,
    })

    if (stream.value) {
      track.value = stream.value.getVideoTracks()[0]
      imgCapture.value = new ImageCapture(track.value)
      await imgCapture.value.takePhoto()
    }

    playVideoStream()
  } catch (err) {
    console.error(err)
  }
}

function playVideoStream() {
  try {
    if ($video.value) {
      $video.value.srcObject = stream.value
      $video.value.play()
    }

    $video.value?.addEventListener(
      'canplay',
      () => {
        if (!streaming.value && $video.value) {
          height.value = $video.value.videoHeight / ($video.value.videoWidth / width)

          // Firefox currently has a bug where the height can't be read from
          // the video, so we will make assumptions if this happens.
          if (isNaN(height.value)) {
            height.value = width / (4 / 3)
          }

          streaming.value = true
        }
      },
      false,
    )
  } catch (err) {
    console.error(`An error occurred: ${err}`)
  }

  if (hasImage.value) {
    clearImage()
  }
}

async function takePicture() {
  if (!imgCapture.value) {
    return
  }

  try {
    const blob = await imgCapture.value.takePhoto()

    file.value = new File([blob], 'cameraImage.jpg', { type: 'image/jpeg' })

    imageSrc.value = URL.createObjectURL(file.value)

    hasImage.value = true
  } catch (err) {
    console.error(err)
  }
}

function emptyStream() {
  stream.value?.getTracks().forEach((track) => track.stop())
}

function save() {
  emit('changeImage', file.value)
  cancel()
}

function clearImage() {
  imageSrc.value = ''
  hasImage.value = false
}

function cancel() {
  emptyStream()

  if (setIntervalId.value) {
    clearInterval(setIntervalId.value)
  }

  emit('closeStream')
}

defineExpose({
  emptyStream,
})
</script>

<template>
  <div class="w-full flex flex-col items-center justify-center">
    <MdlProgressBar v-if="isLoading" indeterminate />

    <span v-if="isLoading" class="italic mdl-color-text--grey-600 py-8">
      {{ trans('Initializing camera') }}
    </span>

    <div class="flex flex-col gap-y-4" :class="{ hidden: isLoading }">
      <span v-if="!selectedCamera" class="italic mdl-color-text--grey-400 py-2">
        {{ trans('No camera device available. Please check your settings.') }}
      </span>

      <div>
        <h4 class="mdl-subheader m-0">{{ trans('Camera devices') }}</h4>
        <MSelect
          v-model="selectedCamera"
          value-format="object"
          :options="videoDevices"
          :disabled="hasImage || videoDevices.length === 1"
          searchable
          class="w-[380px]"
        />
      </div>

      <div :class="{ hidden: !selectedCamera && !hasImage }">
        <div class="camera">
          <video ref="$video" :class="{ hidden: hasImage }" :width="width" :height="height" />
        </div>

        <img :src="imageSrc" :class="{ hidden: !hasImage }" :width="width" :height="height" />
      </div>

      <div class="flex">
        <MdlButton v-if="!hasImage" raised primary @click="takePicture">{{ trans('Take picture') }}</MdlButton>
        <MdlButton v-if="hasImage" primary raised @click="save">{{ trans('Save') }}</MdlButton>
        <MdlButton v-if="hasImage" outlined @click="clearImage">{{ trans('Clear') }}</MdlButton>
        <MdlButton @click="cancel">{{ trans('Cancel') }}</MdlButton>
      </div>
    </div>
  </div>
</template>
