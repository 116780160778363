<script setup lang="ts">
import { computed } from 'vue'
import { trans } from '@/munio/i18n'
import { SelectOption } from '@/munio/definitions'
import MdlCheckbox from '@component/mdl/Checkbox.vue'
import Icon from '@component/Icon.vue'

defineOptions({
  name: 'CheckboxFilter',
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    inputName: string
    disabled?: boolean
    value?: (string | number)[]
    options?: SelectOption[]
  }>(),
  {
    inputName: 'value',
    value: () => [],
    options: () => [],
  },
)

defineEmits(['activate'])

const selected = computed(() => props.value.map(String))

function optionClasses(option: SelectOption) {
  if (option.indent) {
    return [`indent-${option.indent}`]
  }
}
</script>

<template>
  <div class="mdl-filter__form" :class="$attrs.class" @click="$emit('activate', $event)">
    <div
      v-for="option of options"
      :key="option.value"
      class="mdl-filter__option"
      :class="{ 'is-disabled': option.disabled }"
    >
      <MdlCheckbox
        :class="optionClasses(option)"
        :name="inputName"
        :value="String(option.value)"
        :disabled="option.disabled"
        :checked="selected.includes(String(option.value))"
      >
        <span class="flex">
          <img v-if="option.image" :src="option.image" :alt="trans('Avatar')" />
          <span class="flex flex-col">
            <small v-if="option.identifier" class="opaque">{{ option.identifier }}</small>
            <span>
              <Icon v-if="option.icon" :name="option.icon" small />
              {{ option.label }}
            </span>
            <span v-if="option.description" class="mdl-checkbox__description">
              {{ option.description }}
            </span>
          </span>
        </span>
      </MdlCheckbox>
    </div>
  </div>
</template>
