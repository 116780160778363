<template>
  <div class="mdl-paginator-container">
    <nav class="mdl-paginator" v-if="pagination.rowsTotal > 0">
      <div class="mdl-paginator__rows">{{ rowsText }}</div>
      <div class="mdl-paginator__actions">
        <MdlButton
          icon
          primary
          rel="prev"
          :disabled="cannotClickPrev"
          @click="$emit(emitEvent, 1)"
          class="mdl-paginator__button-first"
        >
          <i class="material-icons">first_page</i>
        </MdlButton>
        <MdlButton
          icon
          primary
          rel="prev"
          :disabled="cannotClickPrev"
          @click="$emit(emitEvent, pagination.pageCurrent - 1)"
          class="mdl-paginator__button-prev"
        >
          <i class="material-icons">navigate_before</i>
        </MdlButton>
        <MdlButton
          icon
          primary
          rel="next"
          :disabled="cannotClickNext"
          @click="$emit(emitEvent, pagination.pageCurrent + 1)"
          class="mdl-paginator__button-next"
        >
          <i class="material-icons">navigate_next</i>
        </MdlButton>
        <MdlButton
          icon
          primary
          rel="last"
          :disabled="cannotClickNext"
          @click="$emit(emitEvent, pagination.pageLast)"
          class="mdl-paginator__button-last"
        >
          <i class="material-icons">last_page</i>
        </MdlButton>
      </div>
      <MdlProgressBar v-if="loading" indeterminate />
    </nav>
  </div>
</template>

<script>
import MdlButton from '@component/mdl/Button.vue'
import MdlProgressBar from '@component/mdl/ProgressBar.vue'

import { trans } from '@/munio/i18n/index.js'

export default {
  inheritAttrs: false,

  components: {
    MdlButton,
    MdlProgressBar,
  },

  props: {
    value: { type: Object, required: false },
    count: { type: Number, default: 0 },
    rowsFrom: { type: Number, required: false, default: 0 },
    rowsTo: { type: Number, required: false, default: 0 },
    rowsTotal: { type: Number, required: false, default: 0 },
    pageRows: { type: Number, required: false, default: 15 },
    pageCurrent: { type: Number, required: false, default: 1 },
    pageLast: { type: Number, required: false, default: 1 },
    loading: { type: Boolean, required: false },
  },

  emits: ['goto'],

  computed: {
    pagination() {
      const pageRows = this.value?.per_page || this.value?.perPage || this.pageRows
      const pageCurrent = this.value?.current_page || this.value?.currentPage || this.pageCurrent
      const pageLast = this.value?.last_page || this.value?.totalPages || this.pageLast
      const offset = (pageCurrent - 1) * this.value?.count
      const rowsFrom = this.value?.from || offset + 1 || this.rowsFrom
      const rowsTo = this.value?.to || offset + this.value?.count || this.rowsTo
      const rowsTotal = this.value?.total || this.rowsTotal

      return {
        rowsFrom: rowsFrom,
        rowsTo: rowsTo,
        rowsTotal: rowsTotal,
        pageRows: pageRows,
        pageCurrent: pageCurrent,
        pageLast: pageLast,
      }
    },

    emitEvent() {
      return this.$attrs?.onChange ? 'change' : 'goto'
    },

    cannotClickPrev() {
      return this.loading || this.pagination.pageCurrent <= 1
    },

    cannotClickNext() {
      return this.loading || this.pagination.pageCurrent >= this.pagination.pageLast
    },

    rowsText() {
      return trans(':num of :total', {
        num:
          this.pagination.pageLast === 1
            ? `${this.pagination.rowsTo}`
            : `${this.pagination.rowsFrom}-${this.pagination.rowsTo}`,
        total: this.pagination.rowsTotal,
      })
    },
  },
}
</script>
