<script setup lang="ts">
import { ref, useSlots, onMounted, watch, onBeforeUnmount } from 'vue'
import useSlotCheck from '@composable/useSlotCheck.ts'
import { useRadioGroup } from './useRadioGroup'

const radios = useRadioGroup()

type RadioValue = string | number | boolean

defineOptions({
  name: 'MdlRadio',
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    name: string
    value?: RadioValue
    label?: string
    description?: string
    disabled?: boolean
    checked?: RadioValue
    readonly?: boolean
  }>(),
  {
    checked: false,
  },
)

const emit = defineEmits<{
  (e: 'change', value: RadioValue): void
  (e: 'update:checked', value: RadioValue): void
}>()

const slots = useSlots()
const hasDefaultSlot = useSlotCheck(slots.default)
const input = ref<HTMLInputElement>()
const isChecked = ref(false)
const isFocused = ref(false)

watch(
  () => props.checked,
  (checked) => {
    isChecked.value = getChecked(checked)
  },
  { immediate: true },
)

function getChecked(checked: RadioValue): boolean {
  if (typeof checked === 'boolean') {
    return checked
  }

  return checked === props.value
}

function onChange(event) {
  isChecked.value = event.target.checked
  radios.onChecked(props.name, input.value)

  emit('change', props.value)
  emit('update:checked', props.value)
}

function onFocus() {
  isFocused.value = true
}

function onBlur() {
  isFocused.value = false
}

function onMouseup() {
  window.setTimeout(function () {
    input.value?.blur()
  }, 0.001)
}

onMounted(() => {
  radios.add(props.name, input.value, isChecked)
})

onBeforeUnmount(() => {
  radios.remove(props.name, input.value)
})
</script>

<template>
  <label
    class="mdl-radio is-upgraded"
    :class="[
      {
        'is-checked': isChecked,
        'is-focused': isFocused,
        'is-disabled': props.disabled,
        'pointer-events-none': props.readonly,
      },
      $attrs.class,
    ]"
    @mouseup="onMouseup"
  >
    <input
      ref="input"
      type="radio"
      class="mdl-radio__button"
      :name="name"
      :value="value"
      :disabled="disabled"
      :checked="isChecked"
      :readonly="readonly"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
      v-bind="$attrs"
    />
    <span v-if="hasDefaultSlot || label" class="mdl-radio__label">
      {{ label }}
      <slot />
      <span v-if="description" class="mdl-radio__description">{{ description }}</span>
    </span>
    <span class="mdl-radio__outer-circle" />
    <span class="mdl-radio__inner-circle" />
  </label>
</template>
