import messageSelector from './messageSelector.js'

let translations = {}

if (typeof Munio !== 'undefined' && Munio.translations) {
  translations = Munio.translations
}

function transGet(key) {
  return translations[key] || key
}

function transReplace(translation, params = {}) {
  for (let key in params) {
    let value = params[key]

    if (typeof value === 'number') {
      value = value.toString()
    }

    if (typeof value === 'string') {
      translation = translation.replace(new RegExp(':' + key, 'gi'), value)
    }
  }

  return translation
}

/**
 * Translate a string.
 *
 * @param key
 * @param params
 * @returns string
 */
export function trans(key, params = {}) {
  return transReplace(transGet(key), params)
}

/**
 * Translate and pluralize a string.
 *
 * @param key
 * @param num
 * @param params
 * @returns string
 */
export function transChoice(key, num, params = {}) {
  let translation = messageSelector.choose(transGet(key), num, Munio.config.i18n.language)

  if (params.num === undefined) {
    params.num = num
  }

  return transReplace(translation, params)
}
