import Wrapper from '@app/Wrapper.js'

Munio.AuditViewer = class extends Wrapper {
  constructor(selector, context) {
    super(selector, { context })
  }

  component() {
    return import('./App.vue')
  }
}
