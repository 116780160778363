<script setup lang="ts">
import { ref, watch } from 'vue'

defineOptions({
  name: 'MdlSwitch',
  inheritAttrs: false,
})

const props = defineProps<{
  name?: string
  value?: string | number
  checked?: boolean
  disabled?: boolean
  right?: boolean
}>()

const emit = defineEmits<{
  (e: 'change', value: boolean): void
  (e: 'update:checked', value: boolean): void
}>()

const input = ref<HTMLInputElement>()
const isChecked = ref(false)
const isFocused = ref(false)

watch(
  () => props.checked,
  (value) => {
    isChecked.value = value
  },
  { immediate: true },
)

function onChange(event) {
  isChecked.value = event.target.checked

  emit('change', event.target.checked)
  emit('update:checked', event.target.checked)
}

function onFocus() {
  isFocused.value = true
}

function onBlur() {
  isFocused.value = false
}

function onMouseup() {
  window.setTimeout(function () {
    input.value?.blur()
  }, 0.001)
}
</script>

<template>
  <label
    class="mdl-switch is-upgraded"
    :class="[
      {
        'mdl-switch--right': right,
        'is-checked': isChecked,
        'is-focused': isFocused,
        'is-disabled': disabled,
      },
      $attrs.class,
    ]"
    @mouseup="onMouseup"
  >
    <input
      ref="input"
      type="checkbox"
      class="mdl-switch__input"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="disabled"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
      v-bind="$attrs"
    />
    <input v-if="name && !checked && !disabled" type="hidden" :name="name" value="0" />
    <span class="mdl-switch__label">
      <slot />
    </span>
    <div class="mdl-switch__track" />
    <div class="mdl-switch__thumb">
      <span class="mdl-switch__focus-helper" />
    </div>
  </label>
</template>
