<template>
  <div class="mdl-textfield-i18n">
    <template v-if="name">
      <input
        v-for="(value, key) of input"
        :key="`${name}-${key}`"
        type="hidden"
        :name="`${name}[${key}]`"
        :value="value"
      />
    </template>

    <MdlTextfield ref="input" v-bind="attrs" v-model:modelValue="inputValue" />

    <div class="mdl-chips">
      <MdlChip v-if="languagesActive.length === 0" :ref="`chip_${languageDefault}`" class="is-empty">
        {{ getLanguage(languageDefault).name }}
      </MdlChip>
      <MdlChip
        v-for="code of languagesActive"
        :key="code"
        :ref="`chip_${code}`"
        :highlight="isSelected(code)"
        deletable
        :class="['is-clickable', { 'is-empty': isEmpty(code) }]"
        delete-icon="clear"
        @focus="setLanguage(code)"
        @click="setLanguage(code)"
        @delete="delLanguage(code)"
      >
        {{ getLanguage(code).name }}
      </MdlChip>

      <div class="inline-block dropdown">
        <MdlButton :disabled="!languagesInactive.length" icon data-toggle="dropdown">
          <i class="material-icons">add</i>
        </MdlButton>
        <BMenu right>
          <BMenuItem v-for="code in languagesInactive" :key="code" @click.prevent="setLanguage(code)">
            {{ getLanguage(code).name }}
          </BMenuItem>
        </BMenu>
      </div>
    </div>
  </div>
</template>

<script>
import MdlTextfield from '@component/mdl/Textfield.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlChip from '@component/mdl/Chip.vue'
import BMenu from '@component/Menu.vue'
import BMenuItem from '@component/MenuItem.vue'

export default {
  inheritAttrs: false,

  components: {
    MdlTextfield,
    MdlButton,
    MdlChip,
    BMenu,
    BMenuItem,
  },

  props: {
    ...MdlTextfield.props,
    modelValue: { type: Object, default: () => ({}) },
    languages: { type: Array, default: () => Munio.config.languages.map((l) => l.key) },
    languageDefault: { type: String, default: Munio.config.i18n.key },
  },

  data() {
    return {
      input: {},
      language: this.languageDefault,
    }
  },

  computed: {
    attrs() {
      let { name, modelValue, ...attrs } = Object.assign({}, { ...this.$props }, { ...this.$attrs })

      return attrs
    },

    inputValue: {
      get() {
        return this.input[this.language] || ''
      },

      set(value) {
        this.input[this.language] = value
        this.$emit('update:modelValue', this.input)
      },
    },

    inputLanguages() {
      return Object.keys(this.input)
    },

    languagesActive() {
      return this.languages.filter((code) => this.inputLanguages.indexOf(code) !== -1)
    },

    languagesInactive() {
      return this.languages
        .filter((code) => this.inputLanguages.indexOf(code) === -1)
        .sort((a, b) => {
          return this.getLanguage(a).name.localeCompare(this.getLanguage(b).name, Munio.config.i18n.language)
        })
    },
  },

  methods: {
    isActive(code) {
      return this.input.hasOwnProperty(code)
    },

    isSelected(code) {
      return code === this.language
    },

    isDeletable(code) {
      if (!this.isSelected(code)) {
        return false
      }
      return Object.keys(this.input).length > 1
    },

    isEmpty(code) {
      return !(this.input[code] || '').length
    },

    getLanguage(code) {
      return Munio.language(code)
    },

    setLanguage(code, focus = false) {
      this.language = code
      if (this.input.hasOwnProperty(code)) {
        if (focus) {
          this.$nextTick(() => {
            this.$refs['chip_' + code][0].$el.focus()
          })
        }
      } else {
        this.input[code] = ''
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
    },

    async delLanguage(code) {
      if (this.input[code]) {
        if (!(await Munio.confirm())) {
          return
        }
      }

      delete this.input[code]

      if (Object.keys(this.input).length === 0) {
        this.language = this.languageDefault
      } else {
        this.language = this.languagesActive[0]
      }
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.input = value

        if (Object.keys(this.input).length > 0 && this.input[this.language] === undefined) {
          for (let language of this.languages) {
            if (this.input.hasOwnProperty(language)) {
              this.language = language
              break
            }
          }
        }
      },
    },
  },
}
</script>
