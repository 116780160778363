<template>
  <div
    role="alert"
    class="flex relative rounded p-4 py-3"
    :class="{
      'bg-danger/15 text-danger': type === 'danger',
      'bg-warning/15 text-warning': type === 'warning',
      'bg-info/15 text-info': type === 'info',
      'bg-success/15 text-success': type === 'success',
    }"
  >
    <Icon
      v-if="typeIcon"
      :name="typeIcon"
      class="absolute rounded-full top-[-8px] left-[-8px]"
      :class="{
        'text-danger': type === 'danger',
        'text-warning': type === 'warning',
        'text-info': type === 'info',
        'text-success': type === 'success',
      }"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Icon from '@component/Icon.vue'

const props = withDefaults(
  defineProps<{
    type: 'success' | 'info' | 'warning' | 'danger'
    icon?: string | boolean
  }>(),
  {
    type: 'info',
    icon: true,
  },
)

const typeIcon = computed(() => {
  if (!props.icon) {
    return undefined
  }

  if (typeof props.icon === 'string') {
    return props.icon
  }

  switch (props.type) {
    case 'danger':
      return 'error'
    case 'warning':
      return 'warning'
    case 'success':
      return 'check_circle'

    default:
    case 'info':
      return 'info'
  }
})
</script>
